import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import { AlertType, useAlert } from "../../provider/AlertProvider";
import { useAuthState } from "../../provider/AuthProvider";
import { TableProps } from "antd/es/table/InternalTable";
import { useSelector, useDispatch } from "react-redux";
import { actionSetCars } from "../../utils/action";

import Badge from "antd/es/badge/index";
import Modal from "antd/es/modal/index";
import Space from "antd/es/space/index";
import Tag from "antd/es/tag/index";

import type { ColumnsType, SorterResult } from "antd/es/table/interface";

import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";
import * as Common from "../../commons/common";
import * as String from "../../commons/string";
import * as Utils from "../../utils/utils";
import * as RequestCar from "../../utils/requestApiCar";

import moment from "moment";
import DataTable from "../../components/common/DataTable";
import Search from "../../components/browser/Search";
import IconEdit from "../../assets/images/icon/icon_edit.png";
import IconDelete from "../../assets/images/icon/icon_delete.png";

type SearchType = {
    carNumber: string;
    makerName: string;
    categoryName: string;
    idNumber: string;
    customerName: string;
    customerManagerName: string;
};

const PageCars = () => {
    const alert = useAlert();
    const navigate = useNavigate();
    const userDetails = useAuthState();
    const dispatch = useDispatch();
    const reducerCars = useSelector((state) => state.cars);

    const [cars, setCars] = useState<Array<TypeDTO.CarDto>>();
    const [filteredCars, setFilteredCars] = useState<Array<TypeDTO.CarDto>>();
    const [sortedInfo, setSortedInfo] = useState<SorterResult<TypeDTO.RunningHistoryDto>>({});
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedManagerName, setSelectedManagerName] = useState<string>();
    const [searchValue, setSearchValue] = useState<SearchType>();
    const [pageSize, setPageSize] = useState<number>(20);

    const searchData: TypeUtils.SearchItemType[] = [
        {
            id: "carNumber",
            span: 12,
            title: String.carNumber,
            type: "select",
            typeDetail: "car",
        },
        {
            id: "makerName",
            span: 6,
            title: String.maker,
            type: "select",
            typeDetail: "maker",
        },
        {
            id: "categoryName",
            span: 6,
            title: String.category,
            type: "select",
            typeDetail: "category",
        },
        {
            id: "idNumber",
            span: 12,
            title: String.identityNumber,
            type: "select",
            typeDetail: "idNumber",
        },
        {
            id: "customerManagerName",
            span: 6,
            title: String.customerManager,
            type: "select",
            typeDetail: "customerManager",
        },
        {
            id: "customerName",
            span: 6,
            title: String.customer,
            type: "select",
            typeDetail: "customer",
        },
    ];

    const [search, setSearch] = useState<TypeUtils.SearchItemType[]>();

    const columns: ColumnsType<TypeDTO.CarDto> = [
        {
            title: String.carNumber,
            dataIndex: "carNumber",
            key: "carNumber",
            width: "12%",
            align: "center",
        },
        {
            title: String.type,
            dataIndex: ["category", "categoryName"],
            key: "categoryName",
            width: "10%",
            align: "center",
            render: (_, row) => {
                return row?.category?.categoryName || String.dash;
            },
        },
        {
            title: (
                <>
                    {String.totalRunningDistance}
                    <span className="fs-xs"> {String.kmDistanceUnit}</span>
                </>
            ),
            dataIndex: ["runningSummary", "totalDrivingDistance"],
            key: "totalDrivingDistance",
            width: "10%",
            sorter: (a, b, sortOrder) => Utils.sortNumber(a.runningSummary?.totalDrivingDistance, b.runningSummary?.totalDrivingDistance, sortOrder),
            sortOrder: sortedInfo.columnKey === "totalDrivingDistance" ? sortedInfo.order : null,
            ellipsis: true,
            render: (_, row) => {
                const summary = row?.runningSummary;
                return (summary?.totalMileage || summary?.totalDrivingDistance)
                    ?.toFixed(1)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    .replace(/\.?0+$/, "");
            },
        },
        {
            title: "마지막 운행 시각",
            dataIndex: ["runningSummary", "lastRunningHistory", "endTime"],
            key: "runningEndTime",
            width: "15%",
            sorter: (a, b, sortOrder) =>
                Utils.sortDate(a.runningSummary?.lastRunningHistory?.endTime, b.runningSummary?.lastRunningHistory?.endTime, sortOrder),
            sortOrder: sortedInfo.columnKey === "runningEndTime" ? sortedInfo.order : null,
            render: (_, { runningSummary }) => {
                const endTime = runningSummary?.lastRunningHistory?.endTime;
                if (endTime) {
                    if (runningSummary?.lastRunningHistory?.finish === true) {
                        return <span className="fs-md">{endTime ? moment(endTime).format(Common.FORMAT_MOBILE_DATE_TIME) : String.dash}</span>;
                    } else {
                        if (moment(endTime).isSameOrAfter(moment().subtract(10, "m"))) {
                            return <Tag className="pointTag">운행중</Tag>;
                        } else {
                            if (Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN])) {
                                return (
                                    <Badge style={{ right: "-4px" }} dot color="var(--danger)">
                                        <span className="fc-point fs-md">
                                            {endTime ? moment(endTime).format(Common.FORMAT_MOBILE_DATE_TIME) : String.dash}
                                        </span>
                                    </Badge>
                                );
                            } else {
                                return (
                                    <span className="fs-md">{endTime ? moment(endTime).format(Common.FORMAT_MOBILE_DATE_TIME) : String.dash}</span>
                                );
                            }
                        }
                    }
                } else {
                    return <span className="fs-md">{String.dash}</span>;
                }
            },
        },
        {
            title: String.customer,
            dataIndex: ["customer", "customerName"],
            key: "customerName",
            width: "13%",
            align: "center",
            render: (_, { customer }) => {
                return customer?.customerName || String.dash;
            },
        },
        {
            title: String.phone,
            dataIndex: ["customer", "customerPhone"],
            key: "customerPhone",
            width: "12%",
            align: "center",
            render: (_, { customer }) => {
                return Utils.convertPhone(customer?.customerPhone) || String.dash;
            },
        },
        {
            title: String.manage,
            key: "detail",
            width: "8%",
            align: "center",
            render: (_, row) => {
                return (
                    <Space size={10}>
                        <div
                            style={{ width: 24, height: 24 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                navigate(Common.PAGE_MANAGE_CAR_UPDATE + "/" + row.carId);
                            }}
                        >
                            <img alt="" className="btn-icon" src={IconEdit} width={20} />
                        </div>
                        <div
                            style={{ width: 24, height: 24 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                onDelete(row.carId, row.carNumber);
                            }}
                        >
                            <img alt="" className="btn-icon" src={IconDelete} width={20} />
                        </div>
                    </Space>
                );
            },
        },
    ];

    useEffect(() => {
        requestApiCarList(true);
    }, []);

    useEffect(() => {
        searchValue && onSearchData(searchValue);

        setTimeout(() => {
            document.querySelector(".ant-layout-content").scrollTo({ top: reducerCars.scrollPosition });
        }, 100);
    }, [cars]);

    useEffect(() => {
        if (Object.keys(reducerCars).length > 0) {
            setCurrentPage(reducerCars?.currentPage);

            setSearch(
                searchData?.map((item) => ({
                    ...item,
                    defaultValue: reducerCars[item.id as keyof typeof reducerCars] ?? item.defaultValue,
                }))
            );
        } else {
            setSearch(searchData);
        }
    }, [reducerCars]);

    const dispatchCars = (selectedId: number) => {
        dispatch(
            actionSetCars({
                ...searchValue,
                currentPage: currentPage,
                selectedId: selectedId,
                scrollPosition: document.querySelector(".ant-layout-content").scrollTop,
                pageSize: pageSize,
            })
        );
    };

    const { loadingApiCarList, requestApiCarList, resultApiCarList } = RequestCar.useRequestApiCarList();

    useEffect(() => {
        if (!resultApiCarList) return;

        setCars(resultApiCarList.cars);
    }, [resultApiCarList]);

    const { requestApiCarDelete, resultApiCarDelete } = RequestCar.useRequestApiCarDelete();

    useEffect(() => {
        if (!resultApiCarDelete) return;

        onResult("delete");
    }, [resultApiCarDelete]);

    const onTableChange: TableProps<TypeDTO.RunningHistoryDto>["onChange"] = (pagination, filters, sorter) => {
        setSortedInfo(sorter as SorterResult<TypeDTO.RunningHistoryDto>);
        setCurrentPage(pagination.current);
        setPageSize(pagination.pageSize);
    };

    const onSearchData = (value: SearchType) => {
        setSearchValue(value);
        setSelectedManagerName(value?.customerManagerName);

        const searchFilter = Utils.searchFilter;

        cars &&
            setFilteredCars(
                cars?.filter(
                    (car) =>
                        searchFilter(car, value.carNumber, "carNumber") &&
                        searchFilter(car, value.idNumber, "idNumber") &&
                        searchFilter(car?.makerModel?.maker, value?.makerName, "makerName") &&
                        searchFilter(car?.category, value.categoryName, "categoryName") &&
                        searchFilter(car?.customer?.customerManager, value?.customerManagerName, "customerManagerName") &&
                        searchFilter(car?.customer, value?.customerName, "customerName")
                )
            );
    };

    const onDelete = (id: number, name: string) => {
        Modal.confirm({
            title: String.car + String.remove,
            content: Utils.addParticle(name, "삭제하시겠습니까?"),
            okText: String.confirm,
            onOk() {
                requestApiCarDelete(id);
            },
            cancelText: String.cancel,
            onCancel() {},
            centered: true,
        });
    };

    const onResult = (type: "register" | "edit" | "delete") => {
        const typeText = Utils.getTypeText(type);
        setFilteredCars(undefined);

        alert.setAlert(AlertType.SUCCESS, `${String.car} ${typeText} 성공`, `${String.car} 정보를 ${typeText}하였습니다.`);
        requestApiCarList(true, false, false, searchValue);
    };

    return (
        <div className="pageWrapper">
            <Search
                title={String.carList}
                dataLength={filteredCars?.length}
                values={search}
                selectedName={selectedManagerName}
                onSearch={(value) => {
                    onSearchData(value);
                }}
                onClear={(value) => onSearchData(value)}
                onClick={
                    Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN])
                        ? () => navigate(Common.PAGE_MANAGE_CAR_REGISTER)
                        : undefined
                }
            />
            <DataTable
                rowKey={(row: TypeDTO.CarDto) => row.carId}
                disabledTitle
                loading={loadingApiCarList}
                columns={
                    Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN])
                        ? columns
                        : columns.filter((column) => column.key !== "detail")
                }
                dataSource={filteredCars}
                currentPage={currentPage}
                rowClassName={(car: TypeDTO.CarDto) => {
                    return car?.carId === reducerCars?.selectedId ? "table-row-selected" : "";
                }}
                tableSize={reducerCars?.pageSize}
                onRow={(car: TypeDTO.CarDto) => {
                    dispatchCars(car.carId);

                    localStorage.setItem("carDetailTab", "기본 정보");
                    navigate(Common.PAGE_MANAGE_CAR_DETAIL + "/" + car.carId);
                }}
                onChange={onTableChange}
            />
        </div>
    );
};

export default PageCars;
