import { useEffect, useState } from "react";
import { Space } from "antd";

import * as Common from "../../commons/common";
import * as String from "../../commons/string";
import * as TypeDTO from "../../commons/typeDTO";

import CanDataBukgiViewer from "../common/CanDataBukgiViewer";
import CanDataYaxingViewer from "../common/CanDataYaxingViewer";
import CanDataSkywellViewer from "../common/CanDataSkywellViewer";
import CanDataCoCoKartViewer from "../common/CanDataCoCoKartViewer";
import CanDataBongo3Viewer from "../common/CanDataBongo3Viewer";
import CanDataTSEcoViewer from "../common/CanDataTSEcoViewer";
import CanDataEVSolutionViewer from "../common/CanDataEVSolutionViewer";

import styles from "../common/KakaoMap.module.css";

function RunningData({
    car,
    loading,
    runningCanDataList,
    selectedDataPointIndex,
    onChangeSelectedDataPointIndex,
}: {
    car?: TypeDTO.CarDto;
    loading: boolean;
    runningCanDataList?: Array<TypeDTO.CanData>;
    selectedDataPointIndex?: number;
    onChangeSelectedDataPointIndex?: (index: number) => void;
}) {
    const handleSeletedDataPointIndex = (dataPointIndex: number) => {
        onChangeSelectedDataPointIndex && onChangeSelectedDataPointIndex(dataPointIndex);
    };

    return (
        <Space className={styles.space} direction="vertical" size={8} style={{ display: "flex" }}>
            {car?.makerModel?.maker?.makerName === Common.VehicleMaker.Bukgi && (
                <CanDataBukgiViewer
                    loading={loading}
                    vehicleInfo={car}
                    runningCanDataList={runningCanDataList}
                    selectedDataPointIndex={selectedDataPointIndex}
                    onChangedSelectDataPointIndex={handleSeletedDataPointIndex}
                />
            )}
            {car?.makerModel?.maker?.makerName === Common.VehicleMaker.Yaxing && (
                <CanDataYaxingViewer
                    isMobile={true}
                    loading={loading}
                    vehicleInfo={car}
                    runningCanDataList={runningCanDataList}
                    selectedDataPointIndex={selectedDataPointIndex}
                    onChangedSelectDataPointIndex={handleSeletedDataPointIndex}
                />
            )}

            {car?.makerModel?.maker?.makerName === Common.VehicleMaker.Skywell && (
                <CanDataSkywellViewer
                    loading={loading}
                    vehicleInfo={car}
                    runningCanDataList={runningCanDataList}
                    selectedDataPointIndex={selectedDataPointIndex}
                    onChangedSelectDataPointIndex={handleSeletedDataPointIndex}
                />
            )}

            {car?.category?.categoryName === String.CoCoKart && (
                <CanDataCoCoKartViewer
                    loading={loading}
                    vehicleInfo={car}
                    runningCanDataList={runningCanDataList}
                    selectedDataPointIndex={selectedDataPointIndex}
                    onChangedSelectDataPointIndex={handleSeletedDataPointIndex}
                />
            )}

            {car?.makerModel?.maker?.makerName === Common.VehicleMaker.Kia && (
                <CanDataBongo3Viewer
                    loading={loading}
                    vehicleInfo={car}
                    runningCanDataList={runningCanDataList}
                    selectedDataPointIndex={selectedDataPointIndex}
                    onChangedSelectDataPointIndex={handleSeletedDataPointIndex}
                />
            )}

            {car?.makerModel?.maker?.makerName === Common.VehicleMaker.TSEco && (
                <CanDataTSEcoViewer
                    loading={loading}
                    vehicleInfo={car}
                    runningCanDataList={runningCanDataList}
                    selectedDataPointIndex={selectedDataPointIndex}
                    onChangedSelectDataPointIndex={handleSeletedDataPointIndex}
                />
            )}

            {car?.makerModel?.maker?.makerName === Common.VehicleMaker.EVSolution && (
                <CanDataEVSolutionViewer
                    loading={loading}
                    vehicleInfo={car}
                    runningCanDataList={runningCanDataList}
                    selectedDataPointIndex={selectedDataPointIndex}
                    onChangedSelectDataPointIndex={handleSeletedDataPointIndex}
                />
            )}
        </Space>
    );
}

export default RunningData;
