import React, { useEffect, useState } from "react";
import { TabBar } from "antd-mobile";
import { Header } from "antd/es/layout/layout";
import { useLocation, useNavigate, useParams } from "react-router";
import { DeleteOutlined, LeftOutlined, DownOutlined, PlusOutlined } from "@ant-design/icons";
import { useAuthDispatch, useAuthState } from "../../provider/AuthProvider";
import { useDispatch } from "react-redux";
import { actionReset } from "../../utils/action";

import iconMonitor from "../../assets/images/icon/icon_monitor.png";
import iconTruck from "../../assets/images/icon/icon_truck.png";
import iconTool from "../../assets/images/icon/icon_tool.png";
import iconMenu from "../../assets/images/icon/icon_menu.png";
import MaintenanceMenu from "../../components/mobile/MaintenaceMenu";

import * as Utils from "../../utils/utils";
import * as Common from "../../commons/common";
import * as RequestLogin from "../../utils/requestAuthLogin";
import Layout from "antd/es/layout/index";
import styles from "./LayoutDefault.module.css";

const { Content } = Layout;
declare const window: typeof globalThis & {
    Android: any;
};

function LayoutDefault({
    header,
    theme,
    children,
    onCarNumber,
}: {
    header?: { title?: string; left?: boolean; right?: string; isMaintenance?: boolean };
    theme?: { header?: boolean; footer?: boolean; scroll?: boolean };
    children: any;
    onCarNumber?: (number: string) => void;
}) {
    const param = useParams();
    const userDetails = useAuthState();
    const navigate = useNavigate();
    const location = useLocation();
    const authDispatch = useAuthDispatch();
    const dispatch = useDispatch();

    const [activeKey, setActiveKey] = useState<string>(location?.pathname);
    const [fromTabBar, setFromTabBar] = useState<boolean>(false);
    const [maintenaceMenu, setMaintenaceMenu] = useState<boolean>(false);

    useEffect(() => {
        window.addEventListener(Common.PUSH_NOTIFICATION_ALARM, onAlarmPushNotification);

        return () => {
            window.removeEventListener(Common.PUSH_NOTIFICATION_ALARM, onAlarmPushNotification);
        };
    }, []);

    useEffect(() => {
        window.addEventListener(Common.EXTERNAL_EVENT_BACK, onBackPressEvent);
        Utils.onEvent(Common.EVENT_RE_LOGIN, onReLogin);

        return () => {
            window.removeEventListener(Common.EXTERNAL_EVENT_BACK, onBackPressEvent);
            Utils.offEvent(Common.EVENT_RE_LOGIN, onReLogin);
        };
    }, [location]);

    useEffect(() => {
        const path = location.pathname;

        if (path === Common.PAGE_DASHBOARD) {
            setActiveKey(Common.PAGE_DASHBOARD);
        } else if (path.includes(Common.PAGE_RUNNING)) {
            setActiveKey(Common.PAGE_MANAGE_CAR);
        } else if (path.includes(Common.PAGE_MANAGE_CAR)) {
            setActiveKey(Common.PAGE_MANAGE_CAR);
        } else if (path.includes(Common.PAGE_MANAGE_MAINTENANCES_HISTORY_BY_CAR) || path.includes(Common.PAGE_MANAGE_MAINTENANCES_HISTORY + "/")) {
            param?.id && onCarNumber && onCarNumber(param.id);
            setActiveKey(Common.PAGE_MANAGE_MAINTENANCES_HISTORY_BY_CAR);
        } else if (path.includes(Common.PAGE_MANAGE_MAINTENANCES_HISTORY)) {
            param?.id && onCarNumber && onCarNumber(param.id);
            setActiveKey(Common.PAGE_MANAGE_MAINTENANCES_HISTORY_BY_CAR);
        } else if (path.includes(Common.PAGE_ISSUE_SOCS)) {
            param?.id && onCarNumber && onCarNumber(param.id);
            setActiveKey(Common.PAGE_MANAGE_MAINTENANCES_HISTORY_BY_CAR);
        } else if (path.includes(Common.PAGE_ALARM)) {
            setActiveKey(Common.PAGE_MANAGE_MAINTENANCES_HISTORY_BY_CAR);
        } else if (path.includes(Common.PAGE_MAINTENANCE_GUIDES)) {
            setActiveKey(Common.PAGE_MANAGE_MAINTENANCES_HISTORY_BY_CAR);
        }
    }, [param, location]);

    const tabs = [
        {
            key: Common.PAGE_DASHBOARD,
            title: "대시보드",
            icon: <img src={iconMonitor} width={18} />,
        },
        {
            key: Common.PAGE_MANAGE_CAR,
            title: "차량 정보",
            icon: <img src={iconTruck} width={18} />,
        },
        {
            key: Common.PAGE_MANAGE_MAINTENANCES_HISTORY_BY_CAR,
            title: "차량 정비",
            icon: (
                <img
                    src={iconTool}
                    width={18}
                    onClick={() => {
                        setFromTabBar(true);
                        setMaintenaceMenu(true);
                    }}
                />
            ),
        },
        {
            key: Common.PAGE_MENU,
            title: "전체 메뉴",
            icon: (
                <img
                    src={iconMenu}
                    width={18}
                    onClick={() => {
                        setMaintenaceMenu(false);
                    }}
                />
            ),
        },
    ];

    const onReLogin = async () => {
        const result = Utils.isPossibleAutoLogin();
        if (result.isPossible && result.username && result.password) {
            requestAuthLogin({ email: result.username, password: result.password });
        }
    };

    const { requestAuthLogin, resultAuthLogin, errorAuthLogin } = RequestLogin.useRequestAuthLogin();

    useEffect(() => {
        if (resultAuthLogin === null) return;

        if (authDispatch !== null) {
            authDispatch({
                type: "LOGIN_SUCCESS",
                payload: {
                    user: resultAuthLogin.user,
                    token: resultAuthLogin.token,
                },
            });
        }

        Utils.sendFCMToken();
        window.location.reload();
    }, [resultAuthLogin]);

    useEffect(() => {
        if (errorAuthLogin === null) {
            return;
        }
        navigate(Common.PAGE_LOGIN);
    }, [errorAuthLogin]);

    const onBackPressEvent = () => {
        if (Utils.getModalStack() !== 0) {
            Utils.triggerEvent(Common.EVENT_CLOSE_ALL_MODAL);
            Utils.initModalStack();
        } else if (Utils.getDrawerStack() !== 0) {
            Utils.triggerEvent(Common.EVENT_CLOSE_ALL_DRAWER);
            Utils.initDrawerStack();
        } else if (Utils.getViewStack() !== 0) {
            Utils.triggerEvent(Common.EVENT_CLOSE_ALL_VIEW);
            Utils.initViewStack();
        } else {
            const path = location.pathname;
            if (path === Common.PAGE_DASHBOARD || path === Common.PAGE_LOGIN) {
                window.Android.closeAppOnBackPress();
            } else if (
                path === Common.PAGE_MANAGE_MAINTENANCES_HISTORY ||
                path === Common.PAGE_ISSUE_SOCS ||
                path === Common.PAGE_ALARM ||
                path === Common.PAGE_MAINTENANCE_GUIDES
            ) {
                if (Utils.getLocalStorage(Common.CONTEXT_SHOW_MAINTENANCE_MENU)?.toString() === "true") {
                    setMaintenaceMenu(true);
                    Utils.setLocalStorage(Common.CONTEXT_SHOW_MAINTENANCE_MENU, "false");
                }

                navigate(-1);
            } else {
                navigate(-1);
            }
        }
    };

    const onAlarmPushNotification = async (event: any) => {
        console.log("onAlarmPushNotification: ", event.detail);

        if (userDetails) {
            navigate(Common.PAGE_ALARM_DETAIL + "/" + event.detail.alarmId);
        } else {
            Utils.setLocalStorage(Common.CONTEXT_NEXT_PATH, Common.PAGE_ALARM_DETAIL + "/" + event.detail.alarmId);
            navigate(Common.PAGE_LOGIN);
        }
    };

    return (
        <Layout className={styles.layout}>
            {theme?.header && (
                <Header className={styles.header}>
                    {header?.left ? (
                        <div className={`${styles.buttonWrapper} ${styles.leftButtonWrapper}`} onClick={() => navigate(-1)}>
                            <LeftOutlined className={styles.menuIcon} />
                        </div>
                    ) : (
                        <div className={`${styles.buttonWrapper} ${styles.leftButtonWrapper}`}>
                            <div className={styles.dummyIcon} />
                        </div>
                    )}
                    <div
                        className={styles.headerTitle}
                        onClick={() => {
                            if (header?.isMaintenance) {
                                setFromTabBar(false);
                                setMaintenaceMenu(true);
                            }
                        }}
                    >
                        <h4 className="fc-font1">{header?.title}</h4>
                        {header?.isMaintenance && <DownOutlined className={styles.menuIcon} />}
                    </div>
                    {header?.right === "delete" &&
                    ((header?.title === "상세 차량 정보" && Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN])) ||
                        (header?.title?.includes("정비") && !Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_USER]))) ? (
                        <div
                            className={`${styles.buttonWrapper} ${styles.rightButtonWrapper}`}
                            onClick={() => Utils.triggerEvent(Common.EVENT_CLICK_DELETE)}
                        >
                            <DeleteOutlined style={{ color: "var(--danger)" }} />
                        </div>
                    ) : header?.right === "add" &&
                      ((header?.title === "차량 정보" && Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN])) ||
                          (header?.title?.includes("정비") && !Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_USER]))) ? (
                        <div
                            className={`${styles.buttonWrapper} ${styles.rightButtonWrapper}`}
                            onClick={
                                header?.title === "차량 정보"
                                    ? Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN])
                                        ? () => navigate(Common.PAGE_MANAGE_CAR_REGISTER)
                                        : undefined
                                    : header?.title?.includes("정비")
                                    ? !Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_USER])
                                        ? () =>
                                              navigate(
                                                  param.id
                                                      ? Common.PAGE_MANAGE_MAINTENANCE_REGISTER + "/" + param.id
                                                      : Common.PAGE_MANAGE_MAINTENANCE_REGISTER
                                              )
                                        : undefined
                                    : undefined
                            }
                        >
                            <PlusOutlined className={styles.menuIcon} />
                        </div>
                    ) : (
                        <div className={`${styles.buttonWrapper} ${styles.rightButtonWrapper}`}>
                            <div className={styles.dummyIcon} />
                        </div>
                    )}
                </Header>
            )}

            <Content className={styles.content} style={{ overflowY: theme?.scroll === false ? "hidden" : "auto" }}>
                {children}
            </Content>

            {theme?.footer && (
                <TabBar
                    className={styles.footer}
                    onChange={(key) => {
                        navigate(key);
                        setActiveKey(key);
                        dispatch(actionReset());

                        if (key.includes("maintenance")) {
                            setFromTabBar(true);
                            setMaintenaceMenu(true);
                        }
                    }}
                    activeKey={activeKey}
                >
                    {tabs.map((item) => (
                        <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
                    ))}
                </TabBar>
            )}

            {header?.isMaintenance && maintenaceMenu && (
                <MaintenanceMenu onChangeOpen={(isOpen) => setMaintenaceMenu(isOpen)} fromTabBar={fromTabBar} />
            )}
        </Layout>
    );
}

export default LayoutDefault;
