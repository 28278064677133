import { useEffect, useState } from "react";

import moment from "moment";

import * as CanDataEVSolution from "../../commons/CanDataEVSolution";

import * as Common from "../../commons/common";
import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";

import CanDataWrapper, { CanTableType } from "./CanDataWrapper";
import Space from "antd/es/space/index";

function CanDataEVSolutionViewer({
    loading,
    vehicleInfo,
    runningCanDataList,
    selectedDataPointIndex,
    onChangedSelectDataPointIndex,
    onMountedChart,
}: {
    loading: boolean;
    vehicleInfo?: TypeDTO.CarDto | undefined;
    runningCanDataList?: Array<TypeDTO.CanData> | undefined;
    selectedDataPointIndex?: number;
    onChangedSelectDataPointIndex?: (dataPointIndex: number) => void;
    onMountedChart?: () => void;
}) {
    const [currentCanData, setCurrentCanData] = useState<CanDataEVSolution.CanData>();

    const convertRemarkWithUnit = (value: number, unit: string) => {
        return value + unit;
    };

    const convertRemarkBatPackStatus = (value: number) => {
        let valueString = "-";
        if (!value) return valueString;
        switch (value) {
            case 0:
                valueString = "Normal";
                break;
            case 1:
                valueString = "Warning";
                break;
            case 2:
                valueString = "Warning(VCU),MIL(Cluster)";
                break;
            case 3:
                valueString = "Warning,MIL,Derating";
                break;
            case 4:
                valueString = "Warning,MIL,Fault,Rel off by VCU";
                break;
            case 5:
                valueString = "Warning,MIL,Critical,Rel off BMS";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertRemarkOperationMode = (value: number) => {
        let valueString = "-";
        if (!value) return valueString;
        switch (value) {
            case 0:
                valueString = "Sleep";
                break;
            case 1:
                valueString = "Standby";
                break;
            case 2:
                valueString = "Pre-charge";
                break;
            case 3:
                valueString = "Ready";
                break;
            case 4:
                valueString = "Error";
                break;
            case 5:
                valueString = "Shutdown";
                break;
            default:
                break;
        }
        return valueString;
    };

    const convertRemarkContactorCoilState = (value: number) => {
        let valueString = "-";
        if (!value) return valueString;
        switch (value) {
            case 0:
                valueString = "OFF";
                break;
            case 1:
                valueString = "ON";
                break;
            case 2:
                valueString = "Open Load";
                break;
            case 3:
                valueString = "Short to GND";
                break;
            default:
                break;
        }
        return valueString;
    };

    const convertRemarkFault = (value: number) => {
        let valueString = "-";
        if (!value) return valueString;
        switch (value) {
            case 0:
                valueString = "Fault";
                break;
            case 1:
                valueString = "Normal";
                break;
            default:
                break;
        }
        return valueString;
    };

    const convertRemarkRequest = (value: number) => {
        let valueString = "-";
        if (!value) return valueString;
        switch (value) {
            case 0:
                valueString = "NoRequest";
                break;
            case 1:
                valueString = "Request";
                break;
            default:
                break;
        }
        return valueString;
    };

    const convertRemarkBmsMode = (value: number) => {
        let valueString = "-";
        if (!value) return valueString;
        switch (value) {
            case 0:
                valueString = "Standby";
                break;
            case 1:
                valueString = "Heating mode";
                break;
            case 2:
                valueString = "Cooling mode";
                break;
            default:
                break;
        }
        return valueString;
    };

    const convertRemarkNoYes = (value: number) => {
        let valueString = "-";
        if (!value) return valueString;
        switch (value) {
            case 0:
                valueString = "No";
                break;
            case 1:
                valueString = "Yes";
                break;
            default:
                break;
        }
        return valueString;
    };

    const convertRemarkCoolingPumpFeedbackStatus = (value: number) => {
        let valueString = "-";
        if (!value) return valueString;
        switch (value) {
            case 0:
                valueString = "Not working";
                break;
            case 1:
                valueString = "PWM Wire open";
                break;
            case 2:
                valueString = "Dry run signal";
                break;
            case 3:
                valueString = "STALL";
                break;
            case 4:
                valueString = "Over temp";
                break;
            case 5:
                valueString = "EWP diagnostic fault";
                break;
            case 6:
                valueString = "Reserved";
                break;
            case 7:
                valueString = "Normal working";
                break;
            default:
                break;
        }
        return valueString;
    };

    const convertRemarkBhcStsOwTs = (value: number) => {
        let valueString = "-";
        if (!value) return valueString;
        switch (value) {
            case 0:
                valueString = "Normal state";
                break;
            case 1:
                valueString = "abnormality";
                break;
            default:
                break;
        }
        return valueString;
    };

    const convertRemarkBhcStsErr = (value: number) => {
        let valueString = "-";
        if (!value) return valueString;
        switch (value) {
            case 0:
                valueString = "no_error";
                break;
            case 1:
                valueString = "HV Heater Relay Coil Error";
                break;
            case 2:
                valueString = "Heater Internal Error";
                break;
            default:
                break;
        }
        return valueString;
    };

    const convertRemarkError = (value: number) => {
        let valueString = "-";
        if (!value) return valueString;
        switch (value) {
            case 0:
                valueString = "No Error";
                break;
            case 1:
                valueString = "Error";
                break;
            default:
                break;
        }
        return valueString;
    };

    const convertRemarkMonitoring = (value: number) => {
        let valueString = "-";
        if (!value) return valueString;
        switch (value) {
            case 0:
                valueString = "Monitoring";
                break;
            case 1:
                valueString = "Stop Monitoring";
                break;
            default:
                break;
        }
        return valueString;
    };

    const convertRemarkTorqueMode = (value: number) => {
        let valueString = "-";
        if (!value) return valueString;
        switch (value) {
            case 0:
                valueString = "Torque Mode";
                break;
            case 3:
                valueString = "Invalid";
                break;
            default:
                break;
        }
        return valueString;
    };

    const convertRemarkOperationalModeCmd = (value: number) => {
        let valueString = "-";
        if (!value) return valueString;
        switch (value) {
            case 0:
                valueString = "Neutral";
                break;
            case 3:
                valueString = "Ev";
                break;
            case 7:
                valueString = "Invalid";
                break;
            default:
                break;
        }
        return valueString;
    };

    //#region Bms1
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [bms1PanelOpen, setBms1PanelOpen] = useState(false);
    const [bms1ChartMounted, setBms1ChartMounted] = useState(false);
    const [selectedBms1, setSelectedBms1] = useState<Array<React.Key>>([]);

    const [seriesChartDatasBms1, setSeriesChartDatasBms1] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartBms1BatPackStatus, setChartBms1BatPackStatus] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B1_BAT_PACK_STATUS_NAME,
        data: [],
    });

    const [chartBms1OperationMode, setChartBms1OperationMode] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B1_OPERATION_MODE_NAME,
        data: [],
    });

    const [chartBms1DcLinkVolt, setChartBms1DcLinkVolt] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B1_DC_LINK_VOLT_NAME,
        data: [],
    });

    const [chartBms1BatPackCurr, setChartBms1BatPackCurr] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B1_BAT_PACK_CURR_NAME,
        data: [],
    });

    const [chartBms1MainMinusContactorCoilState, setChartBms1MainMinusContactorCoilState] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B1_MAIN_MINUS_CONTACTOR_COIL_STATE_NAME,
        data: [],
    });

    const [chartBms1MainPlusContactorCoilState, setChartBms1MainPlusContactorCoilState] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B1_MAIN_PLUS_CONTACTOR_COIL_STATE_NAME,
        data: [],
    });

    const [chartBms1PreChargeContactorCoilState, setChartBms1PreChargeContactorCoilState] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B1_PRE_CHARGE_CONTACTOR_COIL_STATE_NAME,
        data: [],
    });

    const [chartBms1IgnStat, setChartBms1IgnStat] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B1_IGN_STAT_NAME,
        data: [],
    });

    const [chartBms1HvilStatus, setChartBms1HvilStatus] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B1_HVIL_STATUS_NAME,
        data: [],
    });

    const [chartBms1Counter, setChartBms1Counter] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B1_COUNTER_NAME,
        data: [],
    });

    const [chartBms1MainMinusContactorState, setChartBms1MainMinusContactorState] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B1_MAIN_MINUS_CONTACTOR_STATE_NAME,
        data: [],
    });

    const [chartBms1MainPlusContactorState, setChartBms1MainPlusContactorState] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B1_MAIN_PLUS_CONTACTOR_STATE_NAME,
        data: [],
    });

    const [chartBms1PreChargeContactorState, setChartBms1PreChargeContactorState] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B1_PRE_CHARGE_CONTACTOR_STATE_NAME,
        data: [],
    });

    const [chartBms1ContactorOffRequest, setChartBms1ContactorOffRequest] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B1_CONTACTOR_OFF_REQUEST_NAME,
        data: [],
    });

    const [chartBms1Checksum, setChartBms1Checksum] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B1_CHECKSUM_NAME,
        data: [],
    });

    const getBms1BatPackStatusValue = () => {
        if (selectedBms1.includes(Common.EVSOLUTION_B1_BAT_PACK_STATUS_KEY) && currentCanData !== undefined) {
            return convertRemarkBatPackStatus(currentCanData.bms1?.batPackStatus);
        }
        return "-";
    };

    const getBms1OperationModeValue = () => {
        if (selectedBms1.includes(Common.EVSOLUTION_B1_OPERATION_MODE_KEY) && currentCanData !== undefined) {
            return convertRemarkOperationMode(currentCanData.bms1?.operationMode);
        }
        return "-";
    };

    const getBms1DcLinkVoltValue = () => {
        if (selectedBms1.includes(Common.EVSOLUTION_B1_DC_LINK_VOLT_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.bms1?.dcLinkVolt, Common.UNIT_VOLTAGE);
        }
        return "-";
    };

    const getBms1BatPackCurrValue = () => {
        if (selectedBms1.includes(Common.EVSOLUTION_B1_BAT_PACK_CURR_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.bms1?.batPackCurr, Common.UNIT_CURRENT);
        }
        return "-";
    };

    const getBms1MainMinusContactorCoilStateValue = () => {
        if (selectedBms1.includes(Common.EVSOLUTION_B1_MAIN_MINUS_CONTACTOR_COIL_STATE_KEY) && currentCanData !== undefined) {
            return convertRemarkContactorCoilState(currentCanData.bms1?.mainMinusContactorCoilState);
        }
        return "-";
    };

    const getBms1MainPlusContactorCoilStateValue = () => {
        if (selectedBms1.includes(Common.EVSOLUTION_B1_MAIN_PLUS_CONTACTOR_COIL_STATE_KEY) && currentCanData !== undefined) {
            return convertRemarkContactorCoilState(currentCanData.bms1?.mainPlusContactorCoilState);
        }
        return "-";
    };

    const getBms1PreChargeContactorCoilStateValue = () => {
        if (selectedBms1.includes(Common.EVSOLUTION_B1_PRE_CHARGE_CONTACTOR_COIL_STATE_KEY) && currentCanData !== undefined) {
            return convertRemarkContactorCoilState(currentCanData.bms1?.preChargeContactorCoilState);
        }
        return "-";
    };

    const getBms1IgnStatValue = () => {
        if (selectedBms1.includes(Common.EVSOLUTION_B1_IGN_STAT_KEY) && currentCanData !== undefined) {
            return convertRemarkContactorCoilState(currentCanData.bms1?.ignStat);
        }
        return "-";
    };

    const getBms1HvilStatusValue = () => {
        if (selectedBms1.includes(Common.EVSOLUTION_B1_HVIL_STATUS_KEY) && currentCanData !== undefined) {
            return convertRemarkFault(currentCanData.bms1?.hvilStatus);
        }
        return "-";
    };

    const getBms1CounterValue = () => {
        if (selectedBms1.includes(Common.EVSOLUTION_B1_COUNTER_KEY) && currentCanData !== undefined) {
            return currentCanData.bms1?.counter;
        }
        return "-";
    };

    const getBms1MainMinusContactorStateValue = () => {
        if (selectedBms1.includes(Common.EVSOLUTION_B1_MAIN_MINUS_CONTACTOR_STATE_KEY) && currentCanData !== undefined) {
            return convertRemarkContactorCoilState(currentCanData.bms1?.mainMinusContactorState);
        }
        return "-";
    };

    const getBms1MainPlusContactorStateValue = () => {
        if (selectedBms1.includes(Common.EVSOLUTION_B1_MAIN_PLUS_CONTACTOR_STATE_KEY) && currentCanData !== undefined) {
            return convertRemarkContactorCoilState(currentCanData.bms1?.mainPlusContactorState);
        }
        return "-";
    };

    const getBms1PreChargeContactorStateValue = () => {
        if (selectedBms1.includes(Common.EVSOLUTION_B1_PRE_CHARGE_CONTACTOR_STATE_KEY) && currentCanData !== undefined) {
            return convertRemarkContactorCoilState(currentCanData.bms1?.preChargeContactorState);
        }
        return "-";
    };

    const getBms1ContactorOffRequestValue = () => {
        if (selectedBms1.includes(Common.EVSOLUTION_B1_CONTACTOR_OFF_REQUEST_KEY) && currentCanData !== undefined) {
            return convertRemarkRequest(currentCanData.bms1?.contactorOffRequest);
        }
        return "-";
    };

    const getBms1ChecksumValue = () => {
        if (selectedBms1.includes(Common.EVSOLUTION_B1_CHECKSUM_KEY) && currentCanData !== undefined) {
            return currentCanData.bms1?.checksum;
        }
        return "-";
    };

    const onChangeBms1 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedBms1(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasBms1: Array<TypeUtils.ChartData> = [];

        if (selectedBms1.includes(Common.EVSOLUTION_B1_BAT_PACK_STATUS_KEY)) chartDatasBms1.push(chartBms1BatPackStatus);
        if (selectedBms1.includes(Common.EVSOLUTION_B1_OPERATION_MODE_KEY)) chartDatasBms1.push(chartBms1OperationMode);
        if (selectedBms1.includes(Common.EVSOLUTION_B1_DC_LINK_VOLT_KEY)) chartDatasBms1.push(chartBms1DcLinkVolt);
        if (selectedBms1.includes(Common.EVSOLUTION_B1_BAT_PACK_CURR_KEY)) chartDatasBms1.push(chartBms1BatPackCurr);
        if (selectedBms1.includes(Common.EVSOLUTION_B1_MAIN_MINUS_CONTACTOR_COIL_STATE_KEY))
            chartDatasBms1.push(chartBms1MainMinusContactorCoilState);
        if (selectedBms1.includes(Common.EVSOLUTION_B1_MAIN_PLUS_CONTACTOR_COIL_STATE_KEY)) chartDatasBms1.push(chartBms1MainPlusContactorCoilState);
        if (selectedBms1.includes(Common.EVSOLUTION_B1_PRE_CHARGE_CONTACTOR_COIL_STATE_KEY))
            chartDatasBms1.push(chartBms1PreChargeContactorCoilState);
        if (selectedBms1.includes(Common.EVSOLUTION_B1_IGN_STAT_KEY)) chartDatasBms1.push(chartBms1IgnStat);
        if (selectedBms1.includes(Common.EVSOLUTION_B1_HVIL_STATUS_KEY)) chartDatasBms1.push(chartBms1HvilStatus);
        if (selectedBms1.includes(Common.EVSOLUTION_B1_COUNTER_KEY)) chartDatasBms1.push(chartBms1Counter);
        if (selectedBms1.includes(Common.EVSOLUTION_B1_MAIN_MINUS_CONTACTOR_STATE_KEY)) chartDatasBms1.push(chartBms1MainMinusContactorState);
        if (selectedBms1.includes(Common.EVSOLUTION_B1_MAIN_PLUS_CONTACTOR_STATE_KEY)) chartDatasBms1.push(chartBms1MainPlusContactorState);
        if (selectedBms1.includes(Common.EVSOLUTION_B1_PRE_CHARGE_CONTACTOR_STATE_KEY)) chartDatasBms1.push(chartBms1PreChargeContactorState);
        if (selectedBms1.includes(Common.EVSOLUTION_B1_CONTACTOR_OFF_REQUEST_KEY)) chartDatasBms1.push(chartBms1ContactorOffRequest);
        if (selectedBms1.includes(Common.EVSOLUTION_B1_CHECKSUM_KEY)) chartDatasBms1.push(chartBms1Checksum);

        setSeriesChartDatasBms1(chartDatasBms1);
    }, [
        selectedBms1,
        chartBms1BatPackStatus,
        chartBms1OperationMode,
        chartBms1DcLinkVolt,
        chartBms1BatPackCurr,
        chartBms1MainMinusContactorCoilState,
        chartBms1MainPlusContactorCoilState,
        chartBms1PreChargeContactorCoilState,
        chartBms1IgnStat,
        chartBms1HvilStatus,
        chartBms1Counter,
        chartBms1MainMinusContactorState,
        chartBms1MainPlusContactorState,
        chartBms1PreChargeContactorState,
        chartBms1ContactorOffRequest,
        chartBms1Checksum,
    ]);

    const tableDataBms1: CanTableType[] = [
        {
            key: Common.EVSOLUTION_B1_BAT_PACK_STATUS_KEY,
            name: Common.EVSOLUTION_B1_BAT_PACK_STATUS_NAME,
            value: getBms1BatPackStatusValue(),
        },
        {
            key: Common.EVSOLUTION_B1_OPERATION_MODE_KEY,
            name: Common.EVSOLUTION_B1_OPERATION_MODE_NAME,
            value: getBms1OperationModeValue(),
        },
        {
            key: Common.EVSOLUTION_B1_DC_LINK_VOLT_KEY,
            name: Common.EVSOLUTION_B1_DC_LINK_VOLT_NAME,
            value: getBms1DcLinkVoltValue(),
        },
        {
            key: Common.EVSOLUTION_B1_BAT_PACK_CURR_KEY,
            name: Common.EVSOLUTION_B1_BAT_PACK_CURR_NAME,
            value: getBms1BatPackCurrValue(),
        },
        {
            key: Common.EVSOLUTION_B1_MAIN_MINUS_CONTACTOR_COIL_STATE_KEY,
            name: Common.EVSOLUTION_B1_MAIN_MINUS_CONTACTOR_COIL_STATE_NAME,
            value: getBms1MainMinusContactorCoilStateValue(),
        },

        {
            key: Common.EVSOLUTION_B1_MAIN_PLUS_CONTACTOR_COIL_STATE_KEY,
            name: Common.EVSOLUTION_B1_MAIN_PLUS_CONTACTOR_COIL_STATE_NAME,
            value: getBms1MainPlusContactorCoilStateValue(),
        },
        {
            key: Common.EVSOLUTION_B1_PRE_CHARGE_CONTACTOR_COIL_STATE_KEY,
            name: Common.EVSOLUTION_B1_PRE_CHARGE_CONTACTOR_COIL_STATE_NAME,
            value: getBms1PreChargeContactorCoilStateValue(),
        },
        {
            key: Common.EVSOLUTION_B1_IGN_STAT_KEY,
            name: Common.EVSOLUTION_B1_IGN_STAT_NAME,
            value: getBms1IgnStatValue(),
        },
        {
            key: Common.EVSOLUTION_B1_HVIL_STATUS_KEY,
            name: Common.EVSOLUTION_B1_HVIL_STATUS_NAME,
            value: getBms1HvilStatusValue(),
        },
        {
            key: Common.EVSOLUTION_B1_COUNTER_KEY,
            name: Common.EVSOLUTION_B1_COUNTER_NAME,
            value: getBms1CounterValue(),
        },

        {
            key: Common.EVSOLUTION_B1_MAIN_MINUS_CONTACTOR_STATE_KEY,
            name: Common.EVSOLUTION_B1_MAIN_MINUS_CONTACTOR_STATE_NAME,
            value: getBms1MainMinusContactorStateValue(),
        },
        {
            key: Common.EVSOLUTION_B1_MAIN_PLUS_CONTACTOR_STATE_KEY,
            name: Common.EVSOLUTION_B1_MAIN_PLUS_CONTACTOR_STATE_NAME,
            value: getBms1MainPlusContactorStateValue(),
        },
        {
            key: Common.EVSOLUTION_B1_PRE_CHARGE_CONTACTOR_STATE_KEY,
            name: Common.EVSOLUTION_B1_PRE_CHARGE_CONTACTOR_STATE_NAME,
            value: getBms1PreChargeContactorStateValue(),
        },
        {
            key: Common.EVSOLUTION_B1_CONTACTOR_OFF_REQUEST_KEY,
            name: Common.EVSOLUTION_B1_CONTACTOR_OFF_REQUEST_NAME,
            value: getBms1ContactorOffRequestValue(),
        },
        {
            key: Common.EVSOLUTION_B1_CHECKSUM_KEY,
            name: Common.EVSOLUTION_B1_CHECKSUM_NAME,
            value: getBms1ChecksumValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Bms2
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [bms2PanelOpen, setBms2PanelOpen] = useState(false);
    const [bms2ChartMounted, setBms2ChartMounted] = useState(false);
    const [selectedBms2, setSelectedBms2] = useState<Array<React.Key>>([]);

    const [seriesChartDatasBms2, setSeriesChartDatasBms2] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartBms2BatPackVolt, setChartBms2BatPackVolt] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B2_BAT_PACK_VOLT_NAME,
        data: [],
    });

    const [chartBms2BmsMode, setChartBms2BmsMode] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B2_BMS_MODE_NAME,
        data: [],
    });

    const [chartBms2WaterPumpStatus, setChartBms2WaterPumpStatus] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B2_WATER_PUMP_STATUS_NAME,
        data: [],
    });

    const [chartBms2BalancingStatus, setChartBms2BalancingStatus] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B2_BALANCING_STATUS_NAME,
        data: [],
    });

    const [chartBms2BatPackAsoc, setChartBms2BatPackAsoc] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B2_BAT_PACK_ASOC_NAME,
        data: [],
    });

    const [chartBms2BatPackRsoc, setChartBms2BatPackRsoc] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B2_BAT_PACK_RSOC_NAME,
        data: [],
    });

    const [chartBms2BatPackSoh, setChartBms2BatPackSoh] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B2_BAT_PACK_SOH_NAME,
        data: [],
    });

    const getBms2BatPackVoltValue = () => {
        if (selectedBms2.includes(Common.EVSOLUTION_B2_BAT_PACK_VOLT_KEY) && currentCanData !== undefined) {
            return currentCanData.bms2?.batPackVolt;
        }
        return "-";
    };

    const getBms2BmsModeValue = () => {
        if (selectedBms2.includes(Common.EVSOLUTION_B2_BMS_MODE_KEY) && currentCanData !== undefined) {
            return convertRemarkBmsMode(currentCanData.bms2?.bmsMode);
        }
        return "-";
    };

    const getBms2WaterPumpStatusValue = () => {
        if (selectedBms2.includes(Common.EVSOLUTION_B2_WATER_PUMP_STATUS_KEY) && currentCanData !== undefined) {
            return convertRemarkContactorCoilState(currentCanData.bms2?.waterPumpStatus);
        }
        return "-";
    };

    const getBms2BalancingStatusValue = () => {
        if (selectedBms2.includes(Common.EVSOLUTION_B2_BALANCING_STATUS_KEY) && currentCanData !== undefined) {
            return convertRemarkNoYes(currentCanData.bms2?.balancingStatus);
        }
        return "-";
    };

    const getBms2BatPackAsocValue = () => {
        if (selectedBms2.includes(Common.EVSOLUTION_B2_BAT_PACK_ASOC_KEY) && currentCanData !== undefined) {
            return currentCanData.bms2?.batPackAsoc;
        }
        return "-";
    };

    const getBms2BatPackRsocValue = () => {
        if (selectedBms2.includes(Common.EVSOLUTION_B2_BAT_PACK_RSOC_KEY) && currentCanData !== undefined) {
            return currentCanData.bms2?.batPackRsoc;
        }
        return "-";
    };

    const getBms2BatPackSohValue = () => {
        if (selectedBms2.includes(Common.EVSOLUTION_B2_BAT_PACK_SOH_KEY) && currentCanData !== undefined) {
            return currentCanData.bms2?.batPackSoh;
        }
        return "-";
    };

    const onChangeBms2 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedBms2(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasBms2: Array<TypeUtils.ChartData> = [];

        if (selectedBms2.includes(Common.EVSOLUTION_B2_BAT_PACK_VOLT_KEY)) chartDatasBms2.push(chartBms2BatPackVolt);
        if (selectedBms2.includes(Common.EVSOLUTION_B2_BMS_MODE_KEY)) chartDatasBms2.push(chartBms2BmsMode);
        if (selectedBms2.includes(Common.EVSOLUTION_B2_WATER_PUMP_STATUS_KEY)) chartDatasBms2.push(chartBms2WaterPumpStatus);
        if (selectedBms2.includes(Common.EVSOLUTION_B2_BALANCING_STATUS_KEY)) chartDatasBms2.push(chartBms2BalancingStatus);
        if (selectedBms2.includes(Common.EVSOLUTION_B2_BAT_PACK_ASOC_KEY)) chartDatasBms2.push(chartBms2BatPackAsoc);
        if (selectedBms2.includes(Common.EVSOLUTION_B2_BAT_PACK_RSOC_KEY)) chartDatasBms2.push(chartBms2BatPackRsoc);
        if (selectedBms2.includes(Common.EVSOLUTION_B2_BAT_PACK_SOH_KEY)) chartDatasBms2.push(chartBms2BatPackSoh);

        setSeriesChartDatasBms2(chartDatasBms2);
    }, [
        selectedBms2,
        chartBms2BatPackVolt,
        chartBms2BmsMode,
        chartBms2WaterPumpStatus,
        chartBms2BalancingStatus,
        chartBms2BatPackAsoc,
        chartBms2BatPackRsoc,
        chartBms2BatPackSoh,
    ]);

    const tableDataBms2: CanTableType[] = [
        {
            key: Common.EVSOLUTION_B2_BAT_PACK_VOLT_KEY,
            name: Common.EVSOLUTION_B2_BAT_PACK_VOLT_NAME,
            value: getBms2BatPackVoltValue(),
        },
        {
            key: Common.EVSOLUTION_B2_BMS_MODE_KEY,
            name: Common.EVSOLUTION_B2_BMS_MODE_NAME,
            value: getBms2BmsModeValue(),
        },
        {
            key: Common.EVSOLUTION_B2_WATER_PUMP_STATUS_KEY,
            name: Common.EVSOLUTION_B2_WATER_PUMP_STATUS_NAME,
            value: getBms2WaterPumpStatusValue(),
        },
        {
            key: Common.EVSOLUTION_B2_BALANCING_STATUS_KEY,
            name: Common.EVSOLUTION_B2_BALANCING_STATUS_NAME,
            value: getBms2BalancingStatusValue(),
        },
        {
            key: Common.EVSOLUTION_B2_BAT_PACK_ASOC_KEY,
            name: Common.EVSOLUTION_B2_BAT_PACK_ASOC_NAME,
            value: getBms2BatPackAsocValue(),
        },

        {
            key: Common.EVSOLUTION_B2_BAT_PACK_RSOC_KEY,
            name: Common.EVSOLUTION_B2_BAT_PACK_RSOC_NAME,
            value: getBms2BatPackRsocValue(),
        },
        {
            key: Common.EVSOLUTION_B2_BAT_PACK_SOH_KEY,
            name: Common.EVSOLUTION_B2_BAT_PACK_SOH_NAME,
            value: getBms2BatPackSohValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Bms3
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [bms3PanelOpen, setBms3PanelOpen] = useState(false);
    const [bms3ChartMounted, setBms3ChartMounted] = useState(false);
    const [selectedBms3, setSelectedBms3] = useState<Array<React.Key>>([]);

    const [seriesChartDatasBms3, setSeriesChartDatasBms3] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartBms3BatMinCellVolt, setChartBms3BatMinCellVolt] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B3_BAT_MIN_CELL_VOLT_NAME,
        data: [],
    });

    const [chartBms3BatMaxCellVolt, setChartBms3BatMaxCellVolt] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B3_BAT_MAX_CELL_VOLT_NAME,
        data: [],
    });

    const [chartBms3BatAvgCellVolt, setChartBms3BatAvgCellVolt] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B3_BAT_AVG_CELL_VOLT_NAME,
        data: [],
    });

    const [chartBms3BatSumVolt, setChartBms3BatSumVolt] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B3_BAT_SUM_VOLT_NAME,
        data: [],
    });

    const getBms3BatMinCellVoltValue = () => {
        if (selectedBms3.includes(Common.EVSOLUTION_B3_BAT_MIN_CELL_VOLT_KEY) && currentCanData !== undefined) {
            return currentCanData.bms3?.batMinCellVolt;
        }
        return "-";
    };

    const getBms3BatMaxCellVoltValue = () => {
        if (selectedBms3.includes(Common.EVSOLUTION_B3_BAT_MAX_CELL_VOLT_KEY) && currentCanData !== undefined) {
            return currentCanData.bms3?.batMaxCellVolt;
        }
        return "-";
    };

    const getBms3BatAvgCellVoltValue = () => {
        if (selectedBms3.includes(Common.EVSOLUTION_B3_BAT_AVG_CELL_VOLT_KEY) && currentCanData !== undefined) {
            return currentCanData.bms3?.batAvgCellVolt;
        }
        return "-";
    };

    const getBms3BatSumVoltValue = () => {
        if (selectedBms3.includes(Common.EVSOLUTION_B3_BAT_SUM_VOLT_KEY) && currentCanData !== undefined) {
            return currentCanData.bms3?.batSumVolt;
        }
        return "-";
    };

    const onChangeBms3 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedBms3(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasBms3: Array<TypeUtils.ChartData> = [];

        if (selectedBms3.includes(Common.EVSOLUTION_B3_BAT_MIN_CELL_VOLT_KEY)) chartDatasBms3.push(chartBms3BatMinCellVolt);
        if (selectedBms3.includes(Common.EVSOLUTION_B3_BAT_MAX_CELL_VOLT_KEY)) chartDatasBms3.push(chartBms3BatMaxCellVolt);
        if (selectedBms3.includes(Common.EVSOLUTION_B3_BAT_AVG_CELL_VOLT_KEY)) chartDatasBms3.push(chartBms3BatAvgCellVolt);
        if (selectedBms3.includes(Common.EVSOLUTION_B3_BAT_SUM_VOLT_KEY)) chartDatasBms3.push(chartBms3BatSumVolt);

        setSeriesChartDatasBms3(chartDatasBms3);
    }, [selectedBms3, chartBms3BatMinCellVolt, chartBms3BatMaxCellVolt, chartBms3BatAvgCellVolt, chartBms3BatSumVolt]);

    const tableDataBms3: CanTableType[] = [
        {
            key: Common.EVSOLUTION_B3_BAT_MIN_CELL_VOLT_KEY,
            name: Common.EVSOLUTION_B3_BAT_MIN_CELL_VOLT_NAME,
            value: getBms3BatMinCellVoltValue(),
        },
        {
            key: Common.EVSOLUTION_B3_BAT_MAX_CELL_VOLT_KEY,
            name: Common.EVSOLUTION_B3_BAT_MAX_CELL_VOLT_NAME,
            value: getBms3BatMaxCellVoltValue(),
        },
        {
            key: Common.EVSOLUTION_B3_BAT_AVG_CELL_VOLT_KEY,
            name: Common.EVSOLUTION_B3_BAT_AVG_CELL_VOLT_NAME,
            value: getBms3BatAvgCellVoltValue(),
        },
        {
            key: Common.EVSOLUTION_B3_BAT_SUM_VOLT_KEY,
            name: Common.EVSOLUTION_B3_BAT_SUM_VOLT_NAME,
            value: getBms3BatSumVoltValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Bms4
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [bms4PanelOpen, setBms4PanelOpen] = useState(false);
    const [bms4ChartMounted, setBms4ChartMounted] = useState(false);
    const [selectedBms4, setSelectedBms4] = useState<Array<React.Key>>([]);

    const [seriesChartDatasBms4, setSeriesChartDatasBms4] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartBms4BatMinTemp, setChartBms4BatMinTemp] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B4_BAT_MIN_TEMP_NAME,
        data: [],
    });

    const [chartBms4BatMaxTemp, setChartBms4BatMaxTemp] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B4_BAT_MAX_TEMP_NAME,
        data: [],
    });

    const [chartBms4BatAvgTemp, setChartBms4BatAvgTemp] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B4_BAT_AVG_TEMP_NAME,
        data: [],
    });

    const [chartBms4BatMinTempLocation, setChartBms4BatMinTempLocation] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B4_BAT_MIN_TEMP_LOCATION_NAME,
        data: [],
    });

    const [chartBms4BatMaxTempLocation, setChartBms4BatMaxTempLocation] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B4_BAT_MAX_TEMP_LOCATION_NAME,
        data: [],
    });

    const [chartBms4CoolingPumpFeedbackStatus, setChartBms4CoolingPumpFeedbackStatus] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B4_COOLING_PUMP_FEEDBACK_STATUS_NAME,
        data: [],
    });

    const [chartBms4CoolingRequest, setChartBms4CoolingRequest] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B4_COOLING_REQUEST_NAME,
        data: [],
    });

    const [chartBms4ChargingStop, setChartBms4ChargingStop] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B4_CHARGING_STOP_NAME,
        data: [],
    });

    const [chartBms4BhcStsOwTs, setChartBms4BhcStsOwTs] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B4_BHC_STS_OW_TS_NAME,
        data: [],
    });

    const [chartBms4BhcStsWhuWtO, setChartBms4BhcStsWhuWtO] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B4_BHC_STS_WHU_WT_O_NAME,
        data: [],
    });

    const [chartBms4BhcStsErr, setChartBms4BhcStsErr] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B4_BHC_STS_ERR_NAME,
        data: [],
    });

    const [chartBms4AuxBattery, setChartBms4AuxBattery] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B4_AUX_BATTERY_NAME,
        data: [],
    });

    const getBms4BatMinTempValue = () => {
        if (selectedBms4.includes(Common.EVSOLUTION_B4_BAT_MIN_TEMP_KEY) && currentCanData !== undefined) {
            return currentCanData.bms4?.batMinTemp;
        }
        return "-";
    };

    const getBms4BatMaxTempValue = () => {
        if (selectedBms4.includes(Common.EVSOLUTION_B4_BAT_MAX_TEMP_KEY) && currentCanData !== undefined) {
            return currentCanData.bms4?.batMaxTemp;
        }
        return "-";
    };

    const getBms4BatAvgTempValue = () => {
        if (selectedBms4.includes(Common.EVSOLUTION_B4_BAT_AVG_TEMP_KEY) && currentCanData !== undefined) {
            return currentCanData.bms4?.batAvgTemp;
        }
        return "-";
    };

    const getBms4BatMinTempLocationValue = () => {
        if (selectedBms4.includes(Common.EVSOLUTION_B4_BAT_MIN_TEMP_LOCATION_KEY) && currentCanData !== undefined) {
            return currentCanData.bms4?.batMinTempLocation;
        }
        return "-";
    };

    const getBms4BatMaxTempLocationValue = () => {
        if (selectedBms4.includes(Common.EVSOLUTION_B4_BAT_MAX_TEMP_LOCATION_KEY) && currentCanData !== undefined) {
            return currentCanData.bms4?.batMaxTempLocation;
        }
        return "-";
    };

    const getBms4CoolingPumpFeedbackStatusValue = () => {
        if (selectedBms4.includes(Common.EVSOLUTION_B4_COOLING_PUMP_FEEDBACK_STATUS_KEY) && currentCanData !== undefined) {
            return convertRemarkCoolingPumpFeedbackStatus(currentCanData.bms4?.coolingPumpFeedbackStatus);
        }
        return "-";
    };

    const getBms4CoolingRequestValue = () => {
        if (selectedBms4.includes(Common.EVSOLUTION_B4_COOLING_REQUEST_KEY) && currentCanData !== undefined) {
            return convertRemarkRequest(currentCanData.bms4?.coolingRequest);
        }
        return "-";
    };

    const getBms4ChargingStopValue = () => {
        if (selectedBms4.includes(Common.EVSOLUTION_B4_CHARGING_STOP_KEY) && currentCanData !== undefined) {
            return convertRemarkRequest(currentCanData.bms4?.chargingStop);
        }
        return "-";
    };

    const getBms4BhcStsOwTsValue = () => {
        if (selectedBms4.includes(Common.EVSOLUTION_B4_BHC_STS_OW_TS_KEY) && currentCanData !== undefined) {
            return convertRemarkBhcStsOwTs(currentCanData.bms4?.bhcStsOwTs);
        }
        return "-";
    };

    const getBms4BhcStsWhuWtOValue = () => {
        if (selectedBms4.includes(Common.EVSOLUTION_B4_BHC_STS_WHU_WT_O_KEY) && currentCanData !== undefined) {
            return currentCanData.bms4?.bhcStsWhuWtO;
        }
        return "-";
    };

    const getBms4BhcStsErrValue = () => {
        if (selectedBms4.includes(Common.EVSOLUTION_B4_BHC_STS_ERR_KEY) && currentCanData !== undefined) {
            return convertRemarkBhcStsErr(currentCanData.bms4?.bhcStsErr);
        }
        return "-";
    };

    const getBms4AuxBatteryValue = () => {
        if (selectedBms4.includes(Common.EVSOLUTION_B4_AUX_BATTERY_KEY) && currentCanData !== undefined) {
            return currentCanData.bms4?.auxBattery;
        }
        return "-";
    };

    const onChangeBms4 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedBms4(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasBms4: Array<TypeUtils.ChartData> = [];

        if (selectedBms4.includes(Common.EVSOLUTION_B4_BAT_MIN_TEMP_KEY)) chartDatasBms4.push(chartBms4BatMinTemp);
        if (selectedBms4.includes(Common.EVSOLUTION_B4_BAT_MAX_TEMP_KEY)) chartDatasBms4.push(chartBms4BatMaxTemp);
        if (selectedBms4.includes(Common.EVSOLUTION_B4_BAT_AVG_TEMP_KEY)) chartDatasBms4.push(chartBms4BatAvgTemp);
        if (selectedBms4.includes(Common.EVSOLUTION_B4_BAT_MIN_TEMP_LOCATION_KEY)) chartDatasBms4.push(chartBms4BatMinTempLocation);
        if (selectedBms4.includes(Common.EVSOLUTION_B4_BAT_MAX_TEMP_LOCATION_KEY)) chartDatasBms4.push(chartBms4BatMaxTempLocation);
        if (selectedBms4.includes(Common.EVSOLUTION_B4_COOLING_PUMP_FEEDBACK_STATUS_KEY)) chartDatasBms4.push(chartBms4CoolingPumpFeedbackStatus);
        if (selectedBms4.includes(Common.EVSOLUTION_B4_COOLING_REQUEST_KEY)) chartDatasBms4.push(chartBms4CoolingRequest);
        if (selectedBms4.includes(Common.EVSOLUTION_B4_CHARGING_STOP_KEY)) chartDatasBms4.push(chartBms4ChargingStop);
        if (selectedBms4.includes(Common.EVSOLUTION_B4_BHC_STS_OW_TS_KEY)) chartDatasBms4.push(chartBms4BhcStsOwTs);
        if (selectedBms4.includes(Common.EVSOLUTION_B4_BHC_STS_WHU_WT_O_KEY)) chartDatasBms4.push(chartBms4BhcStsWhuWtO);
        if (selectedBms4.includes(Common.EVSOLUTION_B4_BHC_STS_ERR_KEY)) chartDatasBms4.push(chartBms4BhcStsErr);
        if (selectedBms4.includes(Common.EVSOLUTION_B4_AUX_BATTERY_KEY)) chartDatasBms4.push(chartBms4AuxBattery);

        setSeriesChartDatasBms4(chartDatasBms4);
    }, [
        selectedBms4,
        chartBms4BatMinTemp,
        chartBms4BatMaxTemp,
        chartBms4BatAvgTemp,
        chartBms4BatMinTempLocation,
        chartBms4BatMaxTempLocation,
        chartBms4CoolingPumpFeedbackStatus,
        chartBms4CoolingRequest,
        chartBms4ChargingStop,
        chartBms4BhcStsOwTs,
        chartBms4BhcStsWhuWtO,
        chartBms4BhcStsErr,
        chartBms4AuxBattery,
    ]);

    const tableDataBms4: CanTableType[] = [
        {
            key: Common.EVSOLUTION_B4_BAT_MIN_TEMP_KEY,
            name: Common.EVSOLUTION_B4_BAT_MIN_TEMP_NAME,
            value: getBms4BatMinTempValue(),
        },
        {
            key: Common.EVSOLUTION_B4_BAT_MAX_TEMP_KEY,
            name: Common.EVSOLUTION_B4_BAT_MAX_TEMP_NAME,
            value: getBms4BatMaxTempValue(),
        },
        {
            key: Common.EVSOLUTION_B4_BAT_AVG_TEMP_KEY,
            name: Common.EVSOLUTION_B4_BAT_AVG_TEMP_NAME,
            value: getBms4BatAvgTempValue(),
        },
        {
            key: Common.EVSOLUTION_B4_BAT_MIN_TEMP_LOCATION_KEY,
            name: Common.EVSOLUTION_B4_BAT_MIN_TEMP_LOCATION_NAME,
            value: getBms4BatMinTempLocationValue(),
        },
        {
            key: Common.EVSOLUTION_B4_BAT_MAX_TEMP_LOCATION_KEY,
            name: Common.EVSOLUTION_B4_BAT_MAX_TEMP_LOCATION_NAME,
            value: getBms4BatMaxTempLocationValue(),
        },

        {
            key: Common.EVSOLUTION_B4_COOLING_PUMP_FEEDBACK_STATUS_KEY,
            name: Common.EVSOLUTION_B4_COOLING_PUMP_FEEDBACK_STATUS_NAME,
            value: getBms4CoolingPumpFeedbackStatusValue(),
        },
        {
            key: Common.EVSOLUTION_B4_COOLING_REQUEST_KEY,
            name: Common.EVSOLUTION_B4_COOLING_REQUEST_NAME,
            value: getBms4CoolingRequestValue(),
        },
        {
            key: Common.EVSOLUTION_B4_CHARGING_STOP_KEY,
            name: Common.EVSOLUTION_B4_CHARGING_STOP_NAME,
            value: getBms4ChargingStopValue(),
        },
        {
            key: Common.EVSOLUTION_B4_BHC_STS_OW_TS_KEY,
            name: Common.EVSOLUTION_B4_BHC_STS_OW_TS_NAME,
            value: getBms4BhcStsOwTsValue(),
        },
        {
            key: Common.EVSOLUTION_B4_BHC_STS_WHU_WT_O_KEY,
            name: Common.EVSOLUTION_B4_BHC_STS_WHU_WT_O_NAME,
            value: getBms4BhcStsWhuWtOValue(),
        },
        {
            key: Common.EVSOLUTION_B4_BHC_STS_ERR_KEY,
            name: Common.EVSOLUTION_B4_BHC_STS_ERR_NAME,
            value: getBms4BhcStsErrValue(),
        },
        {
            key: Common.EVSOLUTION_B4_AUX_BATTERY_KEY,
            name: Common.EVSOLUTION_B4_AUX_BATTERY_NAME,
            value: getBms4AuxBatteryValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Bms5
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [bms5PanelOpen, setBms5PanelOpen] = useState(false);
    const [bms5ChartMounted, setBms5ChartMounted] = useState(false);
    const [selectedBms5, setSelectedBms5] = useState<Array<React.Key>>([]);

    const [seriesChartDatasBms5, setSeriesChartDatasBms5] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartBms5ChargePowerAvailable2, setChartBms5ChargePowerAvailable2] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B5_CHARGE_POWER_AVAILABLE_2_NAME,
        data: [],
    });

    const [chartBms5DischargePowerAvailable2, setChartBms5DischargePowerAvailable2] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B5_DISCHARGE_POWER_AVAILABLE_2_NAME,
        data: [],
    });

    const [chartBms5ChargePowerAvailable10, setChartBms5ChargePowerAvailable10] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B5_CHARGE_POWER_AVAILABLE_10_NAME,
        data: [],
    });

    const [chartBms5DischargePowerAvailable10, setChartBms5DischargePowerAvailable10] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B5_DISCHARGE_POWER_AVAILABLE_10_NAME,
        data: [],
    });

    const [chartBms5BatPackSoe, setChartBms5BatPackSoe] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B5_BAT_PACK_SOE_NAME,
        data: [],
    });

    const getBms5ChargePowerAvailable2Value = () => {
        if (selectedBms5.includes(Common.EVSOLUTION_B5_CHARGE_POWER_AVAILABLE_2_KEY) && currentCanData !== undefined) {
            return currentCanData.bms5?.chargePowerAvailable2;
        }
        return "-";
    };

    const getBms5DischargePowerAvailable2Value = () => {
        if (selectedBms5.includes(Common.EVSOLUTION_B5_DISCHARGE_POWER_AVAILABLE_2_KEY) && currentCanData !== undefined) {
            return currentCanData.bms5?.dischargePowerAvailable2;
        }
        return "-";
    };

    const getBms5ChargePowerAvailable10Value = () => {
        if (selectedBms5.includes(Common.EVSOLUTION_B5_CHARGE_POWER_AVAILABLE_10_KEY) && currentCanData !== undefined) {
            return currentCanData.bms5?.chargePowerAvailable10;
        }
        return "-";
    };

    const getBms5DischargePowerAvailable10Value = () => {
        if (selectedBms5.includes(Common.EVSOLUTION_B5_DISCHARGE_POWER_AVAILABLE_10_KEY) && currentCanData !== undefined) {
            return currentCanData.bms5?.dischargePowerAvailable10;
        }
        return "-";
    };

    const getBms5BatPackSoeValue = () => {
        if (selectedBms5.includes(Common.EVSOLUTION_B5_BAT_PACK_SOE_KEY) && currentCanData !== undefined) {
            return currentCanData.bms5?.batPackSoe;
        }
        return "-";
    };

    const onChangeBms5 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedBms5(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasBms5: Array<TypeUtils.ChartData> = [];

        if (selectedBms5.includes(Common.EVSOLUTION_B5_CHARGE_POWER_AVAILABLE_2_KEY)) chartDatasBms5.push(chartBms5ChargePowerAvailable2);
        if (selectedBms5.includes(Common.EVSOLUTION_B5_DISCHARGE_POWER_AVAILABLE_2_KEY)) chartDatasBms5.push(chartBms5DischargePowerAvailable2);
        if (selectedBms5.includes(Common.EVSOLUTION_B5_CHARGE_POWER_AVAILABLE_10_KEY)) chartDatasBms5.push(chartBms5ChargePowerAvailable10);
        if (selectedBms5.includes(Common.EVSOLUTION_B5_DISCHARGE_POWER_AVAILABLE_10_KEY)) chartDatasBms5.push(chartBms5DischargePowerAvailable10);
        if (selectedBms5.includes(Common.EVSOLUTION_B5_BAT_PACK_SOE_KEY)) chartDatasBms5.push(chartBms5BatPackSoe);

        setSeriesChartDatasBms5(chartDatasBms5);
    }, [
        selectedBms5,
        chartBms5ChargePowerAvailable2,
        chartBms5DischargePowerAvailable2,
        chartBms5ChargePowerAvailable10,
        chartBms5DischargePowerAvailable10,
        chartBms5BatPackSoe,
    ]);

    const tableDataBms5: CanTableType[] = [
        {
            key: Common.EVSOLUTION_B5_CHARGE_POWER_AVAILABLE_2_KEY,
            name: Common.EVSOLUTION_B5_CHARGE_POWER_AVAILABLE_2_NAME,
            value: getBms5ChargePowerAvailable2Value(),
        },
        {
            key: Common.EVSOLUTION_B5_DISCHARGE_POWER_AVAILABLE_2_KEY,
            name: Common.EVSOLUTION_B5_DISCHARGE_POWER_AVAILABLE_2_NAME,
            value: getBms5DischargePowerAvailable2Value(),
        },
        {
            key: Common.EVSOLUTION_B5_CHARGE_POWER_AVAILABLE_10_KEY,
            name: Common.EVSOLUTION_B5_CHARGE_POWER_AVAILABLE_10_NAME,
            value: getBms5ChargePowerAvailable10Value(),
        },
        {
            key: Common.EVSOLUTION_B5_DISCHARGE_POWER_AVAILABLE_10_KEY,
            name: Common.EVSOLUTION_B5_DISCHARGE_POWER_AVAILABLE_10_NAME,
            value: getBms5DischargePowerAvailable10Value(),
        },
        {
            key: Common.EVSOLUTION_B5_BAT_PACK_SOE_KEY,
            name: Common.EVSOLUTION_B5_BAT_PACK_SOE_NAME,
            value: getBms5BatPackSoeValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Bms6
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [bms6PanelOpen, setBms6PanelOpen] = useState(false);
    const [bms6ChartMounted, setBms6ChartMounted] = useState(false);
    const [selectedBms6, setSelectedBms6] = useState<Array<React.Key>>([]);

    const [seriesChartDatasBms6, setSeriesChartDatasBms6] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartBms6ChargeCurrLimit, setChartBms6ChargeCurrLimit] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B6_CHARGE_CURR_LIMIT_NAME,
        data: [],
    });

    const [chartBms6ChargeVoltLimit, setChartBms6ChargeVoltLimit] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B6_CHARGE_VOLT_LIMIT_NAME,
        data: [],
    });

    const [chartBms6InstantPowerConsumption, setChartBms6InstantPowerConsumption] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B6_INSTANT_POWER_CONSUMPTION_NAME,
        data: [],
    });

    const [chartBms6MinIsolationResistance, setChartBms6MinIsolationResistance] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_B6_MIN_ISOLATION_RESISTANCE_NAME,
        data: [],
    });

    const getBms6ChargeCurrLimitValue = () => {
        if (selectedBms6.includes(Common.EVSOLUTION_B6_CHARGE_CURR_LIMIT_KEY) && currentCanData !== undefined) {
            return currentCanData.bms6?.chargeCurrLimit;
        }
        return "-";
    };

    const getBms6ChargeVoltLimitValue = () => {
        if (selectedBms6.includes(Common.EVSOLUTION_B6_CHARGE_VOLT_LIMIT_KEY) && currentCanData !== undefined) {
            return currentCanData.bms6?.chargeVoltLimit;
        }
        return "-";
    };

    const getBms6InstantPowerConsumptionValue = () => {
        if (selectedBms6.includes(Common.EVSOLUTION_B6_INSTANT_POWER_CONSUMPTION_KEY) && currentCanData !== undefined) {
            return currentCanData.bms6?.instantPowerConsumption;
        }
        return "-";
    };

    const getBms6MinIsolationResistanceValue = () => {
        if (selectedBms6.includes(Common.EVSOLUTION_B6_MIN_ISOLATION_RESISTANCE_KEY) && currentCanData !== undefined) {
            return currentCanData.bms6?.minIsolationResistance;
        }
        return "-";
    };

    const onChangeBms6 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedBms6(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasBms6: Array<TypeUtils.ChartData> = [];

        if (selectedBms6.includes(Common.EVSOLUTION_B6_CHARGE_CURR_LIMIT_KEY)) chartDatasBms6.push(chartBms6ChargeCurrLimit);
        if (selectedBms6.includes(Common.EVSOLUTION_B6_CHARGE_VOLT_LIMIT_KEY)) chartDatasBms6.push(chartBms6ChargeVoltLimit);
        if (selectedBms6.includes(Common.EVSOLUTION_B6_INSTANT_POWER_CONSUMPTION_KEY)) chartDatasBms6.push(chartBms6InstantPowerConsumption);
        if (selectedBms6.includes(Common.EVSOLUTION_B6_MIN_ISOLATION_RESISTANCE_KEY)) chartDatasBms6.push(chartBms6MinIsolationResistance);

        setSeriesChartDatasBms6(chartDatasBms6);
    }, [selectedBms6, chartBms6ChargeCurrLimit, chartBms6ChargeVoltLimit, chartBms6InstantPowerConsumption, chartBms6MinIsolationResistance]);

    const tableDataBms6: CanTableType[] = [
        {
            key: Common.EVSOLUTION_B6_CHARGE_CURR_LIMIT_KEY,
            name: Common.EVSOLUTION_B6_CHARGE_CURR_LIMIT_NAME,
            value: getBms6ChargeCurrLimitValue(),
        },
        {
            key: Common.EVSOLUTION_B6_CHARGE_VOLT_LIMIT_KEY,
            name: Common.EVSOLUTION_B6_CHARGE_VOLT_LIMIT_NAME,
            value: getBms6ChargeVoltLimitValue(),
        },
        {
            key: Common.EVSOLUTION_B6_INSTANT_POWER_CONSUMPTION_KEY,
            name: Common.EVSOLUTION_B6_INSTANT_POWER_CONSUMPTION_NAME,
            value: getBms6InstantPowerConsumptionValue(),
        },
        {
            key: Common.EVSOLUTION_B6_MIN_ISOLATION_RESISTANCE_KEY,
            name: Common.EVSOLUTION_B6_MIN_ISOLATION_RESISTANCE_NAME,
            value: getBms6MinIsolationResistanceValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Bms fault
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [bmsFaultPanelOpen, setBmsFaultPanelOpen] = useState(false);
    const [bmsFaultChartMounted, setBmsFaultChartMounted] = useState(false);
    const [selectedBmsFault, setSelectedBmsFault] = useState<Array<React.Key>>([]);

    const [seriesChartDatasBmsFault, setSeriesChartDatasBmsFault] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartBmsFaultHwVer, setChartBmsFaultHwVer] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_BF_HW_VER_NAME,
        data: [],
    });

    const [chartBmsFaultSwVer, setChartBmsFaultSwVer] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_BF_SW_VER_NAME,
        data: [],
    });

    const [chartBmsFaultBootVer, setChartBmsFaultBootVer] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_BF_BOOT_VER_NAME,
        data: [],
    });

    const [chartBmsFaultIntFlt, setChartBmsFaultIntFlt] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_BF_INT_FLT_NAME,
        data: [],
    });

    const [chartBmsFaultCmiFltInit, setChartBmsFaultCmiFltInit] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_BF_CMI_FLT_INIT_NAME,
        data: [],
    });

    const [chartBmsFaultCmiFlt, setChartBmsFaultCmiFlt] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_BF_CMI_FLT_NAME,
        data: [],
    });

    const [chartBmsFaultFetFlt, setChartBmsFaultFetFlt] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_BF_FET_FLT_NAME,
        data: [],
    });

    const [chartBmsFaultRlyMnCs, setChartBmsFaultRlyMnCs] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_BF_RLY_MN_CS_NAME,
        data: [],
    });

    const [chartBmsFaultRlyMpCs, setChartBmsFaultRlyMpCs] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_BF_RLY_MP_CS_NAME,
        data: [],
    });

    const [chartBmsFaultItempSh, setChartBmsFaultItempSh] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_BF_I_TEMP_SH_NAME,
        data: [],
    });

    const [chartBmsFaultItempSl, setChartBmsFaultItempSl] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_BF_I_TEMP_SL_NAME,
        data: [],
    });

    const [chartBmsFaultIsoFlt, setChartBmsFaultIsoFlt] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_BF_ISO_FLT_NAME,
        data: [],
    });

    const [chartBmsFaultCrashOpn, setChartBmsFaultCrashOpn] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_BF_CRASH_OPN_NAME,
        data: [],
    });

    const [chartBmsFaultCrashHw, setChartBmsFaultCrashHw] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_BF_CRASH_HW_NAME,
        data: [],
    });

    const [chartBmsFaultCrashCan, setChartBmsFaultCrashCan] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_BF_CRASH_CAN_NAME,
        data: [],
    });

    const [chartBmsFaultVcuLcm, setChartBmsFaultVcuLcm] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_BF_VCU_LCM_NAME,
        data: [],
    });

    const [chartBmsFaultCrtSensWan, setChartBmsFaultCrtSensWan] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_BF_CRT_SENS_WAN_NAME,
        data: [],
    });

    const [chartBmsFaultCrtSensDev, setChartBmsFaultCrtSensDev] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_BF_CRT_SENS_DEV_NAME,
        data: [],
    });

    const [chartBmsFaultEcanBusOff, setChartBmsFaultEcanBusOff] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_BF_ECAN_BUS_OFF_NAME,
        data: [],
    });

    const [chartBmsFaultPcanBusOff, setChartBmsFaultPcanBusOff] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_BF_PCAN_BUS_OFF_NAME,
        data: [],
    });

    const [chartBmsFaultHeaterFlt, setChartBmsFaultHeaterFlt] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_BF_HEATER_FLT_NAME,
        data: [],
    });

    const [chartBmsFaultRlyMnCos, setChartBmsFaultRlyMnCos] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_BF_RLY_MN_COS_NAME,
        data: [],
    });

    const [chartBmsFaultRlyMpCos, setChartBmsFaultRlyMpCos] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_BF_RLY_MP_COS_NAME,
        data: [],
    });

    const [chartBmsFaultRlyPcCos, setChartBmsFaultRlyPcCos] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_BF_RLY_PC_COS_NAME,
        data: [],
    });

    const [chartBmsFaultRlyMnCcs, setChartBmsFaultRlyMnCcs] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_BF_RLY_MN_CCS_NAME,
        data: [],
    });

    const [chartBmsFaultRlyMpCcs, setChartBmsFaultRlyMpCcs] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_BF_RLY_MP_CCS_NAME,
        data: [],
    });

    const [chartBmsFaultRlyPcCcs, setChartBmsFaultRlyPcCcs] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_BF_RLY_PC_CCS_NAME,
        data: [],
    });

    const [chartBmsFaultCrashNr, setChartBmsFaultCrashNr] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_BF_CRASH_NR_NAME,
        data: [],
    });

    const [chartBmsFaultCtempFltAll, setChartBmsFaultCtempFltAll] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_BF_C_TEMP_FLT_ALL_NAME,
        data: [],
    });

    const [chartBmsFaultCellOpn, setChartBmsFaultCellOpn] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_BF_CELL_OPN_NAME,
        data: [],
    });

    const [chartBmsFaultCtempFlt, setChartBmsFaultCtempFlt] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_BF_C_TEMP_FLT_NAME,
        data: [],
    });

    const [chartBmsFaultCvHFlt, setChartBmsFaultCvHFlt] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_BF_CV_H_FLT_NAME,
        data: [],
    });

    const [chartBmsFaultCvLFlt, setChartBmsFaultCvLFlt] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_BF_CV_L_FLT_NAME,
        data: [],
    });

    const [chartBmsFaultCvHWan, setChartBmsFaultCvHWan] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_BF_CV_H_WAN_NAME,
        data: [],
    });

    const [chartBmsFaultCvLWan, setChartBmsFaultCvLWan] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_BF_CV_L_WAN_NAME,
        data: [],
    });

    const [chartBmsFaultCvDevOcvFlt, setChartBmsFaultCvDevOcvFlt] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_BF_CV_DEV_OCV_FLT_NAME,
        data: [],
    });

    const [chartBmsFaultCvDevFlt, setChartBmsFaultCvDevFlt] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_BF_CV_DEV_FLT_NAME,
        data: [],
    });

    const [chartBmsFaultPvHFlt, setChartBmsFaultPvHFlt] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_BF_PV_H_FLT_NAME,
        data: [],
    });

    const [chartBmsFaultPvLFlt, setChartBmsFaultPvLFlt] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_BF_PV_L_FLT_NAME,
        data: [],
    });

    const [chartBmsFaultPvHWan, setChartBmsFaultPvHWan] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_BF_PV_H_WAN_NAME,
        data: [],
    });

    const [chartBmsFaultPvLWan, setChartBmsFaultPvLWan] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_BF_PV_L_WAN_NAME,
        data: [],
    });

    const [chartBmsFaultCtHFlt, setChartBmsFaultCtHFlt] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_BF_CT_H_FLT_NAME,
        data: [],
    });

    const [chartBmsFaultCtHWan, setChartBmsFaultCtHWan] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_BF_CT_H_WAN_NAME,
        data: [],
    });

    const getBmsFaultHwVerValue = () => {
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_HW_VER_KEY) && currentCanData !== undefined) {
            return currentCanData.bmsFault?.hwVer;
        }
        return "-";
    };

    const getBmsFaultSwVerValue = () => {
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_SW_VER_KEY) && currentCanData !== undefined) {
            return currentCanData.bmsFault?.swVer;
        }
        return "-";
    };

    const getBmsFaultBootVerValue = () => {
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_BOOT_VER_KEY) && currentCanData !== undefined) {
            return currentCanData.bmsFault?.bootVer;
        }
        return "-";
    };

    const getBmsFaultIntFltValue = () => {
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_INT_FLT_KEY) && currentCanData !== undefined) {
            return convertRemarkError(currentCanData.bmsFault?.intFlt);
        }
        return "-";
    };

    const getBmsFaultCmiFltInitValue = () => {
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_CMI_FLT_INIT_KEY) && currentCanData !== undefined) {
            return convertRemarkError(currentCanData.bmsFault?.cmiFltInit);
        }
        return "-";
    };

    const getBmsFaultCmiFltValue = () => {
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_CMI_FLT_KEY) && currentCanData !== undefined) {
            return convertRemarkError(currentCanData.bmsFault?.cmiFlt);
        }
        return "-";
    };

    const getBmsFaultFetFltValue = () => {
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_FET_FLT_KEY) && currentCanData !== undefined) {
            return convertRemarkError(currentCanData.bmsFault?.fetFlt);
        }
        return "-";
    };

    const getBmsFaultRlyMnCsValue = () => {
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_RLY_MN_CS_KEY) && currentCanData !== undefined) {
            return convertRemarkError(currentCanData.bmsFault?.rlyMnCs);
        }
        return "-";
    };

    const getBmsFaultRlyMpCsValue = () => {
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_RLY_MP_CS_KEY) && currentCanData !== undefined) {
            return convertRemarkError(currentCanData.bmsFault?.rlyMpCs);
        }
        return "-";
    };

    const getBmsFaultItempShValue = () => {
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_I_TEMP_SH_KEY) && currentCanData !== undefined) {
            return convertRemarkError(currentCanData.bmsFault?.itempSh);
        }
        return "-";
    };

    const getBmsFaultItempSlValue = () => {
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_I_TEMP_SL_KEY) && currentCanData !== undefined) {
            return convertRemarkError(currentCanData.bmsFault?.itempSl);
        }
        return "-";
    };

    const getBmsFaultIsoFltValue = () => {
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_ISO_FLT_KEY) && currentCanData !== undefined) {
            return convertRemarkError(currentCanData.bmsFault?.isoFlt);
        }
        return "-";
    };

    const getBmsFaultCrashOpnValue = () => {
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_CRASH_OPN_KEY) && currentCanData !== undefined) {
            return convertRemarkError(currentCanData.bmsFault?.crashOpn);
        }
        return "-";
    };

    const getBmsFaultCrashHwValue = () => {
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_CRASH_HW_KEY) && currentCanData !== undefined) {
            return convertRemarkError(currentCanData.bmsFault?.crashHw);
        }
        return "-";
    };

    const getBmsFaultCrashCanValue = () => {
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_CRASH_CAN_KEY) && currentCanData !== undefined) {
            return convertRemarkError(currentCanData.bmsFault?.crashCan);
        }
        return "-";
    };

    const getBmsFaultVcuLcmValue = () => {
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_VCU_LCM_KEY) && currentCanData !== undefined) {
            return convertRemarkError(currentCanData.bmsFault?.vcuLcm);
        }
        return "-";
    };

    const getBmsFaultCrtSensWanValue = () => {
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_CRT_SENS_WAN_KEY) && currentCanData !== undefined) {
            return convertRemarkError(currentCanData.bmsFault?.crtSensWan);
        }
        return "-";
    };

    const getBmsFaultCrtSensDevValue = () => {
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_CRT_SENS_DEV_KEY) && currentCanData !== undefined) {
            return convertRemarkError(currentCanData.bmsFault?.crtSensDev);
        }
        return "-";
    };

    const getBmsFaultEcanBusOffValue = () => {
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_ECAN_BUS_OFF_KEY) && currentCanData !== undefined) {
            return convertRemarkError(currentCanData.bmsFault?.ecanBusOff);
        }
        return "-";
    };

    const getBmsFaultPcanBusOffValue = () => {
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_PCAN_BUS_OFF_KEY) && currentCanData !== undefined) {
            return convertRemarkError(currentCanData.bmsFault?.pcanBusOff);
        }
        return "-";
    };

    const getBmsFaultHeaterFltValue = () => {
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_HEATER_FLT_KEY) && currentCanData !== undefined) {
            return convertRemarkError(currentCanData.bmsFault?.heaterFlt);
        }
        return "-";
    };

    const getBmsFaultRlyMnCosValue = () => {
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_RLY_MN_COS_KEY) && currentCanData !== undefined) {
            return convertRemarkError(currentCanData.bmsFault?.rlyMnCos);
        }
        return "-";
    };

    const getBmsFaultRlyMpCosValue = () => {
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_RLY_MP_COS_KEY) && currentCanData !== undefined) {
            return convertRemarkError(currentCanData.bmsFault?.rlyMpCos);
        }
        return "-";
    };

    const getBmsFaultRlyPcCosValue = () => {
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_RLY_PC_COS_KEY) && currentCanData !== undefined) {
            return convertRemarkError(currentCanData.bmsFault?.rlyPcCos);
        }
        return "-";
    };

    const getBmsFaultRlyMnCcsValue = () => {
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_RLY_MN_CCS_KEY) && currentCanData !== undefined) {
            return convertRemarkError(currentCanData.bmsFault?.rlyMnCcs);
        }
        return "-";
    };

    const getBmsFaultRlyMpCcsValue = () => {
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_RLY_MP_CCS_KEY) && currentCanData !== undefined) {
            return convertRemarkError(currentCanData.bmsFault?.rlyMpCcs);
        }
        return "-";
    };

    const getBmsFaultRlyPcCcsValue = () => {
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_RLY_PC_CCS_KEY) && currentCanData !== undefined) {
            return convertRemarkError(currentCanData.bmsFault?.rlyPcCcs);
        }
        return "-";
    };

    const getBmsFaultCrashNrValue = () => {
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_CRASH_NR_KEY) && currentCanData !== undefined) {
            return convertRemarkError(currentCanData.bmsFault?.crashNr);
        }
        return "-";
    };

    const getBmsFaultCtempFltAllValue = () => {
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_C_TEMP_FLT_ALL_KEY) && currentCanData !== undefined) {
            return convertRemarkError(currentCanData.bmsFault?.ctempFltAll);
        }
        return "-";
    };

    const getBmsFaultCellOpnValue = () => {
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_CELL_OPN_KEY) && currentCanData !== undefined) {
            return convertRemarkError(currentCanData.bmsFault?.cellOpn);
        }
        return "-";
    };

    const getBmsFaultCtempFltValue = () => {
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_C_TEMP_FLT_KEY) && currentCanData !== undefined) {
            return convertRemarkError(currentCanData.bmsFault?.ctempFlt);
        }
        return "-";
    };

    const getBmsFaultCvHFltValue = () => {
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_CV_H_FLT_KEY) && currentCanData !== undefined) {
            return convertRemarkError(currentCanData.bmsFault?.cvHFlt);
        }
        return "-";
    };

    const getBmsFaultCvLFlValue = () => {
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_CV_L_FLT_KEY) && currentCanData !== undefined) {
            return convertRemarkError(currentCanData.bmsFault?.cvLFlt);
        }
        return "-";
    };

    const getBmsFaultCvHWanValue = () => {
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_CV_H_WAN_KEY) && currentCanData !== undefined) {
            return convertRemarkError(currentCanData.bmsFault?.cvHWan);
        }
        return "-";
    };

    const getBmsFaultCvLWanValue = () => {
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_CV_L_WAN_KEY) && currentCanData !== undefined) {
            return convertRemarkError(currentCanData.bmsFault?.cvLWan);
        }
        return "-";
    };

    const getBmsFaultCvDevOcvFltValue = () => {
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_CV_DEV_OCV_FLT_KEY) && currentCanData !== undefined) {
            return convertRemarkError(currentCanData.bmsFault?.cvDevOcvFlt);
        }
        return "-";
    };

    const getBmsFaultCvDevFltValue = () => {
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_CV_DEV_FLT_KEY) && currentCanData !== undefined) {
            return convertRemarkError(currentCanData.bmsFault?.cvDevFlt);
        }
        return "-";
    };

    const getBmsFaultPvHFltValue = () => {
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_PV_H_FLT_KEY) && currentCanData !== undefined) {
            return convertRemarkError(currentCanData.bmsFault?.pvHFlt);
        }
        return "-";
    };

    const getBmsFaultPvLFltValue = () => {
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_PV_L_FLT_KEY) && currentCanData !== undefined) {
            return convertRemarkError(currentCanData.bmsFault?.pvLFlt);
        }
        return "-";
    };

    const getBmsFaultPvHWanValue = () => {
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_PV_H_WAN_KEY) && currentCanData !== undefined) {
            return convertRemarkError(currentCanData.bmsFault?.pvHWan);
        }
        return "-";
    };

    const getBmsFaultPvLWanValue = () => {
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_PV_L_WAN_KEY) && currentCanData !== undefined) {
            return convertRemarkError(currentCanData.bmsFault?.pvLWan);
        }
        return "-";
    };

    const getBmsFaultCtHFltValue = () => {
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_CT_H_FLT_KEY) && currentCanData !== undefined) {
            return convertRemarkError(currentCanData.bmsFault?.ctHFlt);
        }
        return "-";
    };

    const getBmsFaultCtHWanValue = () => {
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_CT_H_WAN_KEY) && currentCanData !== undefined) {
            return convertRemarkError(currentCanData.bmsFault?.ctHWan);
        }
        return "-";
    };

    const onChangeBmsFault = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedBmsFault(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasBmsFault: Array<TypeUtils.ChartData> = [];

        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_HW_VER_KEY)) chartDatasBmsFault.push(chartBmsFaultHwVer);
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_SW_VER_KEY)) chartDatasBmsFault.push(chartBmsFaultSwVer);
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_BOOT_VER_KEY)) chartDatasBmsFault.push(chartBmsFaultBootVer);
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_INT_FLT_KEY)) chartDatasBmsFault.push(chartBmsFaultIntFlt);
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_CMI_FLT_INIT_KEY)) chartDatasBmsFault.push(chartBmsFaultCmiFltInit);
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_CMI_FLT_KEY)) chartDatasBmsFault.push(chartBmsFaultCmiFlt);
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_FET_FLT_KEY)) chartDatasBmsFault.push(chartBmsFaultFetFlt);
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_RLY_MN_CS_KEY)) chartDatasBmsFault.push(chartBmsFaultRlyMnCs);
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_RLY_MP_CS_KEY)) chartDatasBmsFault.push(chartBmsFaultRlyMpCs);
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_I_TEMP_SH_KEY)) chartDatasBmsFault.push(chartBmsFaultItempSh);
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_I_TEMP_SL_KEY)) chartDatasBmsFault.push(chartBmsFaultItempSl);
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_ISO_FLT_KEY)) chartDatasBmsFault.push(chartBmsFaultIsoFlt);
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_CRASH_OPN_KEY)) chartDatasBmsFault.push(chartBmsFaultCrashOpn);
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_CRASH_HW_KEY)) chartDatasBmsFault.push(chartBmsFaultCrashHw);
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_CRASH_CAN_KEY)) chartDatasBmsFault.push(chartBmsFaultCrashCan);
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_VCU_LCM_KEY)) chartDatasBmsFault.push(chartBmsFaultVcuLcm);
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_CRT_SENS_WAN_KEY)) chartDatasBmsFault.push(chartBmsFaultCrtSensWan);
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_CRT_SENS_DEV_KEY)) chartDatasBmsFault.push(chartBmsFaultCrtSensDev);
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_ECAN_BUS_OFF_KEY)) chartDatasBmsFault.push(chartBmsFaultEcanBusOff);
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_PCAN_BUS_OFF_KEY)) chartDatasBmsFault.push(chartBmsFaultPcanBusOff);
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_HEATER_FLT_KEY)) chartDatasBmsFault.push(chartBmsFaultHeaterFlt);
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_RLY_MN_COS_KEY)) chartDatasBmsFault.push(chartBmsFaultRlyMnCos);
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_RLY_MP_COS_KEY)) chartDatasBmsFault.push(chartBmsFaultRlyMpCos);
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_RLY_PC_COS_KEY)) chartDatasBmsFault.push(chartBmsFaultRlyPcCos);
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_RLY_MN_CCS_KEY)) chartDatasBmsFault.push(chartBmsFaultRlyMnCcs);
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_RLY_MP_CCS_KEY)) chartDatasBmsFault.push(chartBmsFaultRlyMpCcs);
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_RLY_PC_CCS_KEY)) chartDatasBmsFault.push(chartBmsFaultRlyPcCcs);
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_CRASH_NR_KEY)) chartDatasBmsFault.push(chartBmsFaultCrashNr);
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_C_TEMP_FLT_ALL_KEY)) chartDatasBmsFault.push(chartBmsFaultCtempFltAll);
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_CELL_OPN_KEY)) chartDatasBmsFault.push(chartBmsFaultCellOpn);
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_C_TEMP_FLT_KEY)) chartDatasBmsFault.push(chartBmsFaultCtempFlt);
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_CV_H_FLT_KEY)) chartDatasBmsFault.push(chartBmsFaultCvHFlt);
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_CV_L_FLT_KEY)) chartDatasBmsFault.push(chartBmsFaultCvLFlt);
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_CV_H_WAN_KEY)) chartDatasBmsFault.push(chartBmsFaultCvHWan);
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_CV_L_WAN_KEY)) chartDatasBmsFault.push(chartBmsFaultCvLWan);
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_CV_DEV_OCV_FLT_KEY)) chartDatasBmsFault.push(chartBmsFaultCvDevOcvFlt);
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_CV_DEV_FLT_KEY)) chartDatasBmsFault.push(chartBmsFaultCvDevFlt);
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_PV_H_FLT_KEY)) chartDatasBmsFault.push(chartBmsFaultPvHFlt);
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_PV_L_FLT_KEY)) chartDatasBmsFault.push(chartBmsFaultPvLFlt);
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_PV_H_WAN_KEY)) chartDatasBmsFault.push(chartBmsFaultPvHWan);
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_PV_L_WAN_KEY)) chartDatasBmsFault.push(chartBmsFaultPvLWan);
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_CT_H_FLT_KEY)) chartDatasBmsFault.push(chartBmsFaultCtHFlt);
        if (selectedBmsFault.includes(Common.EVSOLUTION_BF_CT_H_WAN_KEY)) chartDatasBmsFault.push(chartBmsFaultCtHWan);

        setSeriesChartDatasBmsFault(chartDatasBmsFault);
    }, [
        selectedBmsFault,
        chartBmsFaultHwVer,
        chartBmsFaultSwVer,
        chartBmsFaultBootVer,
        chartBmsFaultIntFlt,
        chartBmsFaultCmiFltInit,
        chartBmsFaultCmiFlt,
        chartBmsFaultFetFlt,
        chartBmsFaultRlyMnCs,
        chartBmsFaultRlyMpCs,
        chartBmsFaultItempSh,
        chartBmsFaultItempSl,
        chartBmsFaultIsoFlt,
        chartBmsFaultCrashOpn,
        chartBmsFaultCrashHw,
        chartBmsFaultCrashCan,
        chartBmsFaultVcuLcm,
        chartBmsFaultCrtSensWan,
        chartBmsFaultCrtSensDev,
        chartBmsFaultEcanBusOff,
        chartBmsFaultPcanBusOff,
        chartBmsFaultHeaterFlt,
        chartBmsFaultRlyMnCos,
        chartBmsFaultRlyMpCos,
        chartBmsFaultRlyPcCos,
        chartBmsFaultRlyMnCcs,
        chartBmsFaultRlyMpCcs,
        chartBmsFaultRlyPcCcs,
        chartBmsFaultCrashNr,
        chartBmsFaultCtempFltAll,
        chartBmsFaultCellOpn,
        chartBmsFaultCtempFlt,
        chartBmsFaultCvHFlt,
        chartBmsFaultCvLFlt,
        chartBmsFaultCvHWan,
        chartBmsFaultCvLWan,
        chartBmsFaultCvDevOcvFlt,
        chartBmsFaultCvDevFlt,
        chartBmsFaultPvHFlt,
        chartBmsFaultPvLFlt,
        chartBmsFaultPvHWan,
        chartBmsFaultPvLWan,
        chartBmsFaultCtHFlt,
        chartBmsFaultCtHWan,
    ]);

    const tableDataBmsFault: CanTableType[] = [
        {
            key: Common.EVSOLUTION_BF_HW_VER_KEY,
            name: Common.EVSOLUTION_BF_HW_VER_NAME,
            value: getBmsFaultHwVerValue(),
        },
        {
            key: Common.EVSOLUTION_BF_SW_VER_KEY,
            name: Common.EVSOLUTION_BF_SW_VER_NAME,
            value: getBmsFaultSwVerValue(),
        },
        {
            key: Common.EVSOLUTION_BF_BOOT_VER_KEY,
            name: Common.EVSOLUTION_BF_BOOT_VER_NAME,
            value: getBmsFaultBootVerValue(),
        },
        {
            key: Common.EVSOLUTION_BF_INT_FLT_KEY,
            name: Common.EVSOLUTION_BF_INT_FLT_NAME,
            value: getBmsFaultIntFltValue(),
        },
        {
            key: Common.EVSOLUTION_BF_CMI_FLT_INIT_KEY,
            name: Common.EVSOLUTION_BF_CMI_FLT_INIT_NAME,
            value: getBmsFaultCmiFltInitValue(),
        },

        {
            key: Common.EVSOLUTION_BF_CMI_FLT_KEY,
            name: Common.EVSOLUTION_BF_CMI_FLT_NAME,
            value: getBmsFaultCmiFltValue(),
        },
        {
            key: Common.EVSOLUTION_BF_FET_FLT_KEY,
            name: Common.EVSOLUTION_BF_FET_FLT_NAME,
            value: getBmsFaultFetFltValue(),
        },
        {
            key: Common.EVSOLUTION_BF_RLY_MN_CS_KEY,
            name: Common.EVSOLUTION_BF_RLY_MN_CS_NAME,
            value: getBmsFaultRlyMnCsValue(),
        },
        {
            key: Common.EVSOLUTION_BF_RLY_MP_CS_KEY,
            name: Common.EVSOLUTION_BF_RLY_MP_CS_NAME,
            value: getBmsFaultRlyMpCsValue(),
        },
        {
            key: Common.EVSOLUTION_BF_I_TEMP_SH_KEY,
            name: Common.EVSOLUTION_BF_I_TEMP_SH_NAME,
            value: getBmsFaultItempShValue(),
        },

        {
            key: Common.EVSOLUTION_BF_I_TEMP_SL_KEY,
            name: Common.EVSOLUTION_BF_I_TEMP_SL_NAME,
            value: getBmsFaultItempSlValue(),
        },
        {
            key: Common.EVSOLUTION_BF_ISO_FLT_KEY,
            name: Common.EVSOLUTION_BF_ISO_FLT_NAME,
            value: getBmsFaultIsoFltValue(),
        },
        {
            key: Common.EVSOLUTION_BF_CRASH_OPN_KEY,
            name: Common.EVSOLUTION_BF_CRASH_OPN_NAME,
            value: getBmsFaultCrashOpnValue(),
        },
        {
            key: Common.EVSOLUTION_BF_CRASH_HW_KEY,
            name: Common.EVSOLUTION_BF_CRASH_HW_NAME,
            value: getBmsFaultCrashHwValue(),
        },
        {
            key: Common.EVSOLUTION_BF_CRASH_CAN_KEY,
            name: Common.EVSOLUTION_BF_CRASH_CAN_NAME,
            value: getBmsFaultCrashCanValue(),
        },
        {
            key: Common.EVSOLUTION_BF_VCU_LCM_KEY,
            name: Common.EVSOLUTION_BF_VCU_LCM_NAME,
            value: getBmsFaultVcuLcmValue(),
        },
        {
            key: Common.EVSOLUTION_BF_CRT_SENS_WAN_KEY,
            name: Common.EVSOLUTION_BF_CRT_SENS_WAN_NAME,
            value: getBmsFaultCrtSensWanValue(),
        },
        {
            key: Common.EVSOLUTION_BF_CRT_SENS_DEV_KEY,
            name: Common.EVSOLUTION_BF_CRT_SENS_DEV_NAME,
            value: getBmsFaultCrtSensDevValue(),
        },
        {
            key: Common.EVSOLUTION_BF_ECAN_BUS_OFF_KEY,
            name: Common.EVSOLUTION_BF_ECAN_BUS_OFF_NAME,
            value: getBmsFaultEcanBusOffValue(),
        },
        {
            key: Common.EVSOLUTION_BF_PCAN_BUS_OFF_KEY,
            name: Common.EVSOLUTION_BF_PCAN_BUS_OFF_NAME,
            value: getBmsFaultPcanBusOffValue(),
        },

        {
            key: Common.EVSOLUTION_BF_HEATER_FLT_KEY,
            name: Common.EVSOLUTION_BF_HEATER_FLT_NAME,
            value: getBmsFaultHeaterFltValue(),
        },
        {
            key: Common.EVSOLUTION_BF_RLY_MN_COS_KEY,
            name: Common.EVSOLUTION_BF_RLY_MN_COS_NAME,
            value: getBmsFaultRlyMnCosValue(),
        },
        {
            key: Common.EVSOLUTION_BF_RLY_MP_COS_KEY,
            name: Common.EVSOLUTION_BF_RLY_MP_COS_NAME,
            value: getBmsFaultRlyMpCosValue(),
        },
        {
            key: Common.EVSOLUTION_BF_RLY_PC_COS_KEY,
            name: Common.EVSOLUTION_BF_RLY_PC_COS_NAME,
            value: getBmsFaultRlyPcCosValue(),
        },
        {
            key: Common.EVSOLUTION_BF_RLY_MN_CCS_KEY,
            name: Common.EVSOLUTION_BF_RLY_MN_CCS_NAME,
            value: getBmsFaultRlyMnCcsValue(),
        },

        {
            key: Common.EVSOLUTION_BF_RLY_MP_CCS_KEY,
            name: Common.EVSOLUTION_BF_RLY_MP_CCS_NAME,
            value: getBmsFaultRlyMpCcsValue(),
        },
        {
            key: Common.EVSOLUTION_BF_RLY_PC_CCS_KEY,
            name: Common.EVSOLUTION_BF_RLY_PC_CCS_NAME,
            value: getBmsFaultRlyPcCcsValue(),
        },
        {
            key: Common.EVSOLUTION_BF_CRASH_NR_KEY,
            name: Common.EVSOLUTION_BF_CRASH_NR_NAME,
            value: getBmsFaultCrashNrValue(),
        },
        {
            key: Common.EVSOLUTION_BF_C_TEMP_FLT_ALL_KEY,
            name: Common.EVSOLUTION_BF_C_TEMP_FLT_ALL_NAME,
            value: getBmsFaultCtempFltAllValue(),
        },
        {
            key: Common.EVSOLUTION_BF_CELL_OPN_KEY,
            name: Common.EVSOLUTION_BF_CELL_OPN_NAME,
            value: getBmsFaultCellOpnValue(),
        },
        {
            key: Common.EVSOLUTION_BF_C_TEMP_FLT_KEY,
            name: Common.EVSOLUTION_BF_C_TEMP_FLT_NAME,
            value: getBmsFaultCtempFltValue(),
        },
        {
            key: Common.EVSOLUTION_BF_CV_H_FLT_KEY,
            name: Common.EVSOLUTION_BF_CV_H_FLT_NAME,
            value: getBmsFaultCvHFltValue(),
        },
        {
            key: Common.EVSOLUTION_BF_CV_L_FLT_KEY,
            name: Common.EVSOLUTION_BF_CV_L_FLT_NAME,
            value: getBmsFaultCvLFlValue(),
        },

        {
            key: Common.EVSOLUTION_BF_CV_H_WAN_KEY,
            name: Common.EVSOLUTION_BF_CV_H_WAN_NAME,
            value: getBmsFaultCvHWanValue(),
        },
        {
            key: Common.EVSOLUTION_BF_CV_L_WAN_KEY,
            name: Common.EVSOLUTION_BF_CV_L_WAN_NAME,
            value: getBmsFaultCvLWanValue(),
        },
        {
            key: Common.EVSOLUTION_BF_CV_DEV_OCV_FLT_KEY,
            name: Common.EVSOLUTION_BF_CV_DEV_OCV_FLT_NAME,
            value: getBmsFaultCvDevOcvFltValue(),
        },
        {
            key: Common.EVSOLUTION_BF_CV_DEV_FLT_KEY,
            name: Common.EVSOLUTION_BF_CV_DEV_FLT_NAME,
            value: getBmsFaultCvDevFltValue(),
        },
        {
            key: Common.EVSOLUTION_BF_PV_H_FLT_KEY,
            name: Common.EVSOLUTION_BF_PV_H_FLT_NAME,
            value: getBmsFaultPvHFltValue(),
        },

        {
            key: Common.EVSOLUTION_BF_PV_L_FLT_KEY,
            name: Common.EVSOLUTION_BF_PV_L_FLT_NAME,
            value: getBmsFaultPvLFltValue(),
        },
        {
            key: Common.EVSOLUTION_BF_PV_H_WAN_KEY,
            name: Common.EVSOLUTION_BF_PV_H_WAN_NAME,
            value: getBmsFaultPvHWanValue(),
        },
        {
            key: Common.EVSOLUTION_BF_PV_L_WAN_KEY,
            name: Common.EVSOLUTION_BF_PV_L_WAN_NAME,
            value: getBmsFaultPvLWanValue(),
        },
        {
            key: Common.EVSOLUTION_BF_CT_H_FLT_KEY,
            name: Common.EVSOLUTION_BF_CT_H_FLT_NAME,
            value: getBmsFaultCtHFltValue(),
        },
        {
            key: Common.EVSOLUTION_BF_CT_H_WAN_KEY,
            name: Common.EVSOLUTION_BF_CT_H_WAN_NAME,
            value: getBmsFaultCtHWanValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Mcu1
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [mcu1PanelOpen, setMcu1PanelOpen] = useState(false);
    const [mcu1ChartMounted, setMcu1ChartMounted] = useState(false);
    const [selectedMcu1, setSelectedMcu1] = useState<Array<React.Key>>([]);

    const [seriesChartDatasMcu1, setSeriesChartDatasMcu1] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartMcu1MotorTorqueEstimatedRs1, setChartMcu1MotorTorqueEstimatedRs1] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_M1_MOTOR_TORQUE_ESTIMATED_RS1_NAME,
        data: [],
    });

    const [chartMcu1MotorTorqueEstimated, setChartMcu1MotorTorqueEstimated] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_M1_MOTOR_TORQUE_ESTIMATED_NAME,
        data: [],
    });

    const [chartMcu1MotorSpeed, setChartMcu1MotorSpeed] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_M1_MOTOR_SPEED_NAME,
        data: [],
    });

    const [chartMcu1Counter, setChartMcu1Counter] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_M1_COUNTER_NAME,
        data: [],
    });

    const [chartMcu1Checksum, setChartMcu1Checksum] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_M1_CHECKSUM_NAME,
        data: [],
    });

    const getMcu1MotorTorqueEstimatedRs1Value = () => {
        if (selectedMcu1.includes(Common.EVSOLUTION_M1_MOTOR_TORQUE_ESTIMATED_RS1_KEY) && currentCanData !== undefined) {
            return currentCanData.mcu1?.motorTorqueEstimatedRs1;
        }
        return "-";
    };

    const getMcu1MotorTorqueEstimatedValue = () => {
        if (selectedMcu1.includes(Common.EVSOLUTION_M1_MOTOR_TORQUE_ESTIMATED_KEY) && currentCanData !== undefined) {
            return currentCanData.mcu1?.motorTorqueEstimated;
        }
        return "-";
    };

    const getMcu1ChargePowerAvailable10Value = () => {
        if (selectedMcu1.includes(Common.EVSOLUTION_M1_MOTOR_SPEED_KEY) && currentCanData !== undefined) {
            return currentCanData.mcu1?.motorSpeed;
        }
        return "-";
    };

    const getMcu1CounterValue = () => {
        if (selectedMcu1.includes(Common.EVSOLUTION_M1_COUNTER_KEY) && currentCanData !== undefined) {
            return currentCanData.mcu1?.counter;
        }
        return "-";
    };

    const getMcu1ChecksumValue = () => {
        if (selectedMcu1.includes(Common.EVSOLUTION_M1_CHECKSUM_KEY) && currentCanData !== undefined) {
            return currentCanData.mcu1?.checksum;
        }
        return "-";
    };

    const onChangeMcu1 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedMcu1(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasMcu1: Array<TypeUtils.ChartData> = [];

        if (selectedMcu1.includes(Common.EVSOLUTION_M1_MOTOR_TORQUE_ESTIMATED_RS1_KEY)) chartDatasMcu1.push(chartMcu1MotorTorqueEstimatedRs1);
        if (selectedMcu1.includes(Common.EVSOLUTION_M1_MOTOR_TORQUE_ESTIMATED_KEY)) chartDatasMcu1.push(chartMcu1MotorTorqueEstimated);
        if (selectedMcu1.includes(Common.EVSOLUTION_M1_MOTOR_SPEED_KEY)) chartDatasMcu1.push(chartMcu1MotorSpeed);
        if (selectedMcu1.includes(Common.EVSOLUTION_M1_COUNTER_KEY)) chartDatasMcu1.push(chartMcu1Counter);
        if (selectedMcu1.includes(Common.EVSOLUTION_M1_CHECKSUM_KEY)) chartDatasMcu1.push(chartMcu1Checksum);

        setSeriesChartDatasMcu1(chartDatasMcu1);
    }, [selectedMcu1, chartMcu1MotorTorqueEstimatedRs1, chartMcu1MotorTorqueEstimated, chartMcu1MotorSpeed, chartMcu1Counter, chartMcu1Checksum]);

    const tableDataMcu1: CanTableType[] = [
        {
            key: Common.EVSOLUTION_M1_MOTOR_TORQUE_ESTIMATED_RS1_KEY,
            name: Common.EVSOLUTION_M1_MOTOR_TORQUE_ESTIMATED_RS1_NAME,
            value: getMcu1MotorTorqueEstimatedRs1Value(),
        },
        {
            key: Common.EVSOLUTION_M1_MOTOR_TORQUE_ESTIMATED_KEY,
            name: Common.EVSOLUTION_M1_MOTOR_TORQUE_ESTIMATED_NAME,
            value: getMcu1MotorTorqueEstimatedValue(),
        },
        {
            key: Common.EVSOLUTION_M1_MOTOR_SPEED_KEY,
            name: Common.EVSOLUTION_M1_MOTOR_SPEED_NAME,
            value: getMcu1ChargePowerAvailable10Value(),
        },
        {
            key: Common.EVSOLUTION_M1_COUNTER_KEY,
            name: Common.EVSOLUTION_M1_COUNTER_NAME,
            value: getMcu1CounterValue(),
        },
        {
            key: Common.EVSOLUTION_M1_CHECKSUM_KEY,
            name: Common.EVSOLUTION_M1_CHECKSUM_NAME,
            value: getMcu1ChecksumValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Vcu1
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [vcu1PanelOpen, setVcu1PanelOpen] = useState(false);
    const [vcu1ChartMounted, setVcu1ChartMounted] = useState(false);
    const [selectedVcu1, setSelectedVcu1] = useState<Array<React.Key>>([]);

    const [seriesChartDatasVcu1, setSeriesChartDatasVcu1] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartVcu1MainContactorControl, setChartVcu1MainContactorControl] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_V1_MAIN_CONTACTOR_CONTROL_NAME,
        data: [],
    });

    const [chartVcu1IsoltMtrStpReq, setChartVcu1IsoltMtrStpReq] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_V1_ISOLT_MTR_STP_REQ_NAME,
        data: [],
    });

    const [chartVcu1Counter, setChartVcu1Counter] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_V1_COUNTER_NAME,
        data: [],
    });

    const [chartVcu1Checksum, setChartVcu1Checksum] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_V1_CHECKSUM_NAME,
        data: [],
    });

    const getVcu1MainContactorControlValue = () => {
        if (selectedVcu1.includes(Common.EVSOLUTION_V1_MAIN_CONTACTOR_CONTROL_KEY) && currentCanData !== undefined) {
            return convertRemarkContactorCoilState(currentCanData.vcu1?.mainContactorControl);
        }
        return "-";
    };

    const getVcu1IsoltMtrStpReqValue = () => {
        if (selectedVcu1.includes(Common.EVSOLUTION_V1_ISOLT_MTR_STP_REQ_KEY) && currentCanData !== undefined) {
            return convertRemarkMonitoring(currentCanData.vcu1?.isoltMtrStpReq);
        }
        return "-";
    };

    const getVcu1CounterValue = () => {
        if (selectedVcu1.includes(Common.EVSOLUTION_V1_COUNTER_KEY) && currentCanData !== undefined) {
            return currentCanData.vcu1?.counter;
        }
        return "-";
    };

    const getVcu1ChecksumValue = () => {
        if (selectedVcu1.includes(Common.EVSOLUTION_V1_CHECKSUM_KEY) && currentCanData !== undefined) {
            return currentCanData.vcu1?.checksum;
        }
        return "-";
    };

    const onChangeVcu1 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedVcu1(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasVcu1: Array<TypeUtils.ChartData> = [];

        if (selectedVcu1.includes(Common.EVSOLUTION_V1_MAIN_CONTACTOR_CONTROL_KEY)) chartDatasVcu1.push(chartVcu1MainContactorControl);
        if (selectedVcu1.includes(Common.EVSOLUTION_V1_ISOLT_MTR_STP_REQ_KEY)) chartDatasVcu1.push(chartVcu1IsoltMtrStpReq);
        if (selectedVcu1.includes(Common.EVSOLUTION_V1_COUNTER_KEY)) chartDatasVcu1.push(chartVcu1Counter);
        if (selectedVcu1.includes(Common.EVSOLUTION_V1_CHECKSUM_KEY)) chartDatasVcu1.push(chartVcu1Checksum);

        setSeriesChartDatasVcu1(chartDatasVcu1);
    }, [selectedVcu1, chartVcu1MainContactorControl, chartVcu1IsoltMtrStpReq, chartVcu1Counter, chartVcu1Checksum]);

    const tableDataVcu1: CanTableType[] = [
        {
            key: Common.EVSOLUTION_V1_MAIN_CONTACTOR_CONTROL_KEY,
            name: Common.EVSOLUTION_V1_MAIN_CONTACTOR_CONTROL_NAME,
            value: getVcu1MainContactorControlValue(),
        },
        {
            key: Common.EVSOLUTION_V1_ISOLT_MTR_STP_REQ_KEY,
            name: Common.EVSOLUTION_V1_ISOLT_MTR_STP_REQ_NAME,
            value: getVcu1IsoltMtrStpReqValue(),
        },
        {
            key: Common.EVSOLUTION_V1_COUNTER_KEY,
            name: Common.EVSOLUTION_V1_COUNTER_NAME,
            value: getVcu1CounterValue(),
        },
        {
            key: Common.EVSOLUTION_V1_CHECKSUM_KEY,
            name: Common.EVSOLUTION_V1_CHECKSUM_NAME,
            value: getVcu1ChecksumValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Vcu2
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [vcu2PanelOpen, setVcu2PanelOpen] = useState(false);
    const [vcu2ChartMounted, setVcu2ChartMounted] = useState(false);
    const [selectedVcu2, setSelectedVcu2] = useState<Array<React.Key>>([]);

    const [seriesChartDatasVcu2, setSeriesChartDatasVcu2] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartVcu2EstimatedEptCoolantFlow, setChartVcu2EstimatedEptCoolantFlow] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_V2_ESTIMATED_EPT_COOLANT_FLOW_NAME,
        data: [],
    });

    const [chartVcu2EptCoolantTemp, setChartVcu2EptCoolantTemp] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_V2_EPT_COOLANT_TEMP_NAME,
        data: [],
    });

    const [chartVcu2CommandMode, setChartVcu2CommandMode] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_V2_COMMAND_MODE_NAME,
        data: [],
    });

    const [chartVcu2ActvDischargeReq, setChartVcu2ActvDischargeReq] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_V2_ACTV_DISCHARGE_REQ_NAME,
        data: [],
    });

    const [chartVcu2OperationModeCmd, setChartVcu2OperationModeCmd] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_V2_OPERATION_MODE_CMD_NAME,
        data: [],
    });

    const [chartVcu2TorqueCommand, setChartVcu2TorqueCommand] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_V2_TORQUE_COMMAND_NAME,
        data: [],
    });

    const [chartVcu2Counter, setChartVcu2Counter] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_V2_COUNTER_NAME,
        data: [],
    });

    const [chartVcu2Checksum, setChartVcu2Checksum] = useState<TypeUtils.ChartData>({
        name: Common.EVSOLUTION_V2_CHECKSUM_NAME,
        data: [],
    });

    const getVcu2EstimatedEptCoolantFlowValue = () => {
        if (selectedVcu2.includes(Common.EVSOLUTION_V2_ESTIMATED_EPT_COOLANT_FLOW_KEY) && currentCanData !== undefined) {
            return currentCanData.vcu2?.estimatedEptCoolantFlow;
        }
        return "-";
    };

    const getVcu2EptCoolantTempValue = () => {
        if (selectedVcu2.includes(Common.EVSOLUTION_V2_EPT_COOLANT_TEMP_KEY) && currentCanData !== undefined) {
            return currentCanData.vcu2?.eptCoolantTemp;
        }
        return "-";
    };

    const getVcu2CommandModeValue = () => {
        if (selectedVcu2.includes(Common.EVSOLUTION_V2_COMMAND_MODE_KEY) && currentCanData !== undefined) {
            return convertRemarkTorqueMode(currentCanData.vcu2?.commandMode);
        }
        return "-";
    };

    const getVcu2ActvDischargeReqValue = () => {
        if (selectedVcu2.includes(Common.EVSOLUTION_V2_ACTV_DISCHARGE_REQ_KEY) && currentCanData !== undefined) {
            return convertRemarkRequest(currentCanData.vcu2?.actvDischargeReq);
        }
        return "-";
    };

    const getVcu2OperationModeCmdValue = () => {
        if (selectedVcu2.includes(Common.EVSOLUTION_V2_OPERATION_MODE_CMD_KEY) && currentCanData !== undefined) {
            return convertRemarkOperationalModeCmd(currentCanData.vcu2?.operationModeCmd);
        }
        return "-";
    };

    const getVcu2TorqueCommandValue = () => {
        if (selectedVcu2.includes(Common.EVSOLUTION_V2_TORQUE_COMMAND_KEY) && currentCanData !== undefined) {
            return currentCanData.vcu2?.torqueCommand;
        }
        return "-";
    };

    const getVcu2CounterValue = () => {
        if (selectedVcu2.includes(Common.EVSOLUTION_V2_COUNTER_KEY) && currentCanData !== undefined) {
            return currentCanData.vcu2?.counter;
        }
        return "-";
    };

    const getVcu2ChecksumValue = () => {
        if (selectedVcu2.includes(Common.EVSOLUTION_V2_CHECKSUM_KEY) && currentCanData !== undefined) {
            return currentCanData.vcu2?.checksum;
        }
        return "-";
    };

    const onChangeVcu2 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedVcu2(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasVcu2: Array<TypeUtils.ChartData> = [];

        if (selectedVcu2.includes(Common.EVSOLUTION_V2_ESTIMATED_EPT_COOLANT_FLOW_KEY)) chartDatasVcu2.push(chartVcu2EstimatedEptCoolantFlow);
        if (selectedVcu2.includes(Common.EVSOLUTION_V2_EPT_COOLANT_TEMP_KEY)) chartDatasVcu2.push(chartVcu2EptCoolantTemp);
        if (selectedVcu2.includes(Common.EVSOLUTION_V2_COMMAND_MODE_KEY)) chartDatasVcu2.push(chartVcu2CommandMode);
        if (selectedVcu2.includes(Common.EVSOLUTION_V2_ACTV_DISCHARGE_REQ_KEY)) chartDatasVcu2.push(chartVcu2ActvDischargeReq);
        if (selectedVcu2.includes(Common.EVSOLUTION_V2_OPERATION_MODE_CMD_KEY)) chartDatasVcu2.push(chartVcu2OperationModeCmd);
        if (selectedVcu2.includes(Common.EVSOLUTION_V2_TORQUE_COMMAND_KEY)) chartDatasVcu2.push(chartVcu2TorqueCommand);
        if (selectedVcu2.includes(Common.EVSOLUTION_V2_COUNTER_KEY)) chartDatasVcu2.push(chartVcu2Counter);
        if (selectedVcu2.includes(Common.EVSOLUTION_V2_CHECKSUM_KEY)) chartDatasVcu2.push(chartVcu2Checksum);

        setSeriesChartDatasVcu2(chartDatasVcu2);
    }, [
        selectedVcu2,
        chartVcu2EstimatedEptCoolantFlow,
        chartVcu2EptCoolantTemp,
        chartVcu2CommandMode,
        chartVcu2ActvDischargeReq,
        chartVcu2OperationModeCmd,
        chartVcu2TorqueCommand,
        chartVcu2Counter,
        chartVcu2Checksum,
    ]);

    const tableDataVcu2: CanTableType[] = [
        {
            key: Common.EVSOLUTION_V2_ESTIMATED_EPT_COOLANT_FLOW_KEY,
            name: Common.EVSOLUTION_V2_ESTIMATED_EPT_COOLANT_FLOW_NAME,
            value: getVcu2EstimatedEptCoolantFlowValue(),
        },
        {
            key: Common.EVSOLUTION_V2_EPT_COOLANT_TEMP_KEY,
            name: Common.EVSOLUTION_V2_EPT_COOLANT_TEMP_NAME,
            value: getVcu2EptCoolantTempValue(),
        },
        {
            key: Common.EVSOLUTION_V2_COMMAND_MODE_KEY,
            name: Common.EVSOLUTION_V2_COMMAND_MODE_NAME,
            value: getVcu2CommandModeValue(),
        },
        {
            key: Common.EVSOLUTION_V2_ACTV_DISCHARGE_REQ_KEY,
            name: Common.EVSOLUTION_V2_ACTV_DISCHARGE_REQ_NAME,
            value: getVcu2ActvDischargeReqValue(),
        },
        {
            key: Common.EVSOLUTION_V2_OPERATION_MODE_CMD_KEY,
            name: Common.EVSOLUTION_V2_OPERATION_MODE_CMD_NAME,
            value: getVcu2OperationModeCmdValue(),
        },
        {
            key: Common.EVSOLUTION_V2_TORQUE_COMMAND_KEY,
            name: Common.EVSOLUTION_V2_TORQUE_COMMAND_NAME,
            value: getVcu2TorqueCommandValue(),
        },
        {
            key: Common.EVSOLUTION_V2_COUNTER_KEY,
            name: Common.EVSOLUTION_V2_COUNTER_NAME,
            value: getVcu2CounterValue(),
        },
        {
            key: Common.EVSOLUTION_V2_CHECKSUM_KEY,
            name: Common.EVSOLUTION_V2_CHECKSUM_NAME,
            value: getVcu2ChecksumValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    useEffect(() => {
        if (vehicleInfo?.makerModel?.maker?.makerName === Common.VehicleMaker.EVSolution) {
            //#region Bms1
            const bms1BatPackStatusDatas: TypeUtils.ChartElement[] = [];
            const bms1OperationModeDatas: TypeUtils.ChartElement[] = [];
            const bms1DcLinkVoltDatas: TypeUtils.ChartElement[] = [];
            const bms1BatPackCurrDatas: TypeUtils.ChartElement[] = [];
            const bms1MainMinusContactorCoilStateDatas: TypeUtils.ChartElement[] = [];
            const bms1MainPlusContactorCoilStateDatas: TypeUtils.ChartElement[] = [];
            const bms1PreChargeContactorCoilStateDatas: TypeUtils.ChartElement[] = [];
            const bms1IgnStatDatas: TypeUtils.ChartElement[] = [];
            const bms1HvilStatusDatas: TypeUtils.ChartElement[] = [];
            const bms1CounterDatas: TypeUtils.ChartElement[] = [];
            const bms1MainMinusContactorStateDatas: TypeUtils.ChartElement[] = [];
            const bms1MainPlusContactorStateDatas: TypeUtils.ChartElement[] = [];
            const bms1PreChargeContactorStateDatas: TypeUtils.ChartElement[] = [];
            const bms1ContactorOffRequestDatas: TypeUtils.ChartElement[] = [];
            const bms1ChecksumDatas: TypeUtils.ChartElement[] = [];
            //#endregion

            //#region Bms2
            const bms2BatPackVoltDatas: TypeUtils.ChartElement[] = [];
            const bms2BmsModeDatas: TypeUtils.ChartElement[] = [];
            const bms2WaterPumpStatusDatas: TypeUtils.ChartElement[] = [];
            const bms2BalancingStatusDatas: TypeUtils.ChartElement[] = [];
            const bms2BatPackAsocDatas: TypeUtils.ChartElement[] = [];
            const bms2BatPackRsocDatas: TypeUtils.ChartElement[] = [];
            const bms2BatPackSohDatas: TypeUtils.ChartElement[] = [];
            //#endregion

            //#region Bms3

            const bms3BatMinCellVoltDatas: TypeUtils.ChartElement[] = [];
            const bms3BatMaxCellVoltDatas: TypeUtils.ChartElement[] = [];
            const bms3BatAvgCellVoltDatas: TypeUtils.ChartElement[] = [];
            const bms3BatSumVoltDatas: TypeUtils.ChartElement[] = [];
            //#endregion

            //#region Bms4
            const bms4BatMinTempDatas: TypeUtils.ChartElement[] = [];
            const bms4BatMaxTempDatas: TypeUtils.ChartElement[] = [];
            const bms4BatAvgTempDatas: TypeUtils.ChartElement[] = [];
            const bms4BatMinTempLocationDatas: TypeUtils.ChartElement[] = [];
            const bms4BatMaxTempLocationDatas: TypeUtils.ChartElement[] = [];
            const bms4CoolingPumpFeedbackStatusDatas: TypeUtils.ChartElement[] = [];
            const bms4CoolingRequestDatas: TypeUtils.ChartElement[] = [];
            const bms4ChargingStopDatas: TypeUtils.ChartElement[] = [];
            const bms4BhcStsOwTsDatas: TypeUtils.ChartElement[] = [];
            const bms4BhcStsWhuWtODatas: TypeUtils.ChartElement[] = [];
            const bms4BhcStsErrDatas: TypeUtils.ChartElement[] = [];
            const bms4AuxBatteryDatas: TypeUtils.ChartElement[] = [];
            //#endregion

            //#region Bms5
            const bms5ChargePowerAvailable2Datas: TypeUtils.ChartElement[] = [];
            const bms5DischargePowerAvailable2Datas: TypeUtils.ChartElement[] = [];
            const bms5ChargePowerAvailable10Datas: TypeUtils.ChartElement[] = [];
            const bms5DischargePowerAvailable10Datas: TypeUtils.ChartElement[] = [];
            const bms5BatPackSoeDatas: TypeUtils.ChartElement[] = [];
            //#endregion

            //#region Bms6
            const bms6ChargeCurrLimitDatas: TypeUtils.ChartElement[] = [];
            const bms6ChargeVoltLimitDatas: TypeUtils.ChartElement[] = [];
            const bms6InstantPowerConsumptionDatas: TypeUtils.ChartElement[] = [];
            const bms6MinIsolationResistanceDatas: TypeUtils.ChartElement[] = [];
            //#endregion

            //#region Bms Fault
            const bmsFaultHwVerDatas: TypeUtils.ChartElement[] = [];
            const bmsFaultSwVerDatas: TypeUtils.ChartElement[] = [];
            const bmsFaultBootVerDatas: TypeUtils.ChartElement[] = [];
            const bmsFaultIntFltDatas: TypeUtils.ChartElement[] = [];
            const bmsFaultCmiFltInitDatas: TypeUtils.ChartElement[] = [];
            const bmsFaultCmiFltDatas: TypeUtils.ChartElement[] = [];
            const bmsFaultFetFltDatas: TypeUtils.ChartElement[] = [];
            const bmsFaultRlyMnCsDatas: TypeUtils.ChartElement[] = [];
            const bmsFaultRlyMpCsDatas: TypeUtils.ChartElement[] = [];
            const bmsFaultItempShDatas: TypeUtils.ChartElement[] = [];
            const bmsFaultItempSlDatas: TypeUtils.ChartElement[] = [];
            const bmsFaultIsoFltDatas: TypeUtils.ChartElement[] = [];
            const bmsFaultCrashOpnDatas: TypeUtils.ChartElement[] = [];
            const bmsFaultCrashHwDatas: TypeUtils.ChartElement[] = [];
            const bmsFaultCrashCanDatas: TypeUtils.ChartElement[] = [];
            const bmsFaultVcuLcmDatas: TypeUtils.ChartElement[] = [];
            const bmsFaultCrtSensWanDatas: TypeUtils.ChartElement[] = [];
            const bmsFaultCrtSensDevDatas: TypeUtils.ChartElement[] = [];
            const bmsFaultEcanBusOffDatas: TypeUtils.ChartElement[] = [];
            const bmsFaultPcanBusOffDatas: TypeUtils.ChartElement[] = [];
            const bmsFaultHeaterFltDatas: TypeUtils.ChartElement[] = [];
            const bmsFaultRlyMnCosDatas: TypeUtils.ChartElement[] = [];
            const bmsFaultRlyMpCosDatas: TypeUtils.ChartElement[] = [];
            const bmsFaultRlyPcCosDatas: TypeUtils.ChartElement[] = [];
            const bmsFaultRlyMnCcsDatas: TypeUtils.ChartElement[] = [];
            const bmsFaultRlyMpCcsatas: TypeUtils.ChartElement[] = [];
            const bmsFaultRlyPcCcsDatas: TypeUtils.ChartElement[] = [];
            const bmsFaultCrashNrDatas: TypeUtils.ChartElement[] = [];
            const bmsFaultCtempFltAllDatas: TypeUtils.ChartElement[] = [];
            const bmsFaultCellOpnDatas: TypeUtils.ChartElement[] = [];
            const bmsFaultCtempFltDatas: TypeUtils.ChartElement[] = [];
            const bmsFaultCvHFltDatas: TypeUtils.ChartElement[] = [];
            const bmsFaultCvLFltDatas: TypeUtils.ChartElement[] = [];
            const bmsFaultCvHWanDatas: TypeUtils.ChartElement[] = [];
            const bmsFaultCvLWanDatas: TypeUtils.ChartElement[] = [];
            const bmsFaultCvDevOcvFltDatas: TypeUtils.ChartElement[] = [];
            const bmsFaultCvDevFltDatas: TypeUtils.ChartElement[] = [];
            const bmsFaultPvHFltDatas: TypeUtils.ChartElement[] = [];
            const bmsFaultPvLFltDatas: TypeUtils.ChartElement[] = [];
            const bmsFaultPvHWanDatas: TypeUtils.ChartElement[] = [];
            const bmsFaultPvLWanDatas: TypeUtils.ChartElement[] = [];
            const bmsFaultCtHFltDatas: TypeUtils.ChartElement[] = [];
            const bmsFaultCtHWanDatas: TypeUtils.ChartElement[] = [];
            //#endregion

            //#region Mcu1
            const mcu1MotorTorqueEstimatedRs1Datas: TypeUtils.ChartElement[] = [];
            const mcu1MotorTorqueEstimatedDatas: TypeUtils.ChartElement[] = [];
            const mcu1MotorSpeedDatas: TypeUtils.ChartElement[] = [];
            const mcu1CounterDatas: TypeUtils.ChartElement[] = [];
            const mcu1ChecksumDatas: TypeUtils.ChartElement[] = [];
            //#endregion

            //#region Vcu1
            const vcu1MainContactorControlDatas: TypeUtils.ChartElement[] = [];
            const vcu1IsoltMtrStpReqDatas: TypeUtils.ChartElement[] = [];
            const vcu1CounterDatas: TypeUtils.ChartElement[] = [];
            const vcu1ChecksumDatas: TypeUtils.ChartElement[] = [];
            //#endregion

            //#region Vcu2
            const vcu2EstimatedEptCoolantFlowDatas: TypeUtils.ChartElement[] = [];
            const vcu2EptCoolantTempDatas: TypeUtils.ChartElement[] = [];
            const vcu2CommandModeDatas: TypeUtils.ChartElement[] = [];
            const vcu2ActvDischargeReqDatas: TypeUtils.ChartElement[] = [];
            const vcu2OperationModeCmdDatas: TypeUtils.ChartElement[] = [];
            const vcu2TorqueCommandDatas: TypeUtils.ChartElement[] = [];
            const vcu2CounterDatas: TypeUtils.ChartElement[] = [];
            const vcu2ChecksumDatas: TypeUtils.ChartElement[] = [];
            //#endregion

            runningCanDataList?.forEach((data) => {
                const canData = data as CanDataEVSolution.CanData;

                const time = moment(canData.dataTime, Common.FORMAT_DATE_TIME_MILLISECOND);

                //#region Bms1
                bms1BatPackStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.bms1?.batPackStatus || 0,
                });

                bms1OperationModeDatas.push({
                    x: time.valueOf(),
                    y: canData.bms1?.operationMode || 0,
                });

                bms1DcLinkVoltDatas.push({
                    x: time.valueOf(),
                    y: canData.bms1?.dcLinkVolt || 0,
                });

                bms1BatPackCurrDatas.push({
                    x: time.valueOf(),
                    y: canData.bms1?.batPackCurr || 0,
                });

                bms1MainMinusContactorCoilStateDatas.push({
                    x: time.valueOf(),
                    y: canData.bms1?.mainMinusContactorCoilState || 0,
                });

                bms1MainPlusContactorCoilStateDatas.push({
                    x: time.valueOf(),
                    y: canData.bms1?.mainPlusContactorCoilState || 0,
                });

                bms1PreChargeContactorCoilStateDatas.push({
                    x: time.valueOf(),
                    y: canData.bms1?.preChargeContactorCoilState || 0,
                });

                bms1IgnStatDatas.push({
                    x: time.valueOf(),
                    y: canData.bms1?.ignStat || 0,
                });

                bms1HvilStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.bms1?.hvilStatus || 0,
                });

                bms1CounterDatas.push({
                    x: time.valueOf(),
                    y: canData.bms1?.counter || 0,
                });

                bms1MainMinusContactorStateDatas.push({
                    x: time.valueOf(),
                    y: canData.bms1?.mainMinusContactorState || 0,
                });

                bms1MainPlusContactorStateDatas.push({
                    x: time.valueOf(),
                    y: canData.bms1?.mainPlusContactorState || 0,
                });

                bms1PreChargeContactorStateDatas.push({
                    x: time.valueOf(),
                    y: canData.bms1?.preChargeContactorState || 0,
                });

                bms1ContactorOffRequestDatas.push({
                    x: time.valueOf(),
                    y: canData.bms1?.contactorOffRequest || 0,
                });

                bms1ChecksumDatas.push({
                    x: time.valueOf(),
                    y: canData.bms1?.checksum || 0,
                });
                //#endregion

                //#region Bms2
                bms2BatPackVoltDatas.push({
                    x: time.valueOf(),
                    y: canData.bms2?.batPackVolt || 0,
                });

                bms2BmsModeDatas.push({
                    x: time.valueOf(),
                    y: canData.bms2?.bmsMode || 0,
                });

                bms2WaterPumpStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.bms2?.waterPumpStatus || 0,
                });

                bms2BalancingStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.bms2?.balancingStatus || 0,
                });

                bms2BatPackAsocDatas.push({
                    x: time.valueOf(),
                    y: canData.bms2?.batPackAsoc || 0,
                });

                bms2BatPackRsocDatas.push({
                    x: time.valueOf(),
                    y: canData.bms2?.batPackRsoc || 0,
                });

                bms2BatPackSohDatas.push({
                    x: time.valueOf(),
                    y: canData.bms2?.batPackSoh || 0,
                });
                //#endregion

                //#region Bms3
                bms3BatMinCellVoltDatas.push({
                    x: time.valueOf(),
                    y: canData.bms3?.batMinCellVolt || 0,
                });

                bms3BatMaxCellVoltDatas.push({
                    x: time.valueOf(),
                    y: canData.bms3?.batMaxCellVolt || 0,
                });

                bms3BatAvgCellVoltDatas.push({
                    x: time.valueOf(),
                    y: canData.bms3?.batAvgCellVolt || 0,
                });

                bms3BatSumVoltDatas.push({
                    x: time.valueOf(),
                    y: canData.bms3?.batSumVolt || 0,
                });
                //#endregion

                //#region Bms4
                bms4BatMinTempDatas.push({
                    x: time.valueOf(),
                    y: canData.bms4?.batMinTemp || 0,
                });

                bms4BatMaxTempDatas.push({
                    x: time.valueOf(),
                    y: canData.bms4?.batMaxTemp || 0,
                });

                bms4BatAvgTempDatas.push({
                    x: time.valueOf(),
                    y: canData.bms4?.batAvgTemp || 0,
                });

                bms4BatMinTempLocationDatas.push({
                    x: time.valueOf(),
                    y: canData.bms4?.batMinTempLocation || 0,
                });

                bms4BatMaxTempLocationDatas.push({
                    x: time.valueOf(),
                    y: canData.bms4?.batMaxTempLocation || 0,
                });

                bms4CoolingPumpFeedbackStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.bms4?.coolingPumpFeedbackStatus || 0,
                });

                bms4CoolingRequestDatas.push({
                    x: time.valueOf(),
                    y: canData.bms4?.coolingRequest || 0,
                });

                bms4ChargingStopDatas.push({
                    x: time.valueOf(),
                    y: canData.bms4?.chargingStop || 0,
                });

                bms4BhcStsOwTsDatas.push({
                    x: time.valueOf(),
                    y: canData.bms4?.bhcStsOwTs || 0,
                });

                bms4BhcStsWhuWtODatas.push({
                    x: time.valueOf(),
                    y: canData.bms4?.bhcStsWhuWtO || 0,
                });

                bms4BhcStsErrDatas.push({
                    x: time.valueOf(),
                    y: canData.bms4?.bhcStsErr || 0,
                });

                bms4AuxBatteryDatas.push({
                    x: time.valueOf(),
                    y: canData.bms4?.auxBattery || 0,
                });
                //#endregion

                //#region Bms5
                bms5ChargePowerAvailable2Datas.push({
                    x: time.valueOf(),
                    y: canData.bms5?.chargePowerAvailable2 || 0,
                });

                bms5DischargePowerAvailable2Datas.push({
                    x: time.valueOf(),
                    y: canData.bms5?.dischargePowerAvailable2 || 0,
                });

                bms5ChargePowerAvailable10Datas.push({
                    x: time.valueOf(),
                    y: canData.bms5?.chargePowerAvailable10 || 0,
                });

                bms5DischargePowerAvailable10Datas.push({
                    x: time.valueOf(),
                    y: canData.bms5?.dischargePowerAvailable10 || 0,
                });

                bms5BatPackSoeDatas.push({
                    x: time.valueOf(),
                    y: canData.bms5?.batPackSoe || 0,
                });
                //#endregion

                //#region Bms6
                bms6ChargeCurrLimitDatas.push({
                    x: time.valueOf(),
                    y: canData.bms6?.chargeCurrLimit || 0,
                });

                bms6ChargeVoltLimitDatas.push({
                    x: time.valueOf(),
                    y: canData.bms6?.chargeVoltLimit || 0,
                });

                bms6InstantPowerConsumptionDatas.push({
                    x: time.valueOf(),
                    y: canData.bms6?.instantPowerConsumption || 0,
                });

                bms6MinIsolationResistanceDatas.push({
                    x: time.valueOf(),
                    y: canData.bms6?.minIsolationResistance || 0,
                });
                //#endregion

                //#region Bms Fault
                bmsFaultHwVerDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsFault?.hwVer || 0,
                });

                bmsFaultSwVerDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsFault?.swVer || 0,
                });

                bmsFaultBootVerDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsFault?.bootVer || 0,
                });

                bmsFaultIntFltDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsFault?.intFlt || 0,
                });

                bmsFaultCmiFltInitDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsFault?.cmiFltInit || 0,
                });

                bmsFaultCmiFltDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsFault?.cmiFlt || 0,
                });

                bmsFaultFetFltDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsFault?.fetFlt || 0,
                });

                bmsFaultRlyMnCsDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsFault?.rlyMnCs || 0,
                });

                bmsFaultRlyMpCsDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsFault?.rlyMpCs || 0,
                });

                bmsFaultItempShDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsFault?.itempSh || 0,
                });

                bmsFaultItempSlDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsFault?.itempSl || 0,
                });

                bmsFaultIsoFltDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsFault?.isoFlt || 0,
                });

                bmsFaultCrashOpnDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsFault?.crashOpn || 0,
                });

                bmsFaultCrashHwDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsFault?.crashHw || 0,
                });

                bmsFaultCrashCanDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsFault?.crashCan || 0,
                });
                bmsFaultVcuLcmDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsFault?.vcuLcm || 0,
                });

                bmsFaultCrtSensWanDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsFault?.crtSensWan || 0,
                });

                bmsFaultCrtSensDevDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsFault?.crtSensDev || 0,
                });

                bmsFaultEcanBusOffDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsFault?.ecanBusOff || 0,
                });

                bmsFaultPcanBusOffDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsFault?.pcanBusOff || 0,
                });

                bmsFaultHeaterFltDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsFault?.heaterFlt || 0,
                });

                bmsFaultRlyMnCosDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsFault?.rlyMnCos || 0,
                });

                bmsFaultRlyMpCosDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsFault?.rlyMpCos || 0,
                });

                bmsFaultRlyPcCosDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsFault?.rlyPcCos || 0,
                });

                bmsFaultRlyMnCcsDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsFault?.rlyMnCcs || 0,
                });

                bmsFaultRlyMpCcsatas.push({
                    x: time.valueOf(),
                    y: canData.bmsFault?.rlyMpCcs || 0,
                });

                bmsFaultRlyPcCcsDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsFault?.rlyPcCcs || 0,
                });

                bmsFaultCrashNrDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsFault?.crashNr || 0,
                });

                bmsFaultCtempFltAllDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsFault?.ctempFltAll || 0,
                });

                bmsFaultCellOpnDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsFault?.cellOpn || 0,
                });

                bmsFaultCtempFltDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsFault?.ctempFlt || 0,
                });

                bmsFaultCvHFltDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsFault?.cvHFlt || 0,
                });

                bmsFaultCvLFltDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsFault?.cvLFlt || 0,
                });

                bmsFaultCvHWanDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsFault?.cvHWan || 0,
                });

                bmsFaultCvLWanDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsFault?.cvLWan || 0,
                });

                bmsFaultCvDevOcvFltDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsFault?.cvDevOcvFlt || 0,
                });

                bmsFaultCvDevFltDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsFault?.cvDevFlt || 0,
                });

                bmsFaultPvHFltDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsFault?.pvHFlt || 0,
                });

                bmsFaultPvLFltDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsFault?.pvLFlt || 0,
                });

                bmsFaultPvHWanDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsFault?.pvHWan || 0,
                });

                bmsFaultPvLWanDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsFault?.pvLWan || 0,
                });

                bmsFaultCtHFltDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsFault?.ctHFlt || 0,
                });

                bmsFaultCtHWanDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsFault?.ctHWan || 0,
                });
                //#endregion

                //#region Mcu1
                mcu1MotorTorqueEstimatedRs1Datas.push({
                    x: time.valueOf(),
                    y: canData.mcu1?.motorTorqueEstimatedRs1 || 0,
                });

                mcu1MotorTorqueEstimatedDatas.push({
                    x: time.valueOf(),
                    y: canData.mcu1?.motorTorqueEstimated || 0,
                });

                mcu1MotorSpeedDatas.push({
                    x: time.valueOf(),
                    y: canData.mcu1?.motorSpeed || 0,
                });

                mcu1CounterDatas.push({
                    x: time.valueOf(),
                    y: canData.mcu1?.counter || 0,
                });

                mcu1ChecksumDatas.push({
                    x: time.valueOf(),
                    y: canData.mcu1?.checksum || 0,
                });
                //#endregion

                //#region Vcu1
                vcu1MainContactorControlDatas.push({
                    x: time.valueOf(),
                    y: canData.vcu1?.mainContactorControl || 0,
                });

                vcu1IsoltMtrStpReqDatas.push({
                    x: time.valueOf(),
                    y: canData.vcu1?.isoltMtrStpReq || 0,
                });

                vcu1CounterDatas.push({
                    x: time.valueOf(),
                    y: canData.vcu1?.counter || 0,
                });

                vcu1ChecksumDatas.push({
                    x: time.valueOf(),
                    y: canData.vcu1?.checksum || 0,
                });
                //#endregion

                //#region Vcu2
                vcu2EstimatedEptCoolantFlowDatas.push({
                    x: time.valueOf(),
                    y: canData.vcu2?.estimatedEptCoolantFlow || 0,
                });

                vcu2EptCoolantTempDatas.push({
                    x: time.valueOf(),
                    y: canData.vcu2?.eptCoolantTemp || 0,
                });

                vcu2CommandModeDatas.push({
                    x: time.valueOf(),
                    y: canData.vcu2?.commandMode || 0,
                });

                vcu2ActvDischargeReqDatas.push({
                    x: time.valueOf(),
                    y: canData.vcu2?.actvDischargeReq || 0,
                });

                vcu2OperationModeCmdDatas.push({
                    x: time.valueOf(),
                    y: canData.vcu2?.operationModeCmd || 0,
                });

                vcu2TorqueCommandDatas.push({
                    x: time.valueOf(),
                    y: canData.vcu2?.torqueCommand || 0,
                });

                vcu2CounterDatas.push({
                    x: time.valueOf(),
                    y: canData.vcu2?.counter || 0,
                });

                vcu2ChecksumDatas.push({
                    x: time.valueOf(),
                    y: canData.vcu2?.checksum || 0,
                });
                //#endregion
            });

            //#region Bms1
            setChartBms1BatPackStatus({
                ...chartBms1BatPackStatus,
                data: bms1BatPackStatusDatas,
            });

            setChartBms1OperationMode({
                ...chartBms1OperationMode,
                data: bms1OperationModeDatas,
            });

            setChartBms1DcLinkVolt({
                ...chartBms1DcLinkVolt,
                data: bms1DcLinkVoltDatas,
            });

            setChartBms1BatPackCurr({
                ...chartBms1BatPackCurr,
                data: bms1BatPackCurrDatas,
            });

            setChartBms1MainMinusContactorCoilState({
                ...chartBms1MainPlusContactorCoilState,
                data: bms1MainPlusContactorCoilStateDatas,
            });

            setChartBms1MainPlusContactorCoilState({
                ...chartBms1BatPackStatus,
                data: bms1BatPackStatusDatas,
            });

            setChartBms1PreChargeContactorCoilState({
                ...chartBms1PreChargeContactorCoilState,
                data: bms1PreChargeContactorCoilStateDatas,
            });

            setChartBms1IgnStat({
                ...chartBms1IgnStat,
                data: bms1IgnStatDatas,
            });

            setChartBms1HvilStatus({
                ...chartBms1HvilStatus,
                data: bms1HvilStatusDatas,
            });

            setChartBms1Counter({
                ...chartBms1Counter,
                data: bms1CounterDatas,
            });

            setChartBms1MainMinusContactorState({
                ...chartBms1MainMinusContactorState,
                data: bms1MainMinusContactorStateDatas,
            });

            setChartBms1MainPlusContactorState({
                ...chartBms1MainPlusContactorState,
                data: bms1MainPlusContactorStateDatas,
            });

            setChartBms1PreChargeContactorState({
                ...chartBms1PreChargeContactorState,
                data: bms1PreChargeContactorStateDatas,
            });

            setChartBms1ContactorOffRequest({
                ...chartBms1ContactorOffRequest,
                data: bms1ContactorOffRequestDatas,
            });

            setChartBms1Checksum({
                ...chartBms1Checksum,
                data: bms1ChecksumDatas,
            });
            //#endregion

            //#region Bms2
            setChartBms2BatPackVolt({
                ...chartBms2BatPackVolt,
                data: bms2BatPackVoltDatas,
            });

            setChartBms2BmsMode({
                ...chartBms2BmsMode,
                data: bms2BmsModeDatas,
            });

            setChartBms2WaterPumpStatus({
                ...chartBms2WaterPumpStatus,
                data: bms2WaterPumpStatusDatas,
            });

            setChartBms2BalancingStatus({
                ...chartBms2BalancingStatus,
                data: bms2BalancingStatusDatas,
            });

            setChartBms2BatPackAsoc({
                ...chartBms2BatPackAsoc,
                data: bms2BatPackAsocDatas,
            });

            setChartBms2BatPackRsoc({
                ...chartBms2BatPackRsoc,
                data: bms2BatPackRsocDatas,
            });

            setChartBms2BatPackSoh({
                ...chartBms2BatPackSoh,
                data: bms2BatPackSohDatas,
            });
            //#endregion

            //#region Bms3
            setChartBms3BatMinCellVolt({
                ...chartBms3BatMinCellVolt,
                data: bms3BatMinCellVoltDatas,
            });

            setChartBms3BatMaxCellVolt({
                ...chartBms3BatMaxCellVolt,
                data: bms3BatMaxCellVoltDatas,
            });

            setChartBms3BatAvgCellVolt({
                ...chartBms3BatAvgCellVolt,
                data: bms3BatAvgCellVoltDatas,
            });

            setChartBms3BatSumVolt({
                ...chartBms3BatSumVolt,
                data: bms3BatSumVoltDatas,
            });
            //#endregion

            //#region Bms4
            setChartBms4BatMinTemp({
                ...chartBms4BatMinTemp,
                data: bms4BatMinTempDatas,
            });

            setChartBms4BatMaxTemp({
                ...chartBms4BatMaxTemp,
                data: bms4BatMaxTempDatas,
            });

            setChartBms4BatAvgTemp({
                ...chartBms4BatAvgTemp,
                data: bms4BatAvgTempDatas,
            });

            setChartBms4BatMinTempLocation({
                ...chartBms4BatMinTempLocation,
                data: bms4BatMinTempLocationDatas,
            });

            setChartBms4BatMaxTempLocation({
                ...chartBms4BatMaxTempLocation,
                data: bms4BatMaxTempLocationDatas,
            });

            setChartBms4CoolingPumpFeedbackStatus({
                ...chartBms4CoolingPumpFeedbackStatus,
                data: bms4CoolingPumpFeedbackStatusDatas,
            });

            setChartBms4CoolingRequest({
                ...chartBms4CoolingRequest,
                data: bms4CoolingRequestDatas,
            });

            setChartBms4ChargingStop({
                ...chartBms4ChargingStop,
                data: bms4ChargingStopDatas,
            });

            setChartBms4BhcStsOwTs({
                ...chartBms4BhcStsOwTs,
                data: bms4BhcStsOwTsDatas,
            });

            setChartBms4BhcStsWhuWtO({
                ...chartBms4BhcStsWhuWtO,
                data: bms4BhcStsWhuWtODatas,
            });

            setChartBms4BhcStsErr({
                ...chartBms4BhcStsErr,
                data: bms4BhcStsErrDatas,
            });

            setChartBms4AuxBattery({
                ...chartBms4AuxBattery,
                data: bms4AuxBatteryDatas,
            });
            //#endregion

            //#region Bms5
            setChartBms5ChargePowerAvailable2({
                ...chartBms5ChargePowerAvailable2,
                data: bms5ChargePowerAvailable2Datas,
            });

            setChartBms5DischargePowerAvailable2({
                ...chartBms5DischargePowerAvailable2,
                data: bms5DischargePowerAvailable2Datas,
            });

            setChartBms5ChargePowerAvailable10({
                ...chartBms5ChargePowerAvailable10,
                data: bms5ChargePowerAvailable10Datas,
            });

            setChartBms5DischargePowerAvailable10({
                ...chartBms5DischargePowerAvailable10,
                data: bms5DischargePowerAvailable10Datas,
            });

            setChartBms5BatPackSoe({
                ...chartBms5BatPackSoe,
                data: bms5BatPackSoeDatas,
            });
            //#endregion

            //#region Bms6
            setChartBms6ChargeCurrLimit({
                ...chartBms6ChargeCurrLimit,
                data: bms6ChargeCurrLimitDatas,
            });

            setChartBms6ChargeVoltLimit({
                ...chartBms6ChargeVoltLimit,
                data: bms6ChargeVoltLimitDatas,
            });

            setChartBms6InstantPowerConsumption({
                ...chartBms6InstantPowerConsumption,
                data: bms6InstantPowerConsumptionDatas,
            });

            setChartBms6MinIsolationResistance({
                ...chartBms6MinIsolationResistance,
                data: bms6MinIsolationResistanceDatas,
            });
            //#endregion

            //#region Bms Fault
            setChartBmsFaultHwVer({
                ...chartBmsFaultHwVer,
                data: bmsFaultHwVerDatas,
            });

            setChartBmsFaultSwVer({
                ...chartBmsFaultSwVer,
                data: bmsFaultSwVerDatas,
            });

            setChartBmsFaultBootVer({
                ...chartBmsFaultBootVer,
                data: bmsFaultBootVerDatas,
            });

            setChartBmsFaultIntFlt({
                ...chartBmsFaultIntFlt,
                data: bmsFaultIntFltDatas,
            });

            setChartBmsFaultCmiFltInit({
                ...chartBmsFaultCmiFltInit,
                data: bmsFaultCmiFltInitDatas,
            });

            setChartBmsFaultCmiFlt({
                ...chartBmsFaultCmiFlt,
                data: bmsFaultCmiFltDatas,
            });

            setChartBmsFaultFetFlt({
                ...chartBmsFaultFetFlt,
                data: bmsFaultFetFltDatas,
            });

            setChartBmsFaultRlyMnCs({
                ...chartBmsFaultRlyMnCs,
                data: bmsFaultRlyMnCsDatas,
            });

            setChartBmsFaultRlyMpCs({
                ...chartBmsFaultRlyMpCs,
                data: bmsFaultRlyMpCsDatas,
            });

            setChartBmsFaultItempSh({
                ...chartBmsFaultItempSh,
                data: bmsFaultItempShDatas,
            });

            setChartBmsFaultItempSl({
                ...chartBmsFaultItempSl,
                data: bmsFaultItempSlDatas,
            });

            setChartBmsFaultIsoFlt({
                ...chartBmsFaultIsoFlt,
                data: bmsFaultIsoFltDatas,
            });

            setChartBmsFaultCrashOpn({
                ...chartBmsFaultCrashOpn,
                data: bmsFaultCrashOpnDatas,
            });

            setChartBmsFaultCrashHw({
                ...chartBmsFaultCrashHw,
                data: bmsFaultCrashHwDatas,
            });

            setChartBmsFaultCrashCan({
                ...chartBmsFaultCrashCan,
                data: bmsFaultCrashCanDatas,
            });

            setChartBmsFaultVcuLcm({
                ...chartBmsFaultVcuLcm,
                data: bmsFaultVcuLcmDatas,
            });

            setChartBmsFaultCrtSensWan({
                ...chartBmsFaultCrtSensWan,
                data: bmsFaultCrtSensWanDatas,
            });

            setChartBmsFaultCrtSensDev({
                ...chartBmsFaultCrtSensDev,
                data: bmsFaultCrtSensDevDatas,
            });

            setChartBmsFaultEcanBusOff({
                ...chartBmsFaultEcanBusOff,
                data: bmsFaultEcanBusOffDatas,
            });

            setChartBmsFaultPcanBusOff({
                ...chartBmsFaultPcanBusOff,
                data: bmsFaultPcanBusOffDatas,
            });

            setChartBmsFaultHeaterFlt({
                ...chartBmsFaultHeaterFlt,
                data: bmsFaultHeaterFltDatas,
            });

            setChartBmsFaultRlyMnCos({
                ...chartBmsFaultRlyMnCos,
                data: bmsFaultRlyMnCosDatas,
            });

            setChartBmsFaultRlyMpCos({
                ...chartBmsFaultRlyMpCos,
                data: bmsFaultRlyMpCosDatas,
            });

            setChartBmsFaultRlyPcCos({
                ...chartBmsFaultRlyPcCos,
                data: bmsFaultRlyPcCosDatas,
            });

            setChartBmsFaultRlyMnCcs({
                ...chartBmsFaultRlyMnCcs,
                data: bmsFaultRlyMnCcsDatas,
            });

            setChartBmsFaultRlyMpCcs({
                ...chartBmsFaultRlyMpCcs,
                data: bmsFaultRlyMpCcsatas,
            });

            setChartBmsFaultRlyPcCcs({
                ...chartBmsFaultRlyPcCcs,
                data: bmsFaultRlyPcCcsDatas,
            });

            setChartBmsFaultCrashNr({
                ...chartBmsFaultCrashNr,
                data: bmsFaultCrashNrDatas,
            });

            setChartBmsFaultCtempFltAll({
                ...chartBmsFaultCtempFltAll,
                data: bmsFaultCtempFltAllDatas,
            });

            setChartBmsFaultCellOpn({
                ...chartBmsFaultCellOpn,
                data: bmsFaultCellOpnDatas,
            });

            setChartBmsFaultCtempFlt({
                ...chartBmsFaultCtempFlt,
                data: bmsFaultCtempFltDatas,
            });

            setChartBmsFaultCvHFlt({
                ...chartBmsFaultCvHFlt,
                data: bmsFaultCvHFltDatas,
            });

            setChartBmsFaultCvLFlt({
                ...chartBmsFaultCvLFlt,
                data: bmsFaultCvLFltDatas,
            });

            setChartBmsFaultCvHWan({
                ...chartBmsFaultCvHWan,
                data: bmsFaultCvHWanDatas,
            });

            setChartBmsFaultCvLWan({
                ...chartBmsFaultCvLWan,
                data: bmsFaultCvLWanDatas,
            });

            setChartBmsFaultCvDevOcvFlt({
                ...chartBmsFaultCvDevOcvFlt,
                data: bmsFaultCvDevOcvFltDatas,
            });

            setChartBmsFaultCvDevFlt({
                ...chartBmsFaultCvDevFlt,
                data: bmsFaultCvDevFltDatas,
            });

            setChartBmsFaultPvHFlt({
                ...chartBmsFaultPvHFlt,
                data: bmsFaultPvHFltDatas,
            });

            setChartBmsFaultPvLFlt({
                ...chartBmsFaultPvLFlt,
                data: bmsFaultPvLFltDatas,
            });

            setChartBmsFaultPvHWan({
                ...chartBmsFaultPvHWan,
                data: bmsFaultPvHWanDatas,
            });

            setChartBmsFaultPvLWan({
                ...chartBmsFaultPvLWan,
                data: bmsFaultPvLWanDatas,
            });

            setChartBmsFaultCtHFlt({
                ...chartBmsFaultCtHFlt,
                data: bmsFaultCtHFltDatas,
            });

            setChartBmsFaultCtHWan({
                ...chartBmsFaultCtHWan,
                data: bmsFaultCtHWanDatas,
            });
            //#endregion

            //#region Mcu1
            setChartMcu1MotorTorqueEstimatedRs1({
                ...chartMcu1MotorTorqueEstimatedRs1,
                data: mcu1MotorTorqueEstimatedRs1Datas,
            });

            setChartMcu1MotorTorqueEstimated({
                ...chartMcu1MotorTorqueEstimated,
                data: mcu1MotorTorqueEstimatedDatas,
            });

            setChartMcu1MotorSpeed({
                ...chartMcu1MotorSpeed,
                data: mcu1MotorSpeedDatas,
            });

            setChartMcu1Counter({
                ...chartMcu1Counter,
                data: mcu1CounterDatas,
            });

            setChartMcu1Checksum({
                ...chartMcu1Checksum,
                data: mcu1ChecksumDatas,
            });
            //#endregion

            //#region Vcu1
            setChartVcu1MainContactorControl({
                ...chartVcu1MainContactorControl,
                data: vcu1MainContactorControlDatas,
            });

            setChartVcu1IsoltMtrStpReq({
                ...chartVcu1IsoltMtrStpReq,
                data: vcu1IsoltMtrStpReqDatas,
            });

            setChartVcu1Counter({
                ...chartVcu1Counter,
                data: vcu1CounterDatas,
            });

            setChartVcu1Checksum({
                ...chartVcu1Checksum,
                data: vcu1ChecksumDatas,
            });
            //#endregion

            //#region Vcu2
            setChartVcu2EstimatedEptCoolantFlow({
                ...chartVcu2EstimatedEptCoolantFlow,
                data: vcu2EstimatedEptCoolantFlowDatas,
            });

            setChartVcu2EptCoolantTemp({
                ...chartVcu2EptCoolantTemp,
                data: vcu2EptCoolantTempDatas,
            });

            setChartVcu2CommandMode({
                ...chartVcu2CommandMode,
                data: vcu2CommandModeDatas,
            });

            setChartVcu2ActvDischargeReq({
                ...chartVcu2ActvDischargeReq,
                data: vcu2ActvDischargeReqDatas,
            });

            setChartVcu2OperationModeCmd({
                ...chartVcu2OperationModeCmd,
                data: vcu2OperationModeCmdDatas,
            });

            setChartVcu2TorqueCommand({
                ...chartVcu2TorqueCommand,
                data: vcu2TorqueCommandDatas,
            });

            setChartVcu2Counter({
                ...chartVcu2Counter,
                data: vcu2CounterDatas,
            });

            setChartVcu2Checksum({
                ...chartVcu2Checksum,
                data: vcu2ChecksumDatas,
            });
            //#endregion
        }
    }, [runningCanDataList]);

    useEffect(() => {
        if (runningCanDataList === undefined || selectedDataPointIndex === undefined) return;

        if (vehicleInfo?.makerModel?.maker?.makerName === Common.VehicleMaker.EVSolution) {
            const canData = runningCanDataList[selectedDataPointIndex] as CanDataEVSolution.CanData;

            setCurrentCanData(canData);
        }
    }, [selectedDataPointIndex]);

    useEffect(() => {
        if (
            bms1ChartMounted === true ||
            bms2ChartMounted === true ||
            bms3ChartMounted === true ||
            bms4ChartMounted === true ||
            bms5ChartMounted === true ||
            bms6ChartMounted === true ||
            bmsFaultChartMounted === true ||
            mcu1ChartMounted === true ||
            vcu1ChartMounted === true ||
            vcu2ChartMounted === true
        ) {
            if (onMountedChart !== undefined) onMountedChart();
        }
    }, [
        bms1ChartMounted,
        bms2ChartMounted,
        bms3ChartMounted,
        bms4ChartMounted,
        bms5ChartMounted,
        bms6ChartMounted,
        bmsFaultChartMounted,
        mcu1ChartMounted,
        vcu1ChartMounted,
        vcu2ChartMounted,
    ]);

    const convertChartTooltip = (seriesName: string, value: number) => {
        let convertTooltipString = String(value);
        switch (seriesName) {
            case Common.EVSOLUTION_B1_DC_LINK_VOLT_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_VOLTAGE);
                break;
            }

            case Common.EVSOLUTION_B1_BAT_PACK_CURR_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_CURRENT);
                break;
            }

            case Common.EVSOLUTION_B1_MAIN_MINUS_CONTACTOR_COIL_STATE_NAME:
            case Common.EVSOLUTION_B1_MAIN_PLUS_CONTACTOR_COIL_STATE_NAME:
            case Common.EVSOLUTION_B1_PRE_CHARGE_CONTACTOR_COIL_STATE_NAME:
            case Common.EVSOLUTION_B1_IGN_STAT_NAME:
            case Common.EVSOLUTION_B1_MAIN_MINUS_CONTACTOR_STATE_NAME:
            case Common.EVSOLUTION_B1_MAIN_PLUS_CONTACTOR_STATE_NAME:
            case Common.EVSOLUTION_B1_PRE_CHARGE_CONTACTOR_STATE_NAME:
            case Common.EVSOLUTION_B2_WATER_PUMP_STATUS_NAME:
            case Common.EVSOLUTION_V1_MAIN_CONTACTOR_CONTROL_NAME: {
                convertTooltipString = convertRemarkContactorCoilState(value);
                break;
            }

            case Common.EVSOLUTION_B1_BAT_PACK_STATUS_NAME: {
                convertTooltipString = convertRemarkBatPackStatus(value);
                break;
            }

            case Common.EVSOLUTION_B1_OPERATION_MODE_NAME: {
                convertTooltipString = convertRemarkOperationMode(value);
                break;
            }

            case Common.EVSOLUTION_B1_HVIL_STATUS_NAME: {
                convertTooltipString = convertRemarkFault(value);
                break;
            }

            case Common.EVSOLUTION_B1_CONTACTOR_OFF_REQUEST_NAME:
            case Common.EVSOLUTION_B4_COOLING_REQUEST_NAME:
            case Common.EVSOLUTION_B4_CHARGING_STOP_NAME:
            case Common.EVSOLUTION_V2_ACTV_DISCHARGE_REQ_NAME: {
                convertTooltipString = convertRemarkRequest(value);
                break;
            }

            case Common.EVSOLUTION_B2_BMS_MODE_NAME: {
                convertTooltipString = convertRemarkBmsMode(value);
                break;
            }

            case Common.EVSOLUTION_B2_BALANCING_STATUS_NAME: {
                convertTooltipString = convertRemarkNoYes(value);
                break;
            }

            case Common.EVSOLUTION_B4_COOLING_PUMP_FEEDBACK_STATUS_NAME: {
                convertTooltipString = convertRemarkCoolingPumpFeedbackStatus(value);
                break;
            }

            case Common.EVSOLUTION_B4_BHC_STS_OW_TS_NAME: {
                convertTooltipString = convertRemarkBhcStsOwTs(value);
                break;
            }

            case Common.EVSOLUTION_B4_BHC_STS_ERR_NAME: {
                convertTooltipString = convertRemarkBhcStsErr(value);
                break;
            }

            case Common.EVSOLUTION_BF_INT_FLT_NAME:
            case Common.EVSOLUTION_BF_CMI_FLT_INIT_NAME:
            case Common.EVSOLUTION_BF_CMI_FLT_NAME:
            case Common.EVSOLUTION_BF_FET_FLT_NAME:
            case Common.EVSOLUTION_BF_RLY_MN_CS_NAME:
            case Common.EVSOLUTION_BF_RLY_MP_CS_NAME:
            case Common.EVSOLUTION_BF_I_TEMP_SH_NAME:
            case Common.EVSOLUTION_BF_I_TEMP_SL_NAME:
            case Common.EVSOLUTION_BF_ISO_FLT_NAME:
            case Common.EVSOLUTION_BF_CRASH_OPN_NAME:
            case Common.EVSOLUTION_BF_CRASH_HW_NAME:
            case Common.EVSOLUTION_BF_CRASH_CAN_NAME:
            case Common.EVSOLUTION_BF_VCU_LCM_NAME:
            case Common.EVSOLUTION_BF_CRT_SENS_WAN_NAME:
            case Common.EVSOLUTION_BF_CRT_SENS_DEV_NAME:
            case Common.EVSOLUTION_BF_ECAN_BUS_OFF_NAME:
            case Common.EVSOLUTION_BF_PCAN_BUS_OFF_NAME:
            case Common.EVSOLUTION_BF_HEATER_FLT_NAME:
            case Common.EVSOLUTION_BF_RLY_MN_COS_NAME:
            case Common.EVSOLUTION_BF_RLY_MP_COS_NAME:
            case Common.EVSOLUTION_BF_RLY_PC_COS_NAME:
            case Common.EVSOLUTION_BF_RLY_MN_CCS_NAME:
            case Common.EVSOLUTION_BF_RLY_MP_CCS_NAME:
            case Common.EVSOLUTION_BF_RLY_PC_CCS_NAME:
            case Common.EVSOLUTION_BF_CRASH_NR_NAME:
            case Common.EVSOLUTION_BF_C_TEMP_FLT_ALL_NAME:
            case Common.EVSOLUTION_BF_CELL_OPN_NAME:
            case Common.EVSOLUTION_BF_C_TEMP_FLT_NAME:
            case Common.EVSOLUTION_BF_CV_H_FLT_NAME:
            case Common.EVSOLUTION_BF_CV_L_FLT_NAME:
            case Common.EVSOLUTION_BF_CV_H_WAN_NAME:
            case Common.EVSOLUTION_BF_CV_L_WAN_NAME:
            case Common.EVSOLUTION_BF_CV_DEV_OCV_FLT_NAME:
            case Common.EVSOLUTION_BF_CV_DEV_FLT_NAME:
            case Common.EVSOLUTION_BF_PV_H_FLT_NAME:
            case Common.EVSOLUTION_BF_PV_L_FLT_NAME:
            case Common.EVSOLUTION_BF_PV_H_WAN_NAME:
            case Common.EVSOLUTION_BF_PV_L_WAN_NAME:
            case Common.EVSOLUTION_BF_CT_H_FLT_NAME:
            case Common.EVSOLUTION_BF_CT_H_WAN_NAME: {
                convertTooltipString = convertRemarkError(value);
                break;
            }

            case Common.EVSOLUTION_V1_ISOLT_MTR_STP_REQ_NAME: {
                convertTooltipString = convertRemarkMonitoring(value);
                break;
            }

            case Common.EVSOLUTION_V2_COMMAND_MODE_NAME: {
                convertTooltipString = convertRemarkTorqueMode(value);
                break;
            }

            case Common.EVSOLUTION_V2_OPERATION_MODE_CMD_NAME: {
                convertTooltipString = convertRemarkOperationalModeCmd(value);
                break;
            }

            default:
                break;
        }
        return convertTooltipString;
    };

    return (
        <div>
            <Space direction="vertical" size={20} style={{ width: "100%" }}>
                <div>
                    <CanDataWrapper
                        title="Bms1"
                        id="bms1"
                        panelOpen={bms1PanelOpen}
                        onChangePanelOpen={(panelOpened) => setBms1PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataBms1}
                        tableSelectRowKeys={selectedBms1}
                        onChangeTableSelect={onChangeBms1}
                        seriesChartDatas={seriesChartDatasBms1}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setBms1ChartMounted(true)}
                    />
                    <CanDataWrapper
                        title="Bms2"
                        id="bms2"
                        panelOpen={bms2PanelOpen}
                        onChangePanelOpen={(panelOpened) => setBms2PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataBms2}
                        tableSelectRowKeys={selectedBms2}
                        onChangeTableSelect={onChangeBms2}
                        seriesChartDatas={seriesChartDatasBms2}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setBms2ChartMounted(true)}
                    />
                    <CanDataWrapper
                        title="Bms3"
                        id="bms3"
                        panelOpen={bms3PanelOpen}
                        onChangePanelOpen={(panelOpened) => setBms3PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataBms3}
                        tableSelectRowKeys={selectedBms3}
                        onChangeTableSelect={onChangeBms3}
                        seriesChartDatas={seriesChartDatasBms3}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setBms3ChartMounted(true)}
                    />
                    <CanDataWrapper
                        title="Bms4"
                        id="bms4"
                        panelOpen={bms4PanelOpen}
                        onChangePanelOpen={(panelOpened) => setBms4PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataBms4}
                        tableSelectRowKeys={selectedBms4}
                        onChangeTableSelect={onChangeBms4}
                        seriesChartDatas={seriesChartDatasBms4}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setBms4ChartMounted(true)}
                    />
                    <CanDataWrapper
                        title="Bms5"
                        id="bms5"
                        panelOpen={bms5PanelOpen}
                        onChangePanelOpen={(panelOpened) => setBms5PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataBms5}
                        tableSelectRowKeys={selectedBms5}
                        onChangeTableSelect={onChangeBms5}
                        seriesChartDatas={seriesChartDatasBms5}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setBms5ChartMounted(true)}
                    />
                    <CanDataWrapper
                        title="Bms6"
                        id="bms6"
                        panelOpen={bms6PanelOpen}
                        onChangePanelOpen={(panelOpened) => setBms6PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataBms6}
                        tableSelectRowKeys={selectedBms6}
                        onChangeTableSelect={onChangeBms6}
                        seriesChartDatas={seriesChartDatasBms6}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setBms6ChartMounted(true)}
                    />
                    <CanDataWrapper
                        title="Bms Fault"
                        id="bmsFault"
                        panelOpen={bmsFaultPanelOpen}
                        onChangePanelOpen={(panelOpened) => setBmsFaultPanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataBmsFault}
                        tableSelectRowKeys={selectedBmsFault}
                        onChangeTableSelect={onChangeBmsFault}
                        seriesChartDatas={seriesChartDatasBmsFault}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setBmsFaultChartMounted(true)}
                    />
                    <CanDataWrapper
                        title="Mcu1"
                        id="mcu1"
                        panelOpen={mcu1PanelOpen}
                        onChangePanelOpen={(panelOpened) => setMcu1PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataMcu1}
                        tableSelectRowKeys={selectedMcu1}
                        onChangeTableSelect={onChangeMcu1}
                        seriesChartDatas={seriesChartDatasMcu1}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setMcu1ChartMounted(true)}
                    />
                    <CanDataWrapper
                        title="Vcu1"
                        id="vcu1"
                        panelOpen={vcu1PanelOpen}
                        onChangePanelOpen={(panelOpened) => setVcu1PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataVcu1}
                        tableSelectRowKeys={selectedVcu1}
                        onChangeTableSelect={onChangeVcu1}
                        seriesChartDatas={seriesChartDatasVcu1}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setVcu1ChartMounted(true)}
                    />
                    <CanDataWrapper
                        title="Vcu2"
                        id="vcu2"
                        panelOpen={vcu2PanelOpen}
                        onChangePanelOpen={(panelOpened) => setVcu2PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataVcu2}
                        tableSelectRowKeys={selectedVcu2}
                        onChangeTableSelect={onChangeVcu2}
                        seriesChartDatas={seriesChartDatasVcu2}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setVcu2ChartMounted(true)}
                    />
                </div>
            </Space>
        </div>
    );
}

export default CanDataEVSolutionViewer;
