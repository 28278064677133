import { useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table/interface";
import { LoadingOutlined } from "@ant-design/icons";
import { isMobile } from "react-device-detect";

import * as TypeUtils from "../../commons/typeUtils";

import Row from "antd/es/grid/row";
import Col from "antd/es/grid/col";
import Table from "antd/es/table/index";
import Spin from "antd/es/spin/index";
import Tooltip from "antd/es/tooltip/index";
import Space from "antd/es/space/index";
import Collapse from "antd/es/collapse/index";

import IconUp from "../../assets/images/icon/icon_up.png";
import CanDataChartJoinWrapper from "./CanDataChartJoinWrapper";
import styles from "./CanDataWrapper.module.css";

export type CanTableType = {
    key: React.Key;
    name: string;
    value: string;
};

function CanDataWrapper({
    title,
    id,
    panelOpen,
    onChangePanelOpen,
    dataLoading,
    tableDataSource,
    tableSelectRowKeys,
    onChangeTableSelect,
    tableGetTooltips,
    seriesChartDatas,
    convertChartTooltips,
    selectedChartDataPointIndex,
    onChangedSelectChartDataPointIndex,
    onMountedChart,
}: {
    title: string;
    id: string;
    panelOpen?: boolean;
    onChangePanelOpen?: (panelOpened: boolean) => void;
    dataLoading: boolean;
    tableDataSource: CanTableType[];
    tableSelectRowKeys: React.Key[];
    onChangeTableSelect: (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => void;
    tableGetTooltips?: (key: React.Key) => JSX.Element | null;
    seriesChartDatas: Array<TypeUtils.ChartData>;
    convertChartTooltips: (seriesName: string, value: number) => string;
    selectedChartDataPointIndex?: number;
    onChangedSelectChartDataPointIndex?: (dataPointIndex: number) => void;
    onMountedChart?: () => void;
}) {
    const { Panel } = Collapse;
    const columns: ColumnsType<CanTableType> = [
        {
            title: "항목",
            dataIndex: "name",
            // render: (_, { key, name }) => {
            //     return (
            //         <Tooltip placement="topLeft" title={tableGetTooltips(key)}>
            //             {name}
            //         </Tooltip>
            //     );
            // },
            width: isMobile ? "100%" : "50%",
        },
        { title: "값", dataIndex: "value", width: "40%" },
    ];

    const [activeKey, setActiveKey] = useState([""]);

    useEffect(() => {
        if (panelOpen === undefined) {
            setActiveKey([""]);
        } else if (panelOpen === true) {
            setActiveKey(["dataPanel"]);
        } else {
            setActiveKey([""]);
        }
    }, [panelOpen]);

    const onChange = (key: string | string[]) => {
        let panelOpened = false;
        if (key.includes("dataPanel")) {
            panelOpened = true;
        } else {
            panelOpened = false;
        }

        if (onChangePanelOpen !== undefined) onChangePanelOpen(panelOpened);
    };

    return (
        <div>
            <Collapse
                className={`fw-rg fs-md ${styles.wrapper}`}
                expandIcon={({ isActive }) => <img alt="" src={IconUp} width={24} style={{ transform: isActive ? "" : "rotate(180deg)" }} />}
                bordered={false}
                activeKey={activeKey}
                onChange={onChange}
            >
                <Panel
                    header={
                        <Space>
                            <p style={{ margin: 0 }} className="fw-bd fs-lg">
                                {title}
                            </p>
                            {dataLoading && <Spin indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />} />}
                        </Space>
                    }
                    key="dataPanel"
                >
                    <Row>
                        <Col xs={{ span: 24 }} lg={{ span: 5 }}>
                            <Table
                                className={styles.table}
                                pagination={false}
                                rowSelection={{ type: "checkbox", selectedRowKeys: tableSelectRowKeys, onChange: onChangeTableSelect }}
                                columns={isMobile ? columns.filter((column) => column.title !== "값") : columns}
                                dataSource={tableDataSource}
                                scroll={{ y: isMobile ? "200px" : "240px" }}
                            />
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 19 }}>
                            {tableSelectRowKeys.length > 0 ? (
                                <CanDataChartJoinWrapper
                                    loading={dataLoading}
                                    id={id}
                                    series={seriesChartDatas}
                                    convertTooltip={convertChartTooltips}
                                    selectedDataPointIndex={selectedChartDataPointIndex}
                                    onChangedSelectDataPointIndex={onChangedSelectChartDataPointIndex}
                                    onMountedChart={onMountedChart}
                                />
                            ) : null}
                        </Col>
                    </Row>
                </Panel>
            </Collapse>
        </div>
    );
}

export default CanDataWrapper;
