import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { DownOutlined, RedoOutlined, LoadingOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { actionSetDashboard } from "../../utils/action";
import type { ColumnsType } from "antd/es/table/interface";
import KakaoMap, { gpsType } from "../../components/common/KakaoMap";

import * as RequestCar from "../../utils/requestApiCar";
import * as Common from "../../commons/common";
import * as Strings from "../../commons/string";
import * as TypeDTO from "../../commons/typeDTO";
import * as Utils from "../../utils/utils";

import Badge from "antd/es/badge/index";
import Spin from "antd/es/spin/index";
import Collapse from "antd/es/collapse/Collapse";
import Modal from "antd/es/modal/index";
import Space from "antd/es/space/index";
import Select from "antd/es/select/index";
import Tag from "antd/es/tag/index";
import moment from "moment";

import DataTable from "../../components/common/DataTable";
import DashboardSummary from "../../components/browser/DashboardSummary";
import DashboardFilter from "../../components/browser/DashboardFilter";
import DashboardCarInfo from "../../components/browser/DashboardCarInfo";
import DashboardDrawer from "../../components/browser/DashboardDrawer";
import styles from "./Dashboard.module.css";

import iconCircleDrivingVehicle from "../../assets/images/icon/icon_circle_driving.png";
import iconCircleBatteryVehicle from "../../assets/images/icon/icon_circle_battery_vehicle.png";
import iconCircleErrorVehicle from "../../assets/images/icon/icon_circle_error_vehicle.png";
import iconCircleAlarm from "../../assets/images/icon/icon_circle_alarm.png";

import IconDriving from "../../assets/images/icon/icon_driving_dashboard.png";
import IconNormal from "../../assets/images/icon/icon_normal_dashboard.png";
import IconBatteryVeryLow from "../../assets/images/marker/circle_battery_very_low.png";
import IconBatteryLow from "../../assets/images/marker/circle_battery_low.png";
import IconError from "../../assets/images/icon/icon_red_exclamation.png";
import IconWarning from "../../assets/images/icon/icon_yellow_exclamation.png";

function Dashboard() {
    const navigate = useNavigate();
    const chargeEmergency = 20;
    const chargeEncourage = 40;
    const dispatch = useDispatch();
    const reducerDashboard = useSelector((state) => state.dashboard);
    const { Option } = Select;

    const [cars, setCars] = useState<Array<TypeDTO.CarDto>>();
    const [filteredCars, setFilteredCars] = useState<Array<TypeDTO.CarDto> | undefined>();
    const [selectedCarId, setSelectedCarId] = useState<number>();
    const [filters, setFilters] = useState<string[]>(["driving", "end", "error", "warning", "normal", "emergency", "encourage", "batteryNormal"]);
    const [center, setCenter] = useState<gpsType>();
    const [updateTime, setUpdateTime] = useState<string>();

    useEffect(() => {
        requestApiCarList(true, false, true);

        const filter = JSON.parse(String(Utils.getLocalStorage(Common.CONTEXT_FILTER)));
        filter && setFilters(filter);

        setCenter(Utils.getLocalStorage(Common.CONTEXT_CENTER) ? JSON.parse(String(Utils.getLocalStorage(Common.CONTEXT_CENTER))) : undefined);
    }, []);

    const { loadingApiCarList, requestApiCarList, resultApiCarList } = RequestCar.useRequestApiCarList();

    useEffect(() => {
        if (!resultApiCarList) return;
        setUpdateTime(moment().format(Common.FORMAT_DATE_TIME));
        setCars(resultApiCarList.cars);
    }, [resultApiCarList]);

    const summaries = [
        {
            title: "운행 중 차량",
            firstText: Number(
                cars?.filter(
                    (car) =>
                        car?.runningSummary?.lastRunningHistory?.finish === false &&
                        moment(car?.runningSummary?.lastRunningHistory?.endTime).isAfter(moment().subtract(10, "m"))
                )?.length
            ),
            lastText: Number(cars?.length),
            unit: "대",
            icon: {
                first: <img alt="" width={20} height={20} src={IconDriving} />,
                second: <img alt="" width={20} height={20} src={IconNormal} />,
            },
            box: <img alt="" width={60} height={60} src={iconCircleDrivingVehicle} />,
        },
        {
            title: "충전 필요 차량",
            firstText: Number(cars?.filter((car) => car?.runningSummary?.lastRunningHistory?.endSoc <= chargeEmergency)?.length),
            lastText: Number(
                cars?.filter(
                    (car) =>
                        car?.runningSummary?.lastRunningHistory?.endSoc > chargeEmergency &&
                        car?.runningSummary?.lastRunningHistory?.endSoc <= chargeEncourage
                )?.length
            ),
            unit: "대",
            icon: {
                first: <img alt="" width={20} height={20} src={IconBatteryVeryLow} />,
                second: <img alt="" width={20} height={20} src={IconBatteryLow} />,
            },
            box: <img alt="" width={60} height={60} src={iconCircleBatteryVehicle} />,
        },
        {
            title: "금일 알람 발생 차량",
            firstText: Number(cars?.filter((car) => car?.alarmCountByCar?.errorCount)?.length),
            lastText: Number(cars?.filter((car) => car?.alarmCountByCar?.warningCount)?.length),
            unit: "대",
            icon: {
                first: <img alt="" width={20} height={20} src={IconError} />,
                second: <img alt="" width={20} height={20} src={IconWarning} />,
            },
            box: <img alt="" width={60} height={60} src={iconCircleErrorVehicle} />,
        },
        {
            title: "금일 발생 알람",
            firstText: Number(cars?.reduce((counts, car) => (counts += car?.alarmCountByCar?.errorCount ?? 0), 0)),
            lastText: Number(cars?.reduce((counts, car) => (counts += car?.alarmCountByCar?.warningCount ?? 0), 0)),
            unit: "건",
            icon: {
                first: <img alt="" width={20} height={20} src={IconError} />,
                second: <img alt="" width={20} height={20} src={IconWarning} />,
            },
            box: <img alt="" width={60} height={60} src={iconCircleAlarm} />,
        },
    ];

    useEffect(() => {
        if (Object.keys(reducerDashboard).length === 0) return;

        setSelectedCarId(reducerDashboard.selectedId);
    }, [reducerDashboard]);

    const dispatchDashboard = () => {
        dispatch(
            actionSetDashboard({
                selectedId: selectedCarId,
            })
        );
    };

    useEffect(() => {
        setFilteredCars(cars?.filter((car) => applyFilters(car, (value) => filters?.includes(value))));
    }, [filters, cars]);

    const applyFilters = (car: TypeDTO.CarDto, checkFilter: (value: string) => boolean, carId?: number) => {
        const history = car?.runningSummary?.lastRunningHistory;
        const errorCnt = car?.alarmCountByCar?.errorCount;
        const warningCnt = car?.alarmCountByCar?.warningCount;

        const isDriving =
            checkFilter("driving") && !history?.finish && !moment(history?.endTime, Common.FORMAT_DATE_TIME).isBefore(moment().subtract(10, "m"));
        const isEnd =
            checkFilter("end") &&
            (history?.finish || (!history?.finish && moment(history?.endTime, Common.FORMAT_DATE_TIME).isBefore(moment().subtract(10, "m"))));

        const isError = checkFilter("error") && errorCnt > 0;
        const isWarning = checkFilter("warning") && warningCnt > 0;
        const isNormal = checkFilter("normal") && !errorCnt && !warningCnt;

        const isEmergency = checkFilter("emergency") && (history?.endSoc === 0 || history?.endSoc <= chargeEmergency);
        const isEncourage = checkFilter("encourage") && history?.endSoc > 20 && history?.endSoc <= chargeEncourage;
        const isBatteryNormal = checkFilter("batteryNormal") && (!history?.endSoc || history?.endSoc > chargeEncourage);

        return (isDriving || isEnd) && (isError || isWarning || isNormal) && (isEmergency || isEncourage || isBatteryNormal);
    };

    const onChangeFilter = (values: string[]) => {
        Utils.setLocalStorage(Common.CONTEXT_FILTER, JSON.stringify(values));
        setFilters(values);
    };

    const carInfo = () => {
        const car = cars?.find((car) => car?.carId === selectedCarId);
        return (
            car && (
                <Collapse
                    className={styles.carInfoOverlay}
                    defaultActiveKey={1}
                    expandIcon={({ isActive }) => <DownOutlined style={{ fontSize: 16, color: "var(--font3)" }} rotate={isActive ? -90 : 0} />}
                    items={[
                        {
                            key: 1,
                            label: (
                                <div className={styles.carInfoSpace}>
                                    <Space size={8}>
                                        <span className="fs-lg fw-bd">{car.carNumber}</span>
                                        <Space
                                            size={0}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                navigate(Common.PAGE_ALARM + "/" + car.carNumber);
                                            }}
                                        >
                                            {car?.alarmCountByCar?.errorCount ? (
                                                <Tag className="errorTag">{car?.alarmCountByCar?.errorCount}</Tag>
                                            ) : (
                                                ""
                                            )}
                                            {car?.alarmCountByCar?.warningCount ? (
                                                <Tag className="warningTag">{car?.alarmCountByCar?.warningCount}</Tag>
                                            ) : (
                                                ""
                                            )}
                                        </Space>
                                    </Space>
                                    <span
                                        className="fs-md fw-bd fc-font3 btn-text"
                                        onClick={() => {
                                            dispatchDashboard();
                                            navigate(Common.PAGE_MANAGE_CAR_DETAIL + "/" + car.carId);
                                        }}
                                    >
                                        + 더보기
                                    </span>
                                </div>
                            ),
                            children: <DashboardCarInfo car={car} />,
                        },
                    ]}
                />
            )
        );
    };

    const socColumns: ColumnsType<TypeDTO.CarDto> = [
        {
            title: Strings.carNumber,
            dataIndex: "carNumber",
            key: "carNumber",
            width: "10%",
            align: "center",
        },
        {
            title: Strings.soc,
            dataIndex: ["runningSummary", "lastRunningHistory", "endSoc"],
            key: "endSoc",
            width: "10%",
            align: "center",
            render: (_, { runningSummary }) =>
                runningSummary?.lastRunningHistory?.endSoc <= chargeEncourage ? (
                    <Badge
                        style={{ right: "-4px" }}
                        dot
                        color={runningSummary?.lastRunningHistory?.endSoc <= chargeEmergency ? "var(--danger)" : "var(--warning)"}
                    >
                        <span className="fw-rg fs-md">{runningSummary?.lastRunningHistory?.endSoc.toFixed(1)}</span>
                    </Badge>
                ) : (
                    <span className="fw-rg fs-md">{runningSummary?.lastRunningHistory?.endSoc.toFixed(1)}</span>
                ),
        },
    ];

    const alarmColumns: ColumnsType<TypeDTO.CarDto> = [
        {
            title: Strings.carNumber,
            dataIndex: "carNumber",
            key: "carNumber",
            width: "10%",
            align: "center",
        },
        {
            title: Strings.alarmType,
            dataIndex: "alarmCountByCar",
            key: "alarmCountByCar",
            width: "10%",
            align: "center",
            render: (_, { alarmCountByCar }) => (
                <Space size={0}>
                    {alarmCountByCar.errorCount ? <Tag className="errorTag">{alarmCountByCar.errorCount}</Tag> : ""}
                    {alarmCountByCar.warningCount ? <Tag className="warningTag">{alarmCountByCar.warningCount}</Tag> : ""}
                </Space>
            ),
        },
    ];

    const onClickSummary = (summary: string) => {
        Modal.info({
            title: summary + " " + Strings.view,
            content: (
                <DataTable
                    rowKey="carNumber"
                    disabledTitle
                    disabledPagination
                    scroll="50vh"
                    columns={summary.includes("알람") ? alarmColumns : socColumns}
                    dataSource={cars?.filter((car) =>
                        summary.includes("운행")
                            ? car?.runningSummary?.lastRunningHistory?.finish === false &&
                              moment(car?.runningSummary?.lastRunningHistory?.endTime).isAfter(moment().subtract(10, "m"))
                            : summary.includes("충전")
                            ? car?.runningSummary?.lastRunningHistory?.endSoc <= chargeEncourage
                            : summary.includes("알람")
                            ? car?.alarmCountByCar !== null
                            : car
                    )}
                />
            ),
            okText: Strings.confirm,
            onOk() {},
            centered: true,
        });
    };

    return (
        <Space direction="vertical" size={24} className={styles.dashboardWrapper}>
            <DashboardSummary
                summaries={summaries}
                onClick={(summary) => {
                    onClickSummary(summary);
                }}
            />
            <div className={styles.mapWrapper}>
                <div className={styles.carSelectorOverlay}>
                    <div className={styles.carSelectorWrapper}>
                        <Select
                            className={styles.carSelector}
                            allowClear
                            showSearch
                            placeholder="차량 번호를 입력해 주세요."
                            value={selectedCarId}
                            optionFilterProp="children"
                            onChange={(id) => {
                                setSelectedCarId(id);

                                const history = cars?.find((car) => car.carId === id)?.runningSummary?.lastRunningHistory;
                                history && setCenter({ lat: history?.latitude, lng: history?.longitude });
                            }}
                        >
                            {cars
                                ?.slice()
                                .sort((a, b) => a?.carNumber?.localeCompare(b?.carNumber))
                                ?.map((car) => (
                                    <Option key={car.carId} value={car.carId}>
                                        {car.carNumber}
                                    </Option>
                                ))}
                        </Select>
                    </div>
                    {carInfo()}
                </div>
                <div className={styles.refreshBtnWrapper} onClick={() => requestApiCarList(true, false, true)}>
                    <span className="fs-xs">업데이트: {updateTime ? updateTime : <Spin indicator={<LoadingOutlined spin />} size="small" />}</span>
                    {updateTime && <RedoOutlined className={loadingApiCarList ? styles.loadingRefreshIcon : styles.refreshIcon} />}
                </div>
                <DashboardFilter filters={filters} onChange={(values) => onChangeFilter(values)} />
                <DashboardDrawer
                    drivingCount={Number(
                        cars?.filter(
                            (car) =>
                                car?.runningSummary?.lastRunningHistory?.finish === false &&
                                moment(car?.runningSummary?.lastRunningHistory?.endTime).isAfter(moment().subtract(10, "m"))
                        )?.length
                    )}
                />
                <KakaoMap
                    className="dashboardMap"
                    data-testid="map"
                    mapStyle={{ width: "100%", height: "calc(100vh - 169px)" }}
                    mapCenter={center}
                    mapZoom={Utils.getLocalStorage(Common.CONTEXT_ZOOM) ? Number(Utils.getLocalStorage(Common.CONTEXT_ZOOM)) : undefined}
                    cars={JSON.parse(String(Utils.getLocalStorage(Common.CONTEXT_FILTER))) ? filteredCars : cars}
                    selectedCarId={selectedCarId}
                    onClick={(id) => setSelectedCarId(id)}
                    onChange={(value) => Utils.setLocalStorage(Common.CONTEXT_CENTER, JSON.stringify(value))}
                    onZoom={(value) => Utils.setLocalStorage(Common.CONTEXT_ZOOM, String(value))}
                />
            </div>
        </Space>
    );
}

export default Dashboard;
