import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { AlertType, useAlert } from "../../provider/AlertProvider";
import { useAuthState } from "../../provider/AuthProvider";
import { TableProps } from "antd/es/table/InternalTable";
import { useSelector, useDispatch } from "react-redux";
import { actionSetCars } from "../../utils/action";

import type { ColumnsType, SorterResult } from "antd/es/table/interface";

import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";
import * as Common from "../../commons/common";
import * as String from "../../commons/string";
import * as Utils from "../../utils/utils";
import * as RequestCar from "../../utils/requestApiCar";

import Badge from "antd/es/badge/index";
import Space from "antd/es/space/index";
import Tag from "antd/es/tag/index";
import moment from "moment";
import DataTable from "../../components/common/DataTable";
import CarSelector from "../../components/mobile/CarSelector";
import Filter from "../../components/mobile/Filter";

type SearchType = {
    makerName: string;
    categoryName: string;
    idNumber: string;
    customerName: string;
    customerManagerName: string;
};

const PageCars = () => {
    const alert = useAlert();
    const navigate = useNavigate();
    const userDetails = useAuthState();
    const dispatch = useDispatch();
    const reducerCars = useSelector((state) => state.cars);

    const [cars, setCars] = useState<Array<TypeDTO.CarDto>>();
    const [selectedCarId, setSelectedCarId] = useState<number>();
    const [selectedCarNumber, setSelectedCarNumber] = useState<string>();
    const [selectedManagerName, setSelectedManagerName] = useState<string>();
    const [filteredCars, setFilteredCars] = useState<Array<TypeDTO.CarDto>>();
    const [sortedInfo, setSortedInfo] = useState<SorterResult<TypeDTO.RunningHistoryDto>>({});
    const [searchValue, setSearchValue] = useState<SearchType>();

    const filterData: TypeUtils.SearchItemType[] = [
        {
            id: "makerName",
            title: String.maker,
            type: "select",
            typeDetail: "maker",
        },
        {
            id: "categoryName",
            title: String.category,
            type: "select",
            typeDetail: "category",
        },
        {
            id: "idNumber",
            title: String.identityNumber,
            type: "select",
            typeDetail: "idNumber",
        },
        {
            id: "customerManagerName",
            title: String.customerManager,
            type: "select",
            typeDetail: "customerManager",
        },
        {
            id: "customerName",
            title: String.customer,
            type: "select",
            typeDetail: "customer",
        },
    ];

    const [filter, setFilter] = useState<TypeUtils.SearchItemType[]>();

    const columns: ColumnsType<TypeDTO.CarDto> = [
        {
            title: String.carNumber,
            dataIndex: "carNumber",
            key: "carNumber",
            align: "center",
        },
        {
            title: "마지막 운행 시각",
            dataIndex: ["runningSummary", "lastRunningHistory", "endTime"],
            key: "runningEndTime",
            sorter: (a, b, sortOrder) =>
                Utils.sortDate(a.runningSummary?.lastRunningHistory?.endTime, b.runningSummary?.lastRunningHistory?.endTime, sortOrder),
            sortOrder: sortedInfo.columnKey === "runningEndTime" ? sortedInfo.order : null,
            render: (_, { runningSummary }) => {
                const endTime = runningSummary?.lastRunningHistory?.endTime;
                if (endTime) {
                    if (runningSummary?.lastRunningHistory?.finish === true) {
                        return <span className="fs-md">{endTime ? moment(endTime).format(Common.FORMAT_MOBILE_DATE_TIME) : String.dash}</span>;
                    } else {
                        if (moment(endTime).isSameOrAfter(moment().subtract(10, "m"))) {
                            return <Tag className="pointTag">운행중</Tag>;
                        } else {
                            if (Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN])) {
                                return (
                                    <Badge style={{ right: "-4px" }} dot color="var(--danger)">
                                        <span className="fc-point fs-md">
                                            {endTime ? moment(endTime).format(Common.FORMAT_MOBILE_DATE_TIME) : String.dash}
                                        </span>
                                    </Badge>
                                );
                            } else {
                                return (
                                    <span className="fs-md">{endTime ? moment(endTime).format(Common.FORMAT_MOBILE_DATE_TIME) : String.dash}</span>
                                );
                            }
                        }
                    }
                } else {
                    return <span className="fs-md">{String.dash}</span>;
                }
            },
        },
    ];

    const onTableChange: TableProps<TypeDTO.RunningHistoryDto>["onChange"] = (pagination, filters, sorter) => {
        setSortedInfo(sorter as SorterResult<TypeDTO.RunningHistoryDto>);
    };

    useEffect(() => {
        requestApiCarList(true);
    }, []);

    useEffect(() => {
        searchValue && onSearchData(searchValue);

        setTimeout(() => {
            document.querySelector(".ant-table-body").scrollTo({ top: reducerCars.scrollPosition });
        }, 100);
    }, [cars]);

    useEffect(() => {
        const searchFilter = Utils.searchFilter;
        setSearchValue(searchValue);
        setFilteredCars(
            cars?.filter((car) => {
                if (selectedCarNumber) {
                    if (searchFilter(car, selectedCarNumber, "carNumber")) {
                        if (
                            searchFilter(car, searchValue?.idNumber, "idNumber") &&
                            searchFilter(car?.makerModel?.maker, searchValue?.makerName, "makerName") &&
                            searchFilter(car?.category, searchValue?.categoryName, "categoryName") &&
                            searchFilter(car?.customer?.customerManager, searchValue?.customerManagerName, "customerManagerName") &&
                            searchFilter(car?.customer, searchValue?.customerName, "customerName")
                        ) {
                            return true;
                        }
                    }
                } else if (
                    searchFilter(car, searchValue?.idNumber, "idNumber") &&
                    searchFilter(car?.makerModel?.maker, searchValue?.makerName, "makerName") &&
                    searchFilter(car?.category, searchValue?.categoryName, "categoryName") &&
                    searchFilter(car?.customer?.customerManager, searchValue?.customerManagerName, "customerManagerName") &&
                    searchFilter(car?.customer, searchValue?.customerName, "customerName")
                ) {
                    return true;
                }
                return false;
            })
        );
    }, [selectedCarNumber]);

    useEffect(() => {
        if (Object.keys(reducerCars).length > 0) {
            setSelectedCarId(reducerCars?.selectedCarId);

            setFilter(
                filterData?.map((item) => ({
                    ...item,
                    defaultValue: reducerCars[item.id as keyof typeof reducerCars] ?? item.defaultValue,
                }))
            );
        } else {
            setFilter(filterData);
        }
    }, [reducerCars]);

    const dispatchCars = (selectedId: number) => {
        dispatch(
            actionSetCars({
                ...searchValue,
                selectedCarId: selectedCarId,
                selectedId: selectedId,
                scrollPosition: document.querySelector(".ant-table-body").scrollTop,
            })
        );
    };

    const onSearchData = (value: SearchType) => {
        const searchFilter = Utils.searchFilter;
        const searchValue = value;
        setSelectedManagerName(value?.customerManagerName);
        setFilteredCars(
            cars?.filter((car) => {
                if (selectedCarNumber) {
                    if (searchFilter(car, selectedCarNumber, "carNumber")) {
                        if (
                            searchFilter(car, value?.idNumber, "idNumber") &&
                            searchFilter(car?.makerModel?.maker, value?.makerName, "makerName") &&
                            searchFilter(car?.category, value?.categoryName, "categoryName") &&
                            searchFilter(car?.customer?.customerManager, value?.customerManagerName, "customerManagerName") &&
                            searchFilter(car?.customer, value?.customerName, "customerName")
                        ) {
                            return true;
                        }
                    }
                } else if (
                    searchFilter(car, value?.idNumber, "idNumber") &&
                    searchFilter(car?.makerModel?.maker, value?.makerName, "makerName") &&
                    searchFilter(car?.category, value?.categoryName, "categoryName") &&
                    searchFilter(car?.customer?.customerManager, value?.customerManagerName, "customerManagerName") &&
                    searchFilter(car?.customer, value?.customerName, "customerName")
                ) {
                    return true;
                }
                return false;
            })
        );
        setSearchValue(searchValue);
    };

    const { loadingApiCarList, requestApiCarList, resultApiCarList } = RequestCar.useRequestApiCarList();

    useEffect(() => {
        if (!resultApiCarList) return;

        setCars(resultApiCarList.cars);
        setFilteredCars(resultApiCarList.cars);
    }, [resultApiCarList]);

    return (
        <div className="pageWrapper">
            <CarSelector
                selectedCarId={selectedCarId}
                onSelect={(id?: number) => {
                    setSelectedCarId(id);
                }}
                onSelectCarNumber={(carNumber?: string) => {
                    setSelectedCarNumber(carNumber);
                }}
            />
            <Space direction="vertical">
                <Filter
                    dataLength={filteredCars?.length}
                    values={filter}
                    selectedName={selectedManagerName}
                    onSearch={(value) => onSearchData(value)}
                />
                <DataTable
                    rowKey={(row: TypeDTO.CarDto) => row.carId}
                    disabledTitle
                    disabledPagination
                    isMobile
                    scroll="calc(var(--vh, 1vh) * 100 - 292px)"
                    loading={loadingApiCarList}
                    columns={
                        Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN])
                            ? columns
                            : columns.filter((column) => column.key !== "detail")
                    }
                    rowClassName={(car: TypeDTO.CarDto) => {
                        return car?.carId === reducerCars?.selectedId ? "table-row-selected" : "";
                    }}
                    dataSource={filteredCars}
                    onRow={(car: TypeDTO.CarDto) => {
                        dispatchCars(car.carId);

                        localStorage.setItem("carDetailTab", "기본 정보");
                        navigate(Common.PAGE_MANAGE_CAR_DETAIL + "/" + car.carId);
                    }}
                    onChange={onTableChange}
                />
            </Space>
        </div>
    );
};

export default PageCars;
