import { useEffect, useState } from "react";
import Input from "antd/es/input/index";
import Form from "antd/es/form/index";
import Select from "antd/es/select/index";
import Space from "antd/es/space/index";
import CustomForm, { viewType } from "./CustomForm";
import { AlertType, useAlert } from "../../provider/AlertProvider";
import { useLocation, useNavigate } from "react-router";
import { useAuthState } from "../../provider/AuthProvider";
import type { ColumnsType } from "antd/es/table/interface";

import * as RequestCar from "../../utils/requestApiCar";
import * as RequestMaker from "../../utils/requestApiMaker";
import * as RequestCategory from "../../utils/requestApiCategory";
import * as RequestCustomer from "../../utils/requestAuthCustomer";
import * as RequestSwVer from "../../utils/requestApiSwVer";

import * as Common from "../../commons/common";
import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";
import * as String from "../../commons/string";
import * as Utils from "../../utils/utils";
import DataTable from "../common/DataTable";

const TabCarInfo = ({ carId, onChange }: { carId?: number; onChange?: (viewType: boolean) => void }) => {
    const userDetails = useAuthState();
    const alert = useAlert();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { Option } = Select;
    const [form] = Form.useForm();
    const [batteryForm] = Form.useForm();

    const [type, setType] = useState<viewType>("view");
    const [car, setCar] = useState<TypeDTO.CarDto>();
    const [makers, setMakers] = useState<Array<TypeDTO.MakerDto>>();
    const [categories, setCategories] = useState<Array<TypeDTO.CategoryDto>>();
    const [customers, setCustomers] = useState<Array<TypeDTO.CustomerDto>>();
    const [swVers, setSwVers] = useState<Array<TypeDTO.SwVerDto>>();
    const [selectedMakerId, setSelectedMakerId] = useState<number>();
    const [selectedModelId, setSelectedModelId] = useState<number>();

    const tsEcoMakerName = "티에스에코에너지";
    const batteryPack = {
        pack1: "1123090147",
        pack2: "1123090296",
        pack3: "1123090267",
        pack4: "1123090240",
        pack5: "1123090724",
        pack6: "1123090175",
        pack7: "1123090146",
        pack8: "1123090656",
    };

    useEffect(() => {
        requestApiMakerList();
        requestAuthCustomerList();
        requestApiSwVerList();
        requestApiCategoryList();

        if (pathname.includes(Common.PAGE_MANAGE_CAR_DETAIL)) {
            setType("view");
        } else if (pathname.includes(Common.PAGE_MANAGE_CAR_REGISTER)) {
            setType("register");
        } else {
            setType("edit");
        }
    }, []);

    useEffect(() => {
        onChange && onChange(type === "view");
    }, [type]);

    useEffect(() => {
        carId && requestApiCarInfo(carId);
    }, [carId]);

    const onChangeIdNumber = (event: React.FormEvent<HTMLInputElement>) => {
        (event.target as HTMLInputElement).value = (event.target as HTMLInputElement).value
            .toUpperCase()
            .replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g, "")
            .replace(/[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/g, "");
    };

    const columns: ColumnsType<TypeDTO.CarNumberHistoryDto> = [
        {
            title: String.carNumber,
            dataIndex: "carNumber",
            key: "carNumber",
            width: "20%",
            align: "center",
            ellipsis: true,
        },
        {
            title: String.customerName,
            dataIndex: ["customer", "customerName"],
            key: "customerName",
            width: "20%",
            align: "center",
            ellipsis: true,
            render: (_, { customer }) => customer?.customerName || String.dash,
        },
        {
            title: String.changeTime,
            dataIndex: "historyTime",
            key: "historyTime",
            width: "25%",
            align: "center",
            ellipsis: true,
            render: (_, { historyTime }) => Utils.utcToLocalTime(historyTime, Common.FORMAT_DATE_TIME),
        },
    ];

    const contents: Array<TypeUtils.formType> = [
        { name: "carNumber", label: String.carNumber, span: 12, required: true },
        {
            name: "idNumber",
            label: String.identityNumber,
            span: 12,
            required: true,
            input: (
                <Input
                    maxLength={17}
                    onInput={onChangeIdNumber}
                    className={type === "view" ? "disabled-input" : ""}
                    disabled={type === "view"}
                />
            ),
        },
        {
            name: ["customer", "customerId"],
            label: String.customer,
            span: 6,
            input: (
                <Select
                    allowClear
                    showSearch
                    placeholder="고객을 선택해 주세요."
                    optionFilterProp="children"
                    onChange={(value) =>
                        form.setFieldValue(
                            ["customer", "customerPhone"],
                            customers?.find((customer) => customer.customerId === value)?.customerPhone
                        )
                    }
                    className={type === "view" ? "disabled-selector" : ""}
                    disabled={type === "view"}
                >
                    {customers?.map((customer) => (
                        <Option key={customer.customerId} value={customer.customerId}>
                            {customer.customerName}
                        </Option>
                    ))}
                </Select>
            ),
        },
        {
            name: ["customer", "customerPhone"],
            label: String.phone,
            span: 6,
            disabled: true,
        },
        {
            name: ["makerModel", "maker", "makerId"],
            label: String.maker,
            span: 6,
            input: (
                <Select
                    allowClear
                    showSearch
                    placeholder="제조사를 선택해 주세요."
                    onChange={(value) => {
                        setSelectedMakerId(value);
                        !value && setSelectedModelId(undefined);
                        form.setFieldValue(["makerModel", "makerModelId"], undefined);
                        form.setFieldValue(["carSwVer"], undefined);
                    }}
                    className={type === "view" ? "disabled-selector" : ""}
                    disabled={type === "view"}
                    optionFilterProp="children"
                >
                    {makers?.map((maker) => (
                        <Option key={maker.makerId} value={maker.makerId}>
                            {maker.makerName}
                        </Option>
                    ))}
                </Select>
            ),
            required: true,
        },
        {
            name: ["makerModel", "makerModelId"],
            label: String.carModel,
            span: 6,
            required: true,
            input: (
                <Select
                    allowClear
                    showSearch
                    placeholder="차량모델을 선택해 주세요."
                    optionFilterProp="children"
                    onChange={(value) => {
                        setSelectedModelId(value);
                        form.setFieldValue(["carSwVer"], undefined);
                    }}
                    className={type === "view" ? "disabled-selector" : ""}
                    disabled={type === "view" || !selectedMakerId}
                >
                    {makers
                        ?.find((maker) => maker.makerId === selectedMakerId)
                        ?.makerModels?.map((model) => (
                            <Option key={model.makerModelId} value={model.makerModelId}>
                                {model.makerModelName}
                            </Option>
                        ))}
                </Select>
            ),
        },
        {
            name: ["category", "categoryId"],
            label: String.type,
            span: 12,
            required: true,
            input: (
                <Select
                    allowClear
                    showSearch
                    placeholder="차량분류를 선택해 주세요."
                    className={type === "view" ? "disabled-selector" : ""}
                    disabled={type === "view"}
                    optionFilterProp="children"
                >
                    {categories?.map((category) => (
                        <Option key={category.categoryId} value={category.categoryId}>
                            {category.categoryName}
                        </Option>
                    ))}
                </Select>
            ),
        },
        {
            name: ["carSwVer", "bmsVer", "swVerId"],
            label: String.bmsVersion,
            span: 6,
            input: (
                <Select
                    allowClear
                    showSearch
                    placeholder="버전을 선택해 주세요."
                    onSelect={(value) => setSelectedMakerId(value)}
                    className={type === "view" ? "disabled-selector" : ""}
                    disabled={type === "view" || !selectedModelId}
                    optionFilterProp="children"
                >
                    {swVers
                        ?.filter((ver) => ver?.makerModel?.makerModelId === selectedModelId && ver.swType === "BMS")
                        ?.map((ver) => (
                            <Option key={ver.swVerId} value={ver.swVerId}>
                                {ver.swVer}
                            </Option>
                        ))}
                </Select>
            ),
        },
        {
            name: ["carSwVer", "vcuVer", "swVerId"],
            label: String.vcuVersion,
            span: 6,
            input: (
                <Select
                    allowClear
                    showSearch
                    placeholder="버전을 선택해 주세요."
                    onSelect={(value) => setSelectedMakerId(value)}
                    className={type === "view" ? "disabled-selector" : ""}
                    disabled={type === "view" || !selectedModelId}
                    optionFilterProp="children"
                >
                    {swVers
                        ?.filter((ver) => ver?.makerModel?.makerModelId === selectedModelId && ver.swType === "VCU")
                        ?.map((ver) => (
                            <Option key={ver.swVerId} value={ver.swVerId}>
                                {ver.swVer}
                            </Option>
                        ))}
                </Select>
            ),
        },
        {
            name: ["runningSummary", "totalDrivingDistance"],
            label: String.totalRunningDistance,
            span: 12,
            disabled: type !== "view",
        },
        {
            name: "description",
            label: String.description,
            span: 12,
        },
    ];

    const batteryContents: Array<TypeUtils.formType> = [
        { name: "pack1", label: "1번", span: 6 },
        { name: "pack2", label: "2번", span: 6 },
        { name: "pack3", label: "3번", span: 6 },
        { name: "pack4", label: "4번", span: 6 },
        { name: "pack5", label: "5번", span: 6 },
        { name: "pack6", label: "6번", span: 6 },
        { name: "pack7", label: "7번", span: 6 },
        { name: "pack8", label: "8번", span: 6 },
    ];

    const { requestApiCarInfo, resultApiCarInfo } = RequestCar.useRequestApiCarInfo();

    useEffect(() => {
        if (!resultApiCarInfo) return;

        const car = resultApiCarInfo.car;
        const summary = car?.runningSummary;
        const totalDrivingDistance =
            ((summary?.totalMileage || summary?.totalDrivingDistance)?.toFixed(1) || String.dash)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                .replace(/\.?0+$/, "") + String.kmDistanceUnit.replace("(", "").replace(")", "");

        form.setFieldsValue({
            ...car,
            customer: {
                ...car.customer,
                customerPhone: Utils.convertPhone(car?.customer?.customerPhone),
            },
            runningSummary: {
                ...summary,
                totalDrivingDistance,
            },
        });

        setSelectedMakerId(car?.makerModel?.maker?.makerId);
        setSelectedModelId(car?.makerModel?.makerModelId);
        setCar(car);
    }, [resultApiCarInfo]);

    const { requestApiCarRegister, resultApiCarRegister } = RequestCar.useRequestApiCarRegister();

    useEffect(() => {
        if (!resultApiCarRegister) return;

        onResult("register", resultApiCarRegister.car?.carId);
    }, [resultApiCarRegister]);

    const { requestApiCarUpdate, resultApiCarUpdate } = RequestCar.useRequestApiCarUpdate();

    useEffect(() => {
        if (!resultApiCarUpdate) return;

        onResult("edit");
    }, [resultApiCarUpdate]);

    const { requestApiMakerList, resultApiMakerList } = RequestMaker.useRequestApiMakerList();

    useEffect(() => {
        if (!resultApiMakerList) return;

        setMakers(resultApiMakerList.makers);
    }, [resultApiMakerList]);

    const { requestApiCategoryList, resultApiCategoryList } = RequestCategory.useRequestApiCategoryList();

    useEffect(() => {
        if (!resultApiCategoryList) return;

        setCategories(resultApiCategoryList.categories);
    }, [resultApiCategoryList]);

    const { requestAuthCustomerList, resultAuthCustomerList } = RequestCustomer.useRequestAuthCustomerList();

    useEffect(() => {
        if (!resultAuthCustomerList) return;

        setCustomers(resultAuthCustomerList.customers);
    }, [resultAuthCustomerList]);

    const { requestApiSwVerList, resultApiSwVerList } = RequestSwVer.useRequestApiSwVerList();

    useEffect(() => {
        if (!resultApiSwVerList) return;

        setSwVers(resultApiSwVerList.swVers);
    }, [resultApiSwVerList]);

    const onFinish = (type: viewType, value: any) => {
        const searchValue = {
            ...value,
            category: value?.category?.categoryId ? { categoryId: value.category.categoryId } : null,
            customer: value?.customer?.customerId
                ? { customerId: value.customer.customerId, customerPhone: value?.customer?.customerPhone?.replaceAll("-", "") }
                : null,
            carSwVer: {
                vcuVer: value?.carSwVer?.vcuVer?.swVerId ? { swVerId: value.carSwVer.vcuVer.swVerId } : null,
                bmsVer: value?.carSwVer?.bmsVer?.swVerId ? { swVerId: value.carSwVer.bmsVer.swVerId } : null,
            },
        };

        delete searchValue.runningSummary;
        type === "register" ? requestApiCarRegister(searchValue) : requestApiCarUpdate(carId, searchValue);
    };

    const onResult = (type: "register" | "edit" | "delete", id?: number) => {
        const typeText = Utils.getTypeText(type);

        alert.setAlert(AlertType.SUCCESS, `${String.car} ${typeText} 성공`, `${String.car} 정보를 ${typeText}하였습니다.`);
        setType("view");

        type === "register" ? navigate(Common.PAGE_MANAGE_CAR_DETAIL + "/" + id) : requestApiCarInfo(carId);
    };

    return (
        <Space direction="vertical" size={24} style={{ display: "flex" }}>
            <CustomForm
                form={form}
                initialValues={car}
                type={type}
                contents={[{ name: "상세 정보", forms: contents }]}
                onFinish={onFinish}
                disabledBtn={!Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN])}
                onChangeType={(type) => setType(type)}
            />
            {car?.makerModel?.maker?.makerName === tsEcoMakerName && (
                <CustomForm
                    form={batteryForm}
                    initialValues={batteryPack}
                    type="view"
                    contents={[{ name: "배터리 팩 정보", forms: batteryContents }]}
                    disabledBtn
                />
            )}
            {car?.carNumberHistories && car?.carNumberHistories?.length > 0 && type === "view" && (
                <DataTable
                    title="변경이력"
                    rowKey={(row: TypeDTO.CarNumberHistoryDto) => row.historyTime}
                    columns={columns}
                    dataSource={car?.carNumberHistories.sort(
                        (a, b) => new Date(b?.historyTime).valueOf() - new Date(a?.historyTime).valueOf()
                    )}
                />
            )}
        </Space>
    );
};

export default TabCarInfo;
