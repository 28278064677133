import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuthState } from "../../provider/AuthProvider";
import { CopyOutlined, HomeOutlined, NumberOutlined, TagOutlined } from "@ant-design/icons";
import { AlertType, useAlert } from "../../provider/AlertProvider";
import Input from "antd/es/input/index";
import Form from "antd/es/form/index";
import Tag from "antd/es/tag/index";
import Space from "antd/es/space/index";
import CustomForm from "./CustomForm";
import CustomCard from "./CustomCard";
import moment from "moment";

import * as Common from "../../commons/common";
import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";
import * as String from "../../commons/string";
import * as Utils from "../../utils/utils";

const TabRunningInfo = ({
    car,
    isFinish,
    runningHistory,
}: {
    car?: TypeDTO.CarDto;
    isFinish?: boolean;
    runningHistory?: TypeDTO.RunningHistoryDto;
}) => {
    const alert = useAlert();
    const param = useParams();
    const userDetails = useAuthState();

    const [runningsForm] = Form.useForm();
    const [endTime, setEndTime] = useState<string>();

    useEffect(() => {
        if (param.id3) {
            const startToEndTime = Number(moment(param.id2).diff(moment(param.id3), "seconds"));
            runningsForm.setFieldsValue({
                startTime: moment(param.id2).format(Common.FORMAT_SHORT_DATE_TIME),
                endTime: moment(param.id3).format(Common.FORMAT_SHORT_DATE_TIME),
                drivingTime: Utils.secToTime(startToEndTime),
            });
        } else {
            if (runningHistory) {
                const startTime = moment(runningHistory.startTime).format(Common.FORMAT_SHORT_DATE_TIME);
                const endTime = moment(runningHistory.endTime).format(Common.FORMAT_SHORT_DATE_TIME);
                const endTimeForSetting = moment(runningHistory.endTime).format(Common.FORMAT_DATE_TIME);
                const drivingTime = Utils.secToTime(runningHistory.drivingTime);
                const drivingDistance = runningHistory.drivingDistance.toFixed(1);
                const avgSpeed = runningHistory.avgSpeed.toFixed(1);
                const electricMileage = runningHistory.electricMileage.toFixed(1);

                runningsForm.setFieldsValue({
                    ...runningHistory,
                    startTime,
                    endTime,
                    drivingTime,
                    drivingDistance: drivingDistance + String.kmDistanceUnit.replace("(", "").replace(")", ""),
                    avgSpeed: avgSpeed + String.kmSpeedUnit.replace("(", "").replace(")", ""),
                    electricMileage: electricMileage + String.kmElectricMileageUnit.replace("(", "").replace(")", ""),
                });
                setEndTime(endTimeForSetting);
            }
        }
    }, [runningHistory]);

    const displayEndTime = (endTime?: string) => {
        return endTime ? (
            isFinish === true ? (
                <Input className="disabled-input" disabled />
            ) : moment(endTime).isSameOrAfter(moment().subtract(10, "m")) ? (
                <Tag className="pointTag" style={{ marginLeft: 11 }}>
                    운행중
                </Tag>
            ) : Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN]) ? (
                <Input className="disabled-input fc-danger" disabled />
            ) : (
                <Input className="disabled-input" disabled />
            )
        ) : (
            <Input className="disabled-input" disabled />
        );
    };

    const contents: Array<TypeUtils.formType> = param.id3
        ? [
              { name: "startTime", label: String.startTime },
              {
                  name: "endTime",
                  label: String.endTime,

                  input: endTime ? displayEndTime(endTime) : <Input className="disabled-input" disabled />,
              },
              { name: "drivingTime", label: "조회 시간" },
          ]
        : [
              { name: "startTime", label: String.startTime },
              {
                  name: "endTime",
                  label: String.endTime,

                  input: endTime ? displayEndTime(endTime) : <Input className="disabled-input" disabled />,
              },
              { name: "drivingTime", label: String.runningTime },
              { name: "drivingDistance", label: String.traveledDistance },
              { name: "avgSpeed", label: String.avgSpeed },
              { name: "electricMileage", label: String.electricMileage },
          ];

    const cardValue = [
        {
            icon: <NumberOutlined />,
            value: car?.idNumber ? car.idNumber : "-",
        },
        {
            icon: <HomeOutlined />,
            value: car?.makerModel.maker.makerName ? car.makerModel.maker.makerName : "-",
        },
        {
            icon: <TagOutlined />,
            value: car?.makerModel.makerModelName ? car.makerModel.makerModelName : "-",
        },
    ];

    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            <p className="fw-bd fs-lg fc-font1" style={{ margin: 0 }}>
                운행 정보
            </p>
            <CustomCard
                title={car?.carNumber}
                icon={<CopyOutlined />}
                contents={cardValue}
                onClick={() => {
                    if (car?.carNumber) {
                        navigator?.clipboard?.writeText(car.carNumber);
                        alert.setAlert(AlertType.SUCCESS, `복사 성공`, `차량 번호를 복사했습니다.`);
                    }
                }}
            />
            <CustomForm type="view" form={runningsForm} contents={[{ forms: contents }]} disabledBtn />
        </Space>
    );
};

export default TabRunningInfo;
