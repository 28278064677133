import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuthState } from "../../provider/AuthProvider";
import { CopyOutlined, HomeOutlined, UserOutlined, PhoneOutlined } from "@ant-design/icons";
import { AlertType, useAlert } from "../../provider/AlertProvider";

import Input from "antd/es/input/index";
import Form from "antd/es/form/index";
import Select from "antd/es/select/index";
import Space from "antd/es/space/index";
import Tag from "antd/es/tag/index";
import CustomForm from "../../components/mobile/CustomForm";
import CustomCard from "../../components/mobile/CustomCard";
import moment from "moment";

import * as RequestCar from "../../utils/requestApiCar";
import * as Common from "../../commons/common";
import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";
import * as String from "../../commons/string";
import * as Utils from "../../utils/utils";

const PageSocDetail = () => {
    const alert = useAlert();
    const param = useParams();
    const [form] = Form.useForm();
    const userDetails = useAuthState();
    const { Option } = Select;
    const [car, setCar] = useState<TypeDTO.CarDto>();
    const [cars, setCars] = useState<Array<TypeDTO.CarDto>>();

    useEffect(() => {
        requestApiCarList(true);
    }, []);

    useEffect(() => {
        if (cars === undefined) return;
        cars.map((car: TypeDTO.CarDto) => {
            return car.carNumber === param.id && setCar(car);
        });
    }, [cars]);

    useEffect(() => {
        if (car === undefined) return;
        const endSoc = car.runningSummary.lastRunningHistory.endSoc?.toFixed(1);
        const tableSoc = car.runningSummary.lastRunningHistory.tableSoc?.toFixed(1);
        const batMaxTemp = car.runningSummary.lastRunningHistory.batMaxTemp?.toFixed(1);
        const batMinTemp = car.runningSummary.lastRunningHistory.batMinTemp?.toFixed(1);
        const batMaxVolt = car.runningSummary.lastRunningHistory.batMaxVolt?.toFixed(1);
        const batMinVolt = car.runningSummary.lastRunningHistory.batMinVolt?.toFixed(1);

        form.setFieldsValue({
            socCheck: car.runningSummary.lastRunningHistory.socCheck ? "경고" : "정상",
            startTime: moment(car.runningSummary.lastRunningHistory.startTime).format(Common.FORMAT_SHORT_DATE_TIME),
            endTime: moment(car.runningSummary.lastRunningHistory.endTime).format(Common.FORMAT_SHORT_DATE_TIME),
            endSoc: endSoc ? endSoc + String.percentUnit.replace("(", "").replace(")", "") : "-",
            tableSoc: tableSoc ? tableSoc + String.percentUnit.replace("(", "").replace(")", "") : "-",
            batMaxTemp: batMaxTemp ? batMaxTemp + String.tempUnit.replace("(", "").replace(")", "") : "-",
            batMinTemp: batMinTemp ? batMinTemp + String.tempUnit.replace("(", "").replace(")", "") : "-",
            batMaxVolt: batMaxVolt ? batMaxVolt + String.voltUnit.replace("(", "").replace(")", "") : "-",
            batMinVolt: batMinVolt ? batMinVolt + String.voltUnit.replace("(", "").replace(")", "") : "-",
        });
    }, [car]);

    const { loadingApiCarList, requestApiCarList, resultApiCarList } = RequestCar.useRequestApiCarList();

    useEffect(() => {
        if (!resultApiCarList) return;

        setCars(
            resultApiCarList.cars.sort((a: TypeDTO.CarDto, b: TypeDTO.CarDto) =>
                !a?.runningSummary?.lastRunningHistory?.socCheck ? 1 : !b?.runningSummary?.lastRunningHistory?.socCheck ? -1 : 0
            )
        );
    }, [resultApiCarList]);

    const displayEndTime = (endTime?: string) => {
        if (endTime) {
            if (car?.runningSummary.lastRunningHistory.finish === true) {
                return <Input className="disabled-input" disabled />;
            } else {
                if (moment(endTime).isSameOrAfter(moment().subtract(10, "m"))) {
                    return <Tag className="pointTag">운행중</Tag>;
                } else {
                    if (Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN])) {
                        return <Input className="disabled-input fc-danger" disabled />;
                    } else {
                        return <Input className="disabled-input" disabled />;
                    }
                }
            }
        } else {
            return <Input className="disabled-input" disabled />;
        }
    };

    const contents: Array<TypeUtils.formType> = [
        {
            name: "socCheck",
            label: String.status,
        },
        {
            name: "startTime",
            label: String.startTime,
        },
        {
            name: "endTime",
            label: String.endTime,
            input: car?.runningSummary.lastRunningHistory.endTime ? (
                displayEndTime(car?.runningSummary.lastRunningHistory.endTime)
            ) : (
                <Input className="disabled-input" disabled />
            ),
        },
        {
            name: "endSoc",
            label: String.soc,
        },
        {
            name: "tableSoc",
            label: String.menu_soc,
        },
        {
            name: "batMaxTemp",
            label: String.maxTemp,
        },
        {
            name: "batMinTemp",
            label: String.minTemp,
        },
        {
            name: "batMaxVolt",
            label: String.maxVolt,
        },
        {
            name: "batMinVolt",
            label: String.minVolt,
        },
    ];

    const cardValue = [
        {
            icon: <HomeOutlined />,
            value: car?.makerModel.maker.makerName ? car.makerModel.maker.makerName : "-",
        },
        {
            icon: <UserOutlined />,
            value: car?.customer.customerName ? car.customer.customerName : "-",
        },
        {
            icon: <PhoneOutlined />,
            value: car?.customer.customerPhone ? car.customer.customerPhone : "-",
        },
    ];

    return (
        <div className="pageWrapper" style={{ paddingBottom: 64 }}>
            <Space direction="vertical" style={{ width: "100%" }}>
                <p className="fw-bd fs-lg fc-font1" style={{ margin: 0 }}>
                    배터리 정보
                </p>
                <CustomCard
                    title={car?.carNumber}
                    icon={<CopyOutlined />}
                    contents={cardValue}
                    onClick={() => {
                        if (car?.carNumber) {
                            navigator?.clipboard?.writeText(car?.carNumber);
                            alert.setAlert(AlertType.SUCCESS, `복사 성공`, `차량 번호를 복사했습니다.`);
                        }
                    }}
                />
                <CustomForm type="view" form={form} contents={[{ forms: contents }]} disabledBtn />
            </Space>
        </div>
    );
};

export default PageSocDetail;
