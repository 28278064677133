import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useAuthState } from "../../provider/AuthProvider";
import { useSelector, useDispatch } from "react-redux";
import { actionSetRunningsByCar } from "../../utils/action";
import type { ColumnsType, SorterResult } from "antd/es/table/interface";
import { type TableProps } from "antd/es/table/InternalTable";

import Space from "antd/es/space/index";
import Tag from "antd/es/tag/index";
import Badge from "antd/es/badge/index";
import Divider from "antd/es/divider/index";
import moment from "moment";

import * as TypeUtils from "../../commons/typeUtils";
import * as String from "../../commons/string";
import * as Common from "../../commons/common";
import * as TypeDTO from "../../commons/typeDTO";
import * as Utils from "../../utils/utils";
import * as RequestRunning from "../../utils/requestApiRunning";

import Filter from "../mobile/Filter";
import CustomRangePicker from "./CustomRangePicker";
import DataTable from "../common/DataTable";

type SearchType = {
    startDate: string;
    endDate: string;
    checkbox: string[];
};

type SearchDateType = {
    startDate: string;
    endDate: string;
};

const TabRuninngs = ({ carId, carNumber }: { carId?: number; carNumber?: string }) => {
    const navigate = useNavigate();
    const userDetails = useAuthState();
    const dispatch = useDispatch();
    const reducerRunningsByCar = useSelector((state) => state.runningsByCar);

    const [sortedInfo, setSortedInfo] = useState<SorterResult<TypeDTO.RunningHistoryDto>>({});
    const [searchValue, setSearchValue] = useState<SearchType>({
        startDate: reducerRunningsByCar?.startDate || moment().subtract(7, "day").startOf("day").format(Common.FORMAT_DATE_TIME),
        endDate: reducerRunningsByCar?.endDate || moment().format(Common.FORMAT_DATE_TIME),
    });
    const [runnings, setRunnings] = useState<Array<TypeDTO.RunningHistoryDto>>();
    const [loading, setLoading] = useState(false);
    const [tempHistories, setTempHistories] = useState<Array<TypeDTO.RunningHistoryDto>>();
    const [requestHistoriesPage, setRequestHistoriesPage] = useState(0);
    const [date, setDate] = useState<SearchDateType>();
    const [filteredRunnings, setFilteredRunnings] = useState<Array<TypeDTO.RunningHistoryDto>>();

    const filterData: TypeUtils.SearchItemType[] = [
        {
            id: "checkbox",
            span: 12,
            title: String.alarmStatus,
            type: "checkbox",
            options: [
                { value: "error", label: String.error },
                { value: "warning", label: String.warning },
                { value: "normal", label: String.normal },
            ],
            defaultValue: ["error", "warning", "normal"],
        },
    ];

    const [filter, setFilter] = useState<TypeUtils.SearchItemType[]>();

    const columns: ColumnsType<TypeDTO.RunningHistoryDto> = [
        {
            title: String.runningDate,
            dataIndex: "startTime",
            key: "startTime",
            sorter: (a, b, sortOrder) => Utils.sortDate(a.startTime, b.startTime, sortOrder),
            sortOrder: sortedInfo.columnKey === "time" ? sortedInfo.order : null,
            render: (_, { startTime }) => {
                return moment.utc(startTime, Common.FORMAT_DATE).format(Common.FORMAT_SHORT_DATE);
            },
        },

        {
            title: String.startTime,
            dataIndex: "startTime",
            key: "startTime",
            align: "center",
            render: (_, { startTime, rtcCheck }) => {
                return rtcCheck === true ? (
                    <Badge style={{ right: "-4px" }} dot color="var(--danger)">
                        <span className="fs-md">{startTime ? moment(startTime).format(Common.FORMAT_TIME) : String.dash}</span>
                    </Badge>
                ) : startTime ? (
                    <span className="fs-md">{moment(startTime).format(Common.FORMAT_TIME)}</span>
                ) : (
                    String.dash
                );
            },
        },
        {
            title: String.endTime,
            dataIndex: "endTime",
            key: "endTime",
            align: "center",
            render: (_, { startTime, endTime, finish }) => {
                const checkDday = moment(endTime).startOf("day").diff(moment(startTime).startOf("day"), "days");
                return (
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        {checkDday > 0 && (
                            <Tag className="normalTag" style={{ marginRight: 4 }}>
                                D+{checkDday}
                            </Tag>
                        )}
                        {endTime ? (
                            finish === true ? (
                                <span className="fs-md">{endTime ? moment(endTime).format(Common.FORMAT_MOBILE_DATE_TIME) : String.dash}</span>
                            ) : moment(endTime).isSameOrAfter(moment().subtract(10, "m")) ? (
                                <Tag className="pointTag">운행중</Tag>
                            ) : Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN]) ? (
                                <Badge style={{ right: "-4px" }} dot color="var(--danger)">
                                    <span className="fc-point fs-md">
                                        {endTime ? moment(endTime).format(Common.FORMAT_MOBILE_DATE_TIME) : String.dash}
                                    </span>
                                </Badge>
                            ) : (
                                <span className="fs-md">{endTime ? moment(endTime).format(Common.FORMAT_MOBILE_DATE_TIME) : String.dash}</span>
                            )
                        ) : (
                            <span className="fs-md">{String.dash}</span>
                        )}
                    </div>
                );
            },
        },
    ];

    useEffect(() => {
        if (date === undefined) return;
        navigate(Common.PAGE_RUNNING_DETAILS + "/" + carId + "/" + date?.startDate + "/" + date?.endDate);
    }, [date]);

    useEffect(() => {
        if (Object.keys(reducerRunningsByCar).length > 0) {
            console.log("reducerRunningsByCar", reducerRunningsByCar);
            setDate(reducerRunningsByCar.searchDate);
            setFilter(
                filterData?.map((item) => {
                    const { id, defaultValue } = item;
                    const isCheckbox = id === "checkbox";

                    if (isCheckbox) {
                        const { error = true, warning = true, normal = true } = reducerRunningsByCar;

                        return {
                            ...item,
                            defaultValue: Object.entries({ error, warning, normal })
                                .filter(([key, value]) => value)
                                .map(([key]) => key),
                        };
                    } else {
                        return {
                            ...item,
                            defaultValue: reducerRunningsByCar[id] ?? defaultValue,
                        };
                    }
                })
            );
            setSearchValue(reducerRunningsByCar);
        } else {
            setFilter(filterData);
        }
    }, [reducerRunningsByCar]);

    const dispatchRunningsByCar = (selectedId?: number) => {
        dispatch(
            actionSetRunningsByCar({
                ...searchValue,
                searchDate: date,
                selectedId: selectedId,
                scrollPosition: document.querySelector(".ant-tabs-content-holder").scrollTop,
            })
        );
    };

    const onTableChange: TableProps<TypeDTO.RunningHistoryDto>["onChange"] = (pagination, filters, sorter) => {
        setSortedInfo(sorter as SorterResult<TypeDTO.RunningHistoryDto>);
    };

    const onSearchData = (value: any) => {
        const { checkbox, ...searchValue } = value;

        searchValue.carNumber = carNumber;
        searchValue.error = value?.error ?? value?.checkbox?.includes("error") ?? false;
        searchValue.warning = value?.warning ?? value?.checkbox?.includes("warning") ?? false;
        searchValue.normal = value?.normal ?? value?.checkbox?.includes("normal") ?? false;

        if (carNumber) {
            setSearchValue(searchValue);
            setLoading(true);
            requestApiRunningHistoryList(searchValue, requestHistoriesPage);
        }
    };

    const { requestApiRunningHistoryList, resultApiRunningHistoryList } = RequestRunning.useRequestApiRunningHistoryList();

    useEffect(() => {
        if (resultApiRunningHistoryList === null) return;

        if (resultApiRunningHistoryList.runningHistories.totalPages > requestHistoriesPage + 1) {
            setTempHistories([...(tempHistories || []), ...resultApiRunningHistoryList.runningHistories.content]);
            setRequestHistoriesPage(requestHistoriesPage + 1);
        } else {
            setLoading(false);
            setRunnings([...(tempHistories || []), ...resultApiRunningHistoryList.runningHistories.content]);
            setRequestHistoriesPage(0);
            setTempHistories([]);

            setTimeout(() => {
                document.querySelector(".ant-tabs-content-holder").scrollTo({ top: reducerRunningsByCar.scrollPosition });
            }, 100);
        }
    }, [resultApiRunningHistoryList]);

    useEffect(() => {
        if (requestHistoriesPage === 0) return;
        requestApiRunningHistoryList(searchValue, requestHistoriesPage);
    }, [requestHistoriesPage]);

    useEffect(() => {
        if (runnings === undefined) return;
        const array: Array<TypeDTO.RunningHistoryDto> = [];
        runnings.map((running) => {
            running.rtcCheck !== true && array.push(running);
        });
        setFilteredRunnings(array);
    }, [runnings]);

    return (
        <>
            <Space direction="vertical" size={8} style={{ display: "flex" }}>
                <p className="fw-bd fs-lg fc-font1" style={{ margin: 0 }}>
                    기간별 조회
                </p>
                <CustomRangePicker
                    placeholder={true}
                    value={{ startDate: date?.startDate, endDate: date?.endDate }}
                    maxDate={1}
                    onSearch={(value) => {
                        console.log("CustomRangePicker", value);
                        value &&
                            setDate({
                                startDate: moment(value?.startDate).format(Common.FORMAT_DATE_HOUR_TIME_START),
                                endDate: moment(value?.endDate).format(Common.FORMAT_DATE_HOUR_TIME_END),
                            });
                    }}
                />
            </Space>
            <Divider className="customDivider" />
            <Space direction="vertical" size={8} style={{ display: "flex" }}>
                <Filter
                    title="건별 조회"
                    dataLength={runnings?.length}
                    values={filter}
                    onSearch={(value) => onSearchData({ ...value, startDate: searchValue.startDate, endDate: searchValue.endDate })}
                />
                <CustomRangePicker
                    value={{ startDate: searchValue.startDate, endDate: searchValue.endDate }}
                    onSearch={(value) => {
                        console.log("value", value);
                        console.log("searchValue", searchValue);

                        if (value) {
                            onSearchData({ ...searchValue, startDate: value.startDate, endDate: value.endDate });
                        } else {
                            onSearchData({
                                ...searchValue,
                                startDate: moment().subtract(7, "day").startOf("day").format(Common.FORMAT_DATE_TIME),
                                endDate: moment().endOf("day").format(Common.FORMAT_DATE_TIME),
                            });
                        }
                    }}
                />
                <DataTable
                    rowKey={(row: TypeDTO.RunningHistoryDto) => row.bootTime}
                    disabledTitle
                    loading={loading}
                    columns={columns}
                    dataSource={Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN]) ? runnings : filteredRunnings}
                    rowClassName={(history: TypeDTO.RunningHistoryDto) => {
                        return history?.runningHistoryId === reducerRunningsByCar?.selectedId ? "table-row-selected" : "";
                    }}
                    onRow={(value: TypeDTO.RunningHistoryDto) => {
                        dispatchRunningsByCar(value.runningHistoryId);
                        navigate(Common.PAGE_RUNNING_DETAILS + "/" + value.car.carId + "/" + value.bootTime);
                    }}
                    onChange={onTableChange}
                    isMobile
                />
            </Space>
        </>
    );
};

export default TabRuninngs;
