import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { BellOutlined, CloseOutlined, RedoOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Row, Spin } from "antd";
import { FloatingPanel } from "antd-mobile";
import { useSelector, useDispatch } from "react-redux";
import { actionSetDashboard } from "../../utils/action";
import KakaoMap, { gpsType } from "../../components/common/KakaoMap";

import * as RequestCar from "../../utils/requestApiCar";
import * as Common from "../../commons/common";
import * as TypeDTO from "../../commons/typeDTO";
import * as Utils from "../../utils/utils";

import Space from "antd/es/space/index";
import Tag from "antd/es/tag/index";
import moment from "moment";
import styles from "./Dashboard.module.css";

import DashboardCarInfo from "../../components/mobile/DashboardCarInfo";
import DashboardFilter from "../../components/mobile/DashboardFilter";
import CarSelector from "../../components/mobile/CarSelector";

function Dashboard() {
    const navigate = useNavigate();
    const chargeEmergency = 20;
    const chargeEncourage = 40;
    const dispatch = useDispatch();
    const reducerDashboard = useSelector((state) => state.dashboard);

    const [cars, setCars] = useState<Array<TypeDTO.CarDto>>();
    const [filteredCars, setFilteredCars] = useState<Array<TypeDTO.CarDto> | undefined>();
    const [selectedCarId, setSelectedCarId] = useState<number>();
    const [filters, setFilters] = useState<string[]>(["driving", "end", "error", "warning", "normal", "emergency", "encourage", "batteryNormal"]);
    const [center, setCenter] = useState<gpsType>();
    const [showCarInfo, setShowCarInfo] = useState<boolean>();
    const [updateTime, setUpdateTime] = useState<string>();

    useEffect(() => {
        requestApiCarList(true, false, true);

        const filter = JSON.parse(String(Utils.getLocalStorage(Common.CONTEXT_FILTER)));
        filter && setFilters(filter);

        setCenter(
            Utils.getLocalStorage(Common.CONTEXT_CENTER)
                ? JSON.parse(String(Utils.getLocalStorage(Common.CONTEXT_CENTER)))
                : { lat: 36.69378701881083, lng: 127.92059335655368 }
        );
    }, []);

    useEffect(() => {
        const car = cars?.find((car) => selectedCarId === car.carId);
        if (car?.runningSummary?.lastRunningHistory?.latitude && car?.runningSummary?.lastRunningHistory?.longitude) {
            setCenter({ lat: car.runningSummary.lastRunningHistory.latitude, lng: car?.runningSummary?.lastRunningHistory?.longitude });
        }
    }, [selectedCarId]);

    const { loadingApiCarList, requestApiCarList, resultApiCarList } = RequestCar.useRequestApiCarList();

    useEffect(() => {
        if (!resultApiCarList) return;
        setUpdateTime(moment().format(Common.FORMAT_SHORT_DATE_TIME));
        setCars(resultApiCarList.cars);
    }, [resultApiCarList]);

    useEffect(() => {
        setFilteredCars(cars?.filter((car) => applyFilters(car, (value) => filters?.includes(value))));
    }, [filters, cars]);

    useEffect(() => {
        if (Object.keys(reducerDashboard).length === 0) return;

        setSelectedCarId(reducerDashboard.selectedId);
    }, [reducerDashboard]);

    const dispatchDashboard = () => {
        dispatch(
            actionSetDashboard({
                selectedId: selectedCarId,
            })
        );
    };

    const applyFilters = (car: TypeDTO.CarDto, checkFilter: (value: string) => boolean, carId?: number) => {
        const history = car?.runningSummary?.lastRunningHistory;
        const errorCnt = car?.alarmCountByCar?.errorCount;
        const warningCnt = car?.alarmCountByCar?.warningCount;

        const isDriving =
            checkFilter("driving") && !history?.finish && !moment(history?.endTime, Common.FORMAT_DATE_TIME).isBefore(moment().subtract(10, "m"));
        const isEnd =
            checkFilter("end") &&
            (history?.finish || (!history?.finish && moment(history?.endTime, Common.FORMAT_DATE_TIME).isBefore(moment().subtract(10, "m"))));

        const isError = checkFilter("error") && errorCnt > 0;
        const isWarning = checkFilter("warning") && warningCnt > 0;
        const isNormal = checkFilter("normal") && !errorCnt && !warningCnt;

        const isEmergency = checkFilter("emergency") && (history?.endSoc === 0 || history?.endSoc <= chargeEmergency);
        const isEncourage = checkFilter("encourage") && history?.endSoc > 20 && history?.endSoc <= chargeEncourage;
        const isBatteryNormal = checkFilter("batteryNormal") && (!history?.endSoc || history?.endSoc > chargeEncourage);

        return (isDriving || isEnd) && (isError || isWarning || isNormal) && (isEmergency || isEncourage || isBatteryNormal);
    };

    const onChangeFilter = (values: string[]) => {
        Utils.setLocalStorage(Common.CONTEXT_FILTER, JSON.stringify(values));
        setFilters(values);
    };

    const carInfo = () => {
        const car = cars?.find((car) => car?.carId === selectedCarId);
        const checkAbnormal =
            car?.runningSummary?.lastRunningHistory?.finish !== true &&
            moment(car?.runningSummary?.lastRunningHistory?.endTime, Common.FORMAT_DATE_TIME).isBefore(moment().subtract(10, "m"));
        return (
            showCarInfo &&
            car && (
                <FloatingPanel className={`fixedFloatingPanel ${styles.carInfoPanel}`} anchors={["fit-content"]}>
                    <div className={styles.carInfoWrapper}>
                        <Row justify="space-between">
                            <Space size={4}>
                                <span
                                    className={
                                        checkAbnormal || car?.runningSummary?.lastRunningHistory?.finish === true
                                            ? "fs-lg fw-bd"
                                            : "fs-lg fw-bd fc-point"
                                    }
                                    onClick={() => {
                                        dispatchDashboard();
                                        navigate(Common.PAGE_MANAGE_CAR_DETAIL + "/" + car.carId);
                                    }}
                                >
                                    {car.carNumber}
                                </span>
                                {car?.alarmCountByCar?.errorCount ? (
                                    <Tag className="errorTag" style={{ margin: 0 }}>
                                        {car?.alarmCountByCar?.errorCount}
                                    </Tag>
                                ) : (
                                    ""
                                )}
                                {car?.alarmCountByCar?.warningCount ? (
                                    <Tag className="warningTag" style={{ margin: 0 }}>
                                        {car?.alarmCountByCar?.warningCount}
                                    </Tag>
                                ) : (
                                    ""
                                )}
                                {(car?.alarmCountByCar?.errorCount || car?.alarmCountByCar?.warningCount) && (
                                    <span
                                        className={`${styles.alarmBtn}
                                           fc-font3 fw-bd fs-sm btn-text`}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            navigate(Common.PAGE_ALARM + "/" + car.carNumber);
                                        }}
                                    >
                                        <BellOutlined /> 알람
                                    </span>
                                )}
                            </Space>
                            {showCarInfo && (
                                <CloseOutlined
                                    style={{ fontSize: 16, color: "var(--font3)" }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setShowCarInfo(false);
                                    }}
                                />
                            )}
                        </Row>
                        <DashboardCarInfo car={car} />
                    </div>
                </FloatingPanel>
            )
        );
    };

    return (
        <div className={styles.mapWrapper}>
            <div className={styles.carSelectorWrapper}>
                <CarSelector
                    isWhite
                    selectedCarId={selectedCarId}
                    onOpen={(isOpen) => {
                        setShowCarInfo(!isOpen);
                        if (isOpen) {
                            setCenter(JSON.parse(String(Utils.getLocalStorage(Common.CONTEXT_CENTER))));
                        }
                    }}
                    onSelect={(id: number) => {
                        setSelectedCarId(id);
                        const history = cars?.find((car) => car.carId === id)?.runningSummary?.lastRunningHistory;
                        history && setCenter({ lat: history?.latitude, lng: history?.longitude });
                    }}
                />

                {carInfo()}
            </div>
            <div className={styles.refreshBtnWrapper} onClick={() => requestApiCarList(true, false, true)}>
                <span className="fs-xs">업데이트: {updateTime ? updateTime : <Spin indicator={<LoadingOutlined spin />} size="small" />}</span>
                {updateTime && <RedoOutlined className={loadingApiCarList ? styles.loadingRefreshIcon : styles.refreshIcon} />}
            </div>
            <DashboardFilter filters={filters} onChange={(values) => onChangeFilter(values)} />
            <KakaoMap
                className="dashboardMap"
                data-testid="map"
                mapStyle={{ width: "100%", height: "calc(var(--vh, 1vh) * 100 - 60px)" }}
                mapCenter={center}
                mapZoom={Utils.getLocalStorage(Common.CONTEXT_ZOOM) ? Number(Utils.getLocalStorage(Common.CONTEXT_ZOOM)) : undefined}
                maxZoom={13}
                cars={JSON.parse(String(Utils.getLocalStorage(Common.CONTEXT_FILTER))) ? filteredCars : cars}
                selectedCarId={selectedCarId}
                mapTypeControl={false}
                onClick={(id) => {
                    selectedCarId && setShowCarInfo(true);
                    setSelectedCarId(id);
                }}
                onChange={(value) => {
                    Utils.setLocalStorage(Common.CONTEXT_CENTER, JSON.stringify(value));
                }}
                onZoom={(value) => Utils.setLocalStorage(Common.CONTEXT_ZOOM, String(value))}
            />
        </div>
    );
}

export default Dashboard;
