import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useAuthState } from "../../provider/AuthProvider";
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import { actionSetAlarms } from "../../utils/action";
import type { ColumnsType } from "antd/es/table/interface";

import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";
import * as Common from "../../commons/common";
import * as String from "../../commons/string";
import * as Utils from "../../utils/utils";
import * as RequestAlarm from "../../utils/requestApiAlarm";

import moment from "moment";
import Space from "antd/es/space/index";
import DataTable from "../../components/common/DataTable";
import CustomRangePicker from "../../components/mobile/CustomRangePicker";
import Filter from "../../components/mobile/Filter";
import IconRed from "../../assets/images/icon/icon_red_exclamation.png";
import IconYellow from "../../assets/images/icon/icon_yellow_exclamation.png";
import IconBlue from "../../assets/images/icon/icon_blue_exclamation.png";

type SearchType = {
    carNumber: string;
    searchValue: string;
    makerName: string;
    categoryName: string;
    startDate: string;
    endDate: string;
    checkbox: string[];
};

const PageAlarm = () => {
    const isLaptop = useMediaQuery({ maxWidth: 2100 });
    const navigate = useNavigate();
    const param = useParams();
    const userDetails = useAuthState();
    const dispatch = useDispatch();
    const reducerAlarms = useSelector((state) => state.alarms);

    const [loading, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState<SearchType>(
        param.id
            ? {
                  startDate: moment().startOf("day").format(Common.FORMAT_DATE_TIME),
                  endDate: moment().format(Common.FORMAT_DATE_TIME),
              }
            : {
                  startDate: moment().subtract(1, "day").startOf("day").format(Common.FORMAT_DATE_TIME),
                  endDate: moment().format(Common.FORMAT_DATE_TIME),
              }
    );
    const [alarms, setAlarms] = useState<Array<TypeDTO.AlarmDto>>();
    const [tempAlarms, setTempAlarms] = useState<Array<TypeDTO.RunningHistoryDto>>();
    const [requestAlarmsPage, setRequestAlarmsPage] = useState(0);
    const [selectedCarNumber, setSelectedCarNumber] = useState<string | undefined>(param.id);

    const filterData: TypeUtils.SearchItemType[] = [
        {
            id: "carNumber",
            span: 12,
            title: String.carNumber,
            type: "select",
            typeDetail: "car",
            defaultValue: selectedCarNumber,
        },
        {
            id: "searchValue",
            span: 12,
            title: "키워드",
            type: "input",
        },
        {
            id: "makerName",
            span: 6,
            title: String.maker,
            type: "select",
            typeDetail: "maker",
        },
        {
            id: "categoryName",
            span: 6,
            title: String.category,
            type: "select",
            typeDetail: "category",
        },
        {
            id: "checkbox",
            span: isLaptop ? 12 : 6,
            title: String.alarmType,
            type: "checkbox",
            options: [
                { value: "error", label: String.error },
                { value: "warning", label: String.warning },
            ],
            defaultValue: ["error", "warning"],
        },
        {
            id: "checkbox2",
            span: isLaptop ? 12 : 6,
            title: String.analysisLevel,
            type: "checkbox2",
            options: [
                { value: "red", label: "red" },
                { value: "yellow", label: "yellow" },
                { value: "blue", label: "blue" },
            ],
            defaultValue: ["red", "yellow", "blue"],
        },
    ];

    const [filter, setFilter] = useState<TypeUtils.SearchItemType[]>();

    const columns: ColumnsType<TypeDTO.AlarmDto> = [
        {
            title: String.level,
            dataIndex: "analysisLevel",
            key: "analysisLevel",
            width: "10%",
            align: "center",
            render: (_, { analysisLevel }) =>
                analysisLevel ? (
                    analysisLevel === "red" ? (
                        <img alt="" width={20} src={IconRed} />
                    ) : analysisLevel === "yellow" ? (
                        <img alt="" width={20} src={IconYellow} />
                    ) : (
                        <img alt="" width={20} src={IconBlue} />
                    )
                ) : (
                    String.dash
                ),
        },
        {
            title: String.carNumber,
            dataIndex: ["runningHistory", "car", "carNumber"],
            key: "carNumber",
            width: "20%",
            align: "center",
        },
        {
            title: String.alarmName,
            dataIndex: "alarmName",
            key: "alarmName",
            width: "20%",
            align: "center",
        },
    ];

    useEffect(() => {
        param?.id && setSelectedCarNumber(param.id);
    }, [param]);

    const { requestApiAlarmList, resultApiAlarmList } = RequestAlarm.useRequestApiAlarmList();

    useEffect(() => {
        console.log("reducerAlarms", reducerAlarms);

        if (Object.keys(reducerAlarms).length > 2) {
            console.log("reducerAlarms.length > 2", reducerAlarms);

            setFilter(
                filterData?.map((item) => {
                    const { id, defaultValue } = item;
                    const isCheckbox = id === "checkbox";
                    const isCheckbox2 = id === "checkbox2";

                    if (isCheckbox) {
                        const { error = true, warning = true } = reducerAlarms;

                        return {
                            ...item,
                            defaultValue: Object.entries({ error, warning })
                                .filter(([key, value]) => value)
                                .map(([key]) => key),
                        };
                    } else if (isCheckbox2) {
                        const { red = true, yellow = true, blue = true } = reducerAlarms;

                        return {
                            ...item,
                            defaultValue: Object.entries({ red, yellow, blue })
                                .filter(([key, value]) => value)
                                .map(([key]) => key),
                        };
                    } else {
                        return {
                            ...item,
                            defaultValue: reducerAlarms[id] ?? defaultValue,
                        };
                    }
                })
            );
            setSearchValue(reducerAlarms);
        } else {
            setFilter(filterData);
            requestApiAlarmList();
        }
    }, [reducerAlarms]);

    useEffect(() => {
        console.log("searchValue", searchValue);
    }, [searchValue]);

    const dispatchAlarms = (selectedId: number) => {
        console.log("dispatchAlarms", searchValue);
        dispatch(
            actionSetAlarms({
                ...searchValue,
                selectedId: selectedId,
                scrollPosition: document.querySelector(".ant-table-body").scrollTop,
            })
        );
    };

    const onSearchData = (value: any) => {
        console.log("value", value);
        const { checkbox, checkbox2, ...searchValue } = value;

        searchValue.error = value?.error ?? value?.checkbox?.includes("error") ?? false;
        searchValue.warning = value?.warning ?? value?.checkbox?.includes("warning") ?? false;
        searchValue.searchValue = value?.searchValue;

        if (selectedCarNumber && value?.carNumber !== selectedCarNumber) {
            setSelectedCarNumber(undefined);
        }

        if (value?.checkbox2) {
            searchValue.red = value?.red ?? value?.checkbox2?.includes("red") ?? false;
            searchValue.yellow = value?.yellow ?? value?.checkbox2.includes("yellow") ?? false;
            searchValue.blue = value?.blue ?? value?.checkbox2.includes("blue") ?? false;
        }

        console.log("onSearchData", searchValue);

        setLoading(true);
        setSearchValue(searchValue);
        requestApiAlarmList(searchValue, requestAlarmsPage);
    };

    useEffect(() => {
        if (!resultApiAlarmList) return;

        if (resultApiAlarmList.alarms.totalPages > requestAlarmsPage + 1) {
            setTempAlarms([...(tempAlarms || []), ...resultApiAlarmList.alarms.content]);
            setRequestAlarmsPage(requestAlarmsPage + 1);
        } else {
            setAlarms([...(tempAlarms || []), ...resultApiAlarmList.alarms.content]);
            setRequestAlarmsPage(0);
            setTempAlarms([]);
            setLoading(false);
        }
    }, [resultApiAlarmList]);

    useEffect(() => {
        if (requestAlarmsPage === 0) return;

        requestApiAlarmList(searchValue, requestAlarmsPage);
    }, [requestAlarmsPage]);

    const onRow = (value: TypeDTO.AlarmDto) => {
        dispatchAlarms(value.alarmId);
        navigate(Common.PAGE_ALARM_DETAIL + "/" + value.alarmId);
    };

    return (
        <div className="pageWrapper">
            <CustomRangePicker
                value={{ startDate: searchValue.startDate, endDate: searchValue.endDate }}
                isToday={selectedCarNumber !== undefined}
                onSearch={(value) => {
                    if (value) {
                        console.log("1111", value);
                        onSearchData({ ...searchValue, startDate: value.startDate, endDate: value.endDate });
                    } else {
                        console.log("2222", value);
                        onSearchData({
                            ...searchValue,
                            startDate: moment().subtract(7, "day").startOf("day").format(Common.FORMAT_DATE_TIME),
                            endDate: moment().endOf("day").format(Common.FORMAT_DATE_TIME),
                        });
                    }
                }}
            />
            <Space direction="vertical">
                <Filter
                    dataLength={alarms?.length}
                    values={filter}
                    onSearch={(value) => {
                        console.log("3333", value);
                        console.log("3333 searchValue", searchValue);

                        onSearchData({ ...value, startDate: searchValue.startDate, endDate: searchValue.endDate });
                    }}
                />
                <DataTable
                    isMobile
                    rowKey={(row: TypeDTO.AlarmDto) => row.alarmId}
                    disabledTitle
                    disabledPagination
                    loading={loading}
                    columns={
                        Utils.roleCheck(userDetails?.user?.authority, [
                            Utils.getAuthority.ROLE_ADMIN,
                            Utils.getAuthority.ROLE_MANAGER,
                            Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                            Utils.getAuthority.ROLE_CUSTOMER,
                        ])
                            ? columns
                            : columns.filter((column) => column.key !== "detail")
                    }
                    dataSource={alarms}
                    rowClassName={(alarm: TypeDTO.AlarmDto) => {
                        return alarm?.alarmId === reducerAlarms?.selectedId ? "table-row-selected" : "";
                    }}
                    onRow={onRow}
                    scroll="calc(var(--vh, 1vh) * 100 - 292px)"
                />
            </Space>
        </div>
    );
};

export default PageAlarm;
