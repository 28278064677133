import { useState, useEffect } from "react";
import { TableProps } from "antd/es/table/InternalTable";
import { useNavigate, useLocation, useParams } from "react-router";
import { useAuthState } from "../../provider/AuthProvider";
import { useMediaQuery } from "react-responsive";
import type { ColumnsType, SorterResult } from "antd/es/table/interface";
import { EyeOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { actionSetAlarms } from "../../utils/action";

import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";
import * as Common from "../../commons/common";
import * as String from "../../commons/string";
import * as Utils from "../../utils/utils";
import * as RequestAlarm from "../../utils/requestApiAlarm";

import Space from "antd/es/space/index";

import moment from "moment";
import DataTable from "../../components/common/DataTable";
import Search from "../../components/browser/Search";
import IconRed from "../../assets/images/icon/icon_red_exclamation.png";
import IconYellow from "../../assets/images/icon/icon_yellow_exclamation.png";
import IconBlue from "../../assets/images/icon/icon_blue_exclamation.png";
import IconTool from "../../assets/images/icon/icon_tool.png";

type SearchType = {
    carNumber: string;
    customerName: string;
    searchValue: string;
    makerName: string;
    categoryName: string;
    startDate: string;
    endDate: string;
    checkbox: string[];
};

const PageAlarm = () => {
    const isLaptop = useMediaQuery({ maxWidth: 2100 });
    const navigate = useNavigate();
    const param = useParams();
    const userDetails = useAuthState();
    const dispatch = useDispatch();
    const reducerAlarms = useSelector((state) => state.alarms);

    const { navigateWithRefresh } = Utils.useNavigateWithRefresh();
    const [alarms, setAlarms] = useState<Array<TypeDTO.AlarmDto>>();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [searchValue, setSearchValue] = useState<SearchType>();
    const [loading, setLoading] = useState(false);
    const [sortedInfo, setSortedInfo] = useState<SorterResult<TypeDTO.AlarmDto>>({});
    const [tempAlarms, setTempAlarms] = useState<Array<TypeDTO.RunningHistoryDto>>();
    const [requestAlarmsPage, setRequestAlarmsPage] = useState(0);
    const [pageSize, setPageSize] = useState<number>(20);
    const [errorCount, setErrorCount] = useState<number>();
    const [warningCount, setWarningCount] = useState<number>();
    const [selectedCarNumber, setSelectedCarNumber] = useState<string | undefined>(param.id);

    useEffect(() => {
        requestApiAlarmList();
    }, []);

    useEffect(() => {
        param?.id && setSelectedCarNumber(param.id);
    }, [param]);

    const searchData: TypeUtils.SearchItemType[] = [
        {
            id: "carNumber",
            span: 6,
            title: String.carNumber,
            type: "select",
            typeDetail: "car",
            defaultValue: selectedCarNumber,
        },
        {
            id: "customerName",
            span: 6,
            title: String.customer,
            type: "select",
            typeDetail: "customer",
        },
        {
            id: "searchValue",
            span: 12,
            title: "키워드",
            type: "input",
        },
        {
            id: "makerName",
            span: 6,
            title: String.maker,
            type: "select",
            typeDetail: "maker",
        },
        {
            id: "categoryName",
            span: 6,
            title: String.category,
            type: "select",
            typeDetail: "category",
        },
        {
            id: "checkbox",
            span: isLaptop ? 12 : 6,
            title: String.alarmType,
            type: "checkbox",
            options: [
                { value: "error", label: String.error },
                { value: "warning", label: String.warning },
            ],
            defaultValue: ["error", "warning"],
        },
        {
            id: "checkbox2",
            span: isLaptop ? 12 : 6,
            title: String.analysisLevel,
            type: "checkbox2",
            options: [
                { value: "red", label: "red" },
                { value: "yellow", label: "yellow" },
                { value: "blue", label: "blue" },
            ],
            defaultValue: ["red", "yellow", "blue"],
        },
        {
            id: "searchDate",
            span: isLaptop ? 12 : 6,
            title: String.searchDate,
            type: "rangePicker",
            dateFormat: Common.FORMAT_DATE_TIME,
            defaultStartDate: param.id
                ? moment().startOf("day").format(Common.FORMAT_DATE_TIME)
                : moment().subtract(1, "day").startOf("day").format(Common.FORMAT_DATE_TIME),
        },
    ];

    const [search, setSearch] = useState<TypeUtils.SearchItemType[]>();

    const columns: ColumnsType<TypeDTO.AlarmDto> = [
        {
            title: String.alarmType,
            dataIndex: "alarmType",
            key: "alarmType",
            align: "center",
            width: "10%",
            render: (_, { alarmType }) =>
                alarmType === "ERROR" ? <img alt="" width={20} src={IconRed} /> : <img alt="" width={20} src={IconYellow} />,
        },
        {
            title: String.carNumber,
            dataIndex: ["runningHistory", "car", "carNumber"],
            key: "carNumber",
            width: "15%",
            align: "center",
        },
        {
            title: String.alarmName,
            dataIndex: "alarmName",
            key: "alarmName",
            width: "20%",
            align: "center",
        },
        {
            title: String.alarmValue,
            dataIndex: "alarmValue",
            key: "alarmValue",
            width: "25%",
            align: "center",
        },
        {
            title: String.alarmTime,
            dataIndex: "alarmTime",
            key: "alarmTime",
            width: "25%",
            sorter: (a, b, sortOrder) => Utils.sortDate(a.alarmTime, b.alarmTime, sortOrder),
            sortOrder: sortedInfo.columnKey === "alarmTime" ? sortedInfo.order : null,
            render: (_, { alarmTime }) => moment(alarmTime, Common.FORMAT_DATE_TIME).format(Common.FORMAT_DATE_TIME),
        },
        {
            title: String.analysisLevel,
            dataIndex: "analysisLevel",
            key: "analysisLevel",
            width: "10%",
            align: "center",
            render: (_, { analysisLevel }) =>
                analysisLevel ? (
                    analysisLevel === "red" ? (
                        <img alt="" width={20} src={IconRed} />
                    ) : analysisLevel === "yellow" ? (
                        <img alt="" width={20} src={IconYellow} />
                    ) : (
                        <img alt="" width={20} src={IconBlue} />
                    )
                ) : (
                    String.dash
                ),
        },
        {
            title: String.analysis,
            dataIndex: "analysis",
            key: "analysis",
            width: "16%",
            align: "center",
            render: (_, { analysis }) => analysis || String.dash,
        },
        {
            title: String.customer,
            dataIndex: ["runningHistory", "car", "customer", "customerName"],
            key: "customerName",
            width: "10%",
            ellipsis: true,
            align: "center",
            render: (_, { runningHistory }) => runningHistory?.car?.customer?.customerName || String.dash,
        },
        {
            title: String.maker,
            dataIndex: ["runningHistory", "car", "makerModel", "maker", "makerName"],
            key: "makerName",
            width: "8%",
            align: "center",
            render: (_, { runningHistory }) => runningHistory?.car?.makerModel?.maker?.makerName || String.dash,
        },
        {
            title: String.manage,
            key: "detail",
            align: "center",
            width: "8%",
            render: (_, row) => {
                return (
                    <Space size={10}>
                        <div
                            style={{ width: 24, height: 24 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                if (row?.issue?.issueId) {
                                    navigateWithRefresh(
                                        Common.PAGE_MANAGE_MAINTENANCE_DETAIL + "/" + row.runningHistory?.car?.carId + "/" + row?.issue?.issueId
                                    );
                                } else {
                                    navigate(Common.PAGE_MANAGE_MAINTENANCE_REGISTER + "/" + row.runningHistory?.car?.carNumber, {
                                        state: row,
                                    });
                                }
                            }}
                        >
                            {row?.issue?.issueId ? (
                                <EyeOutlined style={{ color: "var(--font3)" }} />
                            ) : (
                                <img alt="" className="btn-icon" src={IconTool} width={20} />
                            )}
                        </div>
                    </Space>
                );
            },
        },
    ];

    useEffect(() => {
        if (Object.keys(reducerAlarms).length > 0) {
            setCurrentPage(reducerAlarms?.currentPage);

            setSearch(
                searchData?.map((item) => {
                    const { id, defaultStartDate, defaultEndDate, defaultValue } = item;
                    const isSearchDate = id === "searchDate";
                    const isCheckbox = id === "checkbox";
                    const isCheckbox2 = id === "checkbox2";

                    if (isSearchDate) {
                        return {
                            ...item,
                            defaultStartDate: reducerAlarms["startDate"] ?? defaultStartDate,
                            defaultEndDate: reducerAlarms["endDate"] ?? defaultEndDate,
                        };
                    } else if (isCheckbox) {
                        const { error = true, warning = true } = reducerAlarms;

                        return {
                            ...item,
                            defaultValue: Object.entries({ error, warning })
                                .filter(([key, value]) => value)
                                .map(([key]) => key),
                        };
                    } else if (isCheckbox2) {
                        console.log("reducerAlarms", reducerAlarms);
                        const { red = true, yellow = true, blue = true } = reducerAlarms;

                        return {
                            ...item,
                            defaultValue: Object.entries({ red, yellow, blue })
                                .filter(([key, value]) => value)
                                .map(([key]) => key),
                        };
                    } else {
                        return {
                            ...item,
                            defaultValue: reducerAlarms[id] ?? defaultValue,
                        };
                    }
                })
            );
        } else {
            setSearch(searchData);
        }
    }, [reducerAlarms]);

    const dispatchAlarms = (selectedId: number) => {
        dispatch(
            actionSetAlarms({
                ...searchValue,
                currentPage: currentPage,
                selectedId: selectedId,
                scrollPosition: document.querySelector(".ant-layout-content").scrollTop,
                pageSize: pageSize,
            })
        );
    };

    const onTableChange: TableProps<TypeDTO.AlarmDto>["onChange"] = (pagination, filters, sorter) => {
        setSortedInfo(sorter as SorterResult<TypeDTO.AlarmDto>);
        setCurrentPage(pagination.current);
        setPageSize(pagination.pageSize);
    };

    const { loadingApiAlarmList, requestApiAlarmList, resultApiAlarmList } = RequestAlarm.useRequestApiAlarmList();

    useEffect(() => {
        if (!resultApiAlarmList) return;

        if (resultApiAlarmList.alarms.totalPages > requestAlarmsPage + 1) {
            setTempAlarms([...(tempAlarms || []), ...resultApiAlarmList.alarms.content]);
            setRequestAlarmsPage(requestAlarmsPage + 1);
        } else {
            setLoading(false);
            setAlarms([...(tempAlarms || []), ...resultApiAlarmList.alarms.content]);
            setRequestAlarmsPage(0);
            setTempAlarms([]);

            setTimeout(() => {
                document.querySelector(".ant-layout-content").scrollTo({ top: reducerAlarms.scrollPosition });
            }, 100);
        }
    }, [resultApiAlarmList]);

    useEffect(() => {
        if (requestAlarmsPage === 0) return;

        requestApiAlarmList(searchValue, requestAlarmsPage);
    }, [requestAlarmsPage]);

    const onSearchData = (value: any) => {
        const { checkbox, checkbox2, ...searchValue } = value;

        searchValue.error = value?.checkbox?.includes("error");
        searchValue.warning = value?.checkbox?.includes("warning");
        searchValue.starDate = moment(value?.startDate).format(Common.FORMAT_DATE_HOUR_TIME_START);
        searchValue.endDate = moment(value?.endDate).format(Common.FORMAT_DATE_HOUR_TIME_END);
        searchValue.searchValue = value?.searchValue;

        if (selectedCarNumber && value?.carNumber !== selectedCarNumber) {
            setSelectedCarNumber(undefined);
        }

        if (value?.checkbox2) {
            searchValue.red = value?.checkbox2?.includes("red");
            searchValue.yellow = value?.checkbox2.includes("yellow");
            searchValue.blue = value?.checkbox2.includes("blue");
        }

        setSearchValue(searchValue);
        setLoading(true);
        requestApiAlarmList(searchValue, requestAlarmsPage);
    };

    const onRow = (value: TypeDTO.AlarmDto) => {
        const startTime = moment(value.alarmTime).add(-5, "m").format(Common.FORMAT_DATE_TIME);
        const endTime = moment(value.alarmTime).add(5, "m").format(Common.FORMAT_DATE_TIME);

        dispatchAlarms(value.alarmId);
        navigateWithRefresh(Common.PAGE_RUNNING_DETAILS + "/" + value.runningHistory?.car?.carId + "/" + startTime + "/" + endTime, value);
    };

    useEffect(() => {
        setErrorCount(alarms?.filter((alarm) => alarm.alarmType === "ERROR")?.length);
        setWarningCount(alarms?.filter((alarm) => alarm.alarmType === "WARNING")?.length);
    }, [alarms]);

    const summary = [
        {
            content: `${errorCount}건 /`,
            icon: IconRed,
        },
        {
            content: `${warningCount}건`,
            icon: IconYellow,
        },
    ];

    return (
        <div className="pageWrapper">
            <Search
                title={String.listAlarm.substring(3)}
                dataLength={alarms?.length}
                summary={errorCount || warningCount ? summary : undefined}
                values={search}
                onSearch={(value) => onSearchData(value)}
                onClear={(value) => {
                    setSelectedCarNumber(undefined);
                    onSearchData(value);
                }}
            />
            <DataTable
                rowKey={(row: TypeDTO.AlarmDto) => row.alarmId}
                disabledTitle
                loading={loading}
                columns={
                    Utils.roleCheck(userDetails?.user?.authority, [
                        Utils.getAuthority.ROLE_ADMIN,
                        Utils.getAuthority.ROLE_MANAGER,
                        Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                        Utils.getAuthority.ROLE_CUSTOMER,
                    ])
                        ? columns
                        : columns.filter((column) => column.key !== "detail")
                }
                dataSource={alarms}
                currentPage={currentPage}
                rowClassName={(alarm: TypeDTO.AlarmDto) => {
                    return alarm?.alarmId === reducerAlarms?.selectedId ? "table-row-selected" : "";
                }}
                tableSize={reducerAlarms?.pageSize}
                onChange={onTableChange}
                onRow={onRow}
            />
        </div>
    );
};

export default PageAlarm;
