import { useState, useEffect } from "react";
import { AlertType, useAlert } from "../../provider/AlertProvider";
import { useNavigate } from "react-router";
import { useAuthState } from "../../provider/AuthProvider";
import { useSelector, useDispatch } from "react-redux";
import { actionSetGroups } from "../../utils/action";
import type { ColumnsType } from "antd/es/table/interface";

import Modal from "antd/es/modal/index";
import Space from "antd/es/space/index";

import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";
import * as Common from "../../commons/common";
import * as String from "../../commons/string";
import * as Utils from "../../utils/utils";
import * as RequestGroup from "../../utils/requestApiAlarm";

import DataTable from "../../components/common/DataTable";
import Search from "../../components/browser/Search";

import IconEdit from "../../assets/images/icon/icon_edit.png";
import IconDelete from "../../assets/images/icon/icon_delete.png";

type SearchType = {
    groupName: string;
};

const PageGroup = () => {
    const alert = useAlert();
    const userDetails = useAuthState();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const reducerGroups = useSelector((state) => state.groups);

    const [groups, setGroups] = useState<Array<TypeDTO.AlarmReceivingGroupDto>>();
    const [filteredGroups, setFilteredGroups] = useState<Array<TypeDTO.AlarmReceivingGroupDto>>();
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState<SearchType>();
    const [pageSize, setPageSize] = useState<number>(20);

    const searchData: TypeUtils.SearchItemType[] = [
        {
            id: "groupName",
            span: 24,
            title: String.group,
            type: "select",
            options: groups?.map((group) => ({ value: group.groupName, label: group.groupName })),
        },
    ];

    const [search, setSearch] = useState<TypeUtils.SearchItemType[]>();

    const columns: ColumnsType<TypeDTO.AlarmReceivingGroupDto> = [
        {
            title: String.group,
            dataIndex: "groupName",
            key: "groupName",
            width: "10%",
            align: "center",
        },
        {
            title: String.groupCount,
            dataIndex: "alarmReceivingGroupUsers",
            key: "alarmReceivingGroupUsers",
            width: "10%",
            align: "center",
            render: (_, { alarmReceivingGroupUsers }) => alarmReceivingGroupUsers?.length,
        },
        {
            title: String.car,
            dataIndex: ["cars"],
            key: "cars",
            width: "10%",
            align: "center",
            render: (_, { cars }) => cars?.length,
        },
        {
            title: String.email,
            dataIndex: "alarmReceivingGroupUsers",
            key: "email",
            width: "10%",
            align: "center",
            render: (_, { alarmReceivingGroupUsers }) =>
                alarmReceivingGroupUsers ? alarmReceivingGroupUsers.filter((user) => user.receivingEmail).length : String.dash,
        },
        {
            title: String.sms,
            dataIndex: "alarmReceivingGroupUsers",
            key: "sms",
            width: "10%",
            align: "center",
            render: (_, { alarmReceivingGroupUsers }) =>
                alarmReceivingGroupUsers ? alarmReceivingGroupUsers.filter((user) => user.receivingSms).length : String.dash,
        },
        {
            title: String.push,
            dataIndex: "alarmReceivingGroupUsers",
            key: "push",
            width: "10%",
            align: "center",
            render: (_, { alarmReceivingGroupUsers }) =>
                alarmReceivingGroupUsers ? alarmReceivingGroupUsers.filter((user) => user.receivingPushMessage).length : String.dash,
        },
        {
            title: String.manage,
            key: "detail",
            width: "10%",
            align: "center",
            render: (_, row) => {
                return (
                    <Space size={10}>
                        <div
                            style={{ width: 24, height: 24 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                navigate(Common.PAGE_MANAGE_GROUP_UPDATE + "/" + row.alarmReceivingGroupId);
                            }}
                        >
                            <img alt="" className="btn-icon" src={IconEdit} width={20} />
                        </div>
                        <div
                            style={{ width: 24, height: 24 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                onDelete(row.alarmReceivingGroupId, row.groupName);
                            }}
                        >
                            <img alt="" className="btn-icon" src={IconDelete} width={20} />
                        </div>
                    </Space>
                );
            },
        },
    ];

    useEffect(() => {
        requestApiAlarmReceivingGroupList();
    }, []);

    useEffect(() => {
        searchValue && onSearchData(searchValue);

        setTimeout(() => {
            document.querySelector(".ant-layout-content").scrollTo({ top: reducerGroups.scrollPosition });
        }, 100);

        setSearch(
            searchData?.map((item) => ({
                ...item,
                defaultValue: reducerGroups[item.id as keyof typeof reducerGroups] ?? item.defaultValue,
            }))
        );
    }, [groups]);

    useEffect(() => {
        if (Object.keys(reducerGroups).length > 0) {
            setCurrentPage(reducerGroups?.currentPage);

            setSearch(
                searchData?.map((item) => ({
                    ...item,
                    defaultValue: reducerGroups[item.id as keyof typeof reducerGroups] ?? item.defaultValue,
                }))
            );
        } else {
            setSearch(searchData);
        }
    }, [reducerGroups]);

    const dispatchGroups = (selectedId: number) => {
        dispatch(
            actionSetGroups({
                ...searchValue,
                currentPage: currentPage,
                selectedId: selectedId,
                scrollPosition: document.querySelector(".ant-layout-content").scrollTop,
                pageSize: pageSize,
            })
        );
    };

    const onTableChange: TableProps<TypeDTO.RunningHistoryDto>["onChange"] = (pagination, filters, sorter) => {
        setCurrentPage(pagination.current);
        setPageSize(pagination.pageSize);
    };

    const { loadingApiAlarmReceivingGroupList, requestApiAlarmReceivingGroupList, resultApiAlarmReceivingGroupList } =
        RequestGroup.useRequestApiAlarmReceivingGroupList();

    useEffect(() => {
        if (!resultApiAlarmReceivingGroupList) return;

        console.log("resultApiAlarmReceivingGroupList", resultApiAlarmReceivingGroupList);
        setGroups(resultApiAlarmReceivingGroupList.alarmReceivingGroups);
    }, [resultApiAlarmReceivingGroupList]);

    const { requestApiAlarmReceivingGroupDelete, resultApiAlarmReceivingGroupDelete } =
        RequestGroup.useRequestApiAlarmReceivingGroupDelete();

    useEffect(() => {
        if (!resultApiAlarmReceivingGroupDelete) return;

        onResult("delete");
    }, [resultApiAlarmReceivingGroupDelete]);

    const onSearchData = (value: SearchType) => {
        setSearchValue(value);

        const searchFilter = Utils.searchFilter;
        setFilteredGroups(groups?.filter((group) => searchFilter(group, value?.groupName, "groupName")));
    };

    const onDelete = (id: number, name: string) => {
        Modal.confirm({
            title: String.group + String.remove,
            content: Utils.addParticle(name, "삭제하시겠습니까?"),
            okText: String.confirm,
            onOk() {
                requestApiAlarmReceivingGroupDelete(id);
            },
            cancelText: String.cancel,
            onCancel() {},
            centered: true,
        });
    };

    const onResult = (type: "register" | "edit" | "delete") => {
        const typeText = Utils.getTypeText(type);

        alert.setAlert(AlertType.SUCCESS, `${String.group} ${typeText} 성공`, `${String.group} 정보를 ${typeText}하였습니다.`);
        requestApiAlarmReceivingGroupList();
    };

    return (
        <div className="pageWrapper">
            <Search
                title={`${String.alarmGroup} 목록`}
                dataLength={filteredGroups?.length || groups?.length}
                values={search}
                onSearch={(value) => onSearchData(value)}
                onClear={(value) => onSearchData(value)}
                onClick={
                    Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN])
                        ? () => navigate(Common.PAGE_MANAGE_GROUP_REGISTER)
                        : undefined
                }
            />
            <DataTable
                rowKey={(row: TypeDTO.AlarmReceivingGroupDto) => row.alarmReceivingGroupId}
                disabledTitle
                loading={loadingApiAlarmReceivingGroupList}
                columns={
                    Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN])
                        ? columns
                        : columns.filter((column) => column.key !== "detail")
                }
                dataSource={filteredGroups || groups}
                currentPage={currentPage}
                rowClassName={(car: TypeDTO.AlarmReceivingGroupDto) => {
                    return car?.alarmReceivingGroupId === reducerGroups?.selectedId ? "table-row-selected" : "";
                }}
                tableSize={reducerGroups?.pageSize}
                onRow={(value) => {
                    dispatchGroups(value.alarmReceivingGroupId);
                    navigate(Common.PAGE_MANAGE_GROUP_DETAIL + "/" + value.alarmReceivingGroupId);
                }}
                onChange={onTableChange}
            />
        </div>
    );
};

export default PageGroup;
