import { useState, useEffect } from "react";
import Checkbox from "antd/es/checkbox/Checkbox";
import type { ColumnsType, SorterResult } from "antd/es/table/interface";
import type { TableProps } from "antd/es/table/InternalTable";

import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";
import * as String from "../../commons/string";
import * as Utils from "../../utils/utils";
import * as RequestUser from "../../utils/requestAuthUser";

import DataTable from "./DataTable";
import Search from "../browser/Search";

export type SearchType = {
    username: string;
    name: string;
};

function GroupReceivers({
    inputType,
    groupReceivers,
    onSelected,
}: {
    inputType?: boolean;
    groupReceivers?: Array<TypeDTO.AlarmReceivingGroupUserDto>;
    onSelected?: (receivers: Array<TypeDTO.AlarmReceivingGroupUserDto>) => void;
}) {
    const [sortedInfo, setSortedInfo] = useState<SorterResult<TypeDTO.AlarmReceivingGroupUserDto>>({});
    const [filterReceivers, setFilterReceivers] = useState<Array<TypeDTO.AlarmReceivingGroupUserDto>>();
    const [receivers, setReceivers] = useState<Array<TypeDTO.AlarmReceivingGroupUserDto>>([]);

    useEffect(() => {
        if (inputType) {
            requestAuthUserList();
        } else {
            setFilterReceivers(undefined);
            setReceivers(groupReceivers || []);
        }
    }, [groupReceivers, inputType]);

    useEffect(() => {
        onSelected && onSelected(receivers);
    }, [receivers]);

    const columns: ColumnsType<TypeDTO.AlarmReceivingGroupUserDto> = [
        {
            title: String.id,
            dataIndex: ["user", "username"],
            key: "username",
            sorter: (a, b, sortOrder) => Utils.sortString(a.user.username, b.user.username, sortOrder),
            sortOrder: sortedInfo.columnKey === "username" ? sortedInfo.order : null,
            ellipsis: true,
        },
        {
            title: String.receiver,
            dataIndex: ["user", "name"],
            key: "name",
            sorter: (a, b, sortOrder) => Utils.sortString(a.user.name, b.user.name, sortOrder),
            sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
            ellipsis: true,
        },
        {
            title: String.email,
            dataIndex: ["user", "email"],
            key: "email",
            ellipsis: true,
            align: "center",
            render: (_, { user }) => user.email || String.dash,
        },
        {
            title: String.phone,
            dataIndex: ["user", "phone"],
            key: "phone",
            ellipsis: true,
            align: "center",
            render: (_, { user }) => Utils.convertPhone(user?.phone) || String.dash,
        },
        {
            title: String.email,
            dataIndex: "receivingEmail",
            key: "receivingEmail",
            width: "8%",
            ellipsis: true,
            align: "center",
            render: (_, row) => {
                return (
                    <Checkbox
                        disabled={!inputType}
                        checked={row.receivingEmail}
                        onChange={(checked) => onChangeReceivingEmail(row, checked.target.checked)}
                    />
                );
            },
        },
        {
            title: String.sms,
            dataIndex: "receivingSms",
            key: "receivingSms",
            width: "8%",
            ellipsis: true,
            align: "center",
            render: (_, row) => {
                return (
                    <Checkbox
                        disabled={!inputType}
                        checked={row.receivingSms}
                        onChange={(checked) => onChangeReceivingSms(row, checked.target.checked)}
                    />
                );
            },
        },
        {
            title: String.push,
            dataIndex: "receivingPushMessage",
            key: "receivingPushMessage",
            width: "8%",
            ellipsis: true,
            align: "center",
            render: (_, row) => {
                return (
                    <Checkbox
                        disabled={!inputType}
                        checked={row.receivingPushMessage}
                        onChange={(checked) => onChangeReceivingPushMessage(row, checked.target.checked)}
                    />
                );
            },
        },
    ];

    const search: TypeUtils.SearchItemType[] = [
        {
            id: "username",
            span: 24,
            title: String.id,
            type: "input",
        },
        {
            id: "name",
            span: 24,
            title: String.name,
            type: "input",
        },
    ];

    const { loadingAuthUserList, requestAuthUserList, resultAuthUserList } = RequestUser.useRequestAuthUserList();

    useEffect(() => {
        if (!resultAuthUserList) return;

        setReceivers(
            resultAuthUserList.users.map((user: TypeDTO.UserDto) => {
                const recevier = groupReceivers?.find((receiver) => receiver.user.username === user.username);

                return {
                    user: user,
                    receivingEmail: recevier?.receivingEmail || false,
                    receivingSms: recevier?.receivingSms || false,
                    receivingPushMessage: recevier?.receivingPushMessage || false,
                };
            })
        );
    }, [resultAuthUserList]);

    const onTableChange: TableProps<TypeDTO.AlarmReceivingGroupUserDto>["onChange"] = (pagination, filters, sorter) => {
        setSortedInfo(sorter as SorterResult<TypeDTO.AlarmReceivingGroupUserDto>);
    };

    const onChangeReceivingEmail = (userAlarmReceiving: TypeDTO.AlarmReceivingGroupUserDto, value: boolean) => {
        setReceivers(
            receivers.map((receiver) => ({
                ...receiver,
                receivingEmail: receiver?.user?.username === userAlarmReceiving?.user?.username ? value : receiver.receivingEmail,
            }))
        );

        if (filterReceivers) {
            setFilterReceivers(
                filterReceivers.map((receiver) => ({
                    ...receiver,
                    receivingEmail: receiver?.user?.username === userAlarmReceiving?.user?.username ? value : receiver.receivingEmail,
                }))
            );
        }
    };

    const onChangeReceivingSms = (userAlarmReceiving: TypeDTO.AlarmReceivingGroupUserDto, value: boolean) => {
        setReceivers(
            receivers.map((receiver) => ({
                ...receiver,
                receivingSms: receiver?.user?.username === userAlarmReceiving?.user?.username ? value : receiver.receivingSms,
            }))
        );

        if (filterReceivers) {
            setFilterReceivers(
                filterReceivers.map((receiver) => ({
                    ...receiver,
                    receivingSms: receiver?.user?.username === userAlarmReceiving?.user?.username ? value : receiver.receivingEmail,
                }))
            );
        }
    };

    const onChangeReceivingPushMessage = (userAlarmReceiving: TypeDTO.AlarmReceivingGroupUserDto, value: boolean) => {
        setReceivers(
            receivers.map((receiver) => ({
                ...receiver,
                receivingPushMessage:
                    receiver?.user?.username === userAlarmReceiving?.user?.username ? value : receiver.receivingPushMessage,
            }))
        );

        if (filterReceivers) {
            setFilterReceivers(
                filterReceivers.map((receiver) => ({
                    ...receiver,
                    receivingPushMessage:
                        receiver?.user?.username === userAlarmReceiving?.user?.username ? value : receiver.receivingPushMessage,
                }))
            );
        }
    };

    const onSearchData = (value: SearchType) => {
        const searchFilter = Utils.searchFilter;

        receivers &&
            receivers.length > 0 &&
            setFilterReceivers(
                receivers?.filter(
                    (receiver) =>
                        searchFilter(receiver?.user, value?.username, "username") && searchFilter(receiver?.user, value?.name, "name")
                )
            );
    };

    return (
        <>
            <Search
                title={String.receiver}
                dataLength={filterReceivers?.length || receivers?.length}
                values={search}
                onSearch={(value) => onSearchData(value)}
                onClear={(value) => onSearchData(value)}
            />
            <DataTable
                columns={columns}
                disabledTitle
                loading={loadingAuthUserList}
                dataSource={filterReceivers || receivers}
                rowKey={(render: TypeDTO.AlarmReceivingGroupUserDto) => render?.user.username}
                onChange={onTableChange}
            />
        </>
    );
}

export default GroupReceivers;
